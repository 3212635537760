import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import AboutUsBanner from "../../components/AboutUs/AboutUsBanner";
import { careersPageData, ourTeamPageData } from "../../common";
import ellipseImageTop from '../../assets/images/landingpage/Ellipse1880.png'
import AccordionCareerCard from "./AccordionCareerCard";

// import Loadable from "../../components/Loadable"


// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const AboutUsBanner = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutUsBanner'))
// )
// const AccordionCareerCard = Loadable(
//     lazy(() => import('./AccordionCareerCard'))
// )

const CareersScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper perks-and-benefits-page-wrapper ourTeam-page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <AboutUsBanner bannerImg={careersPageData?.bannerImg} data={careersPageData?.headerData} />
                        <div className="content-tech-page-warper acss-1kbtli">
                            <div className='position-absolute' style={{ inset: 0 }}>
                                <div className="acss-lbif7z acss-b61x89">
                                    <img className="acss-61n50j acss-118odl4" src={ellipseImageTop} alt="ellipseImage" />
                                    {/* <img className="acss-61n50j acss-x14t9p" src={ellipseImageBottom} alt="" /> */}
                                </div>
                                <div></div>
                            </div>
                            <Container className="acss-18azjcp">
                                <div className="page-context-text">
                                    <h1>{careersPageData?.aboutText?.title}</h1>
                                    {careersPageData?.aboutText?.textData.map((text, index) => <p key={index}>{text?.paragraph}</p>)}
                                </div>
                            </Container>
                        </div>
                        <section className='perksAndBenefits-page-wrapper' style={{ backgroundImage: `url(${careersPageData?.perksAndBenefits?.bgImg})` }}>
                            <div className="client-count text-white">
                                <Container>
                                    <div className="heading">
                                        <h1>{careersPageData?.perksAndBenefits?.title}</h1>
                                        <p>{careersPageData?.perksAndBenefits?.paragraph}</p>
                                    </div>
                                    <div className='Fre8-Pro d-flex flex-wrap align-items-center justify-content-start w-100'>
                                        {careersPageData?.perksAndBenefits?.benefits.map((benefit, index) => {
                                            return (
                                                < div key={index} className='ps-0 d-flex flex-wrap align-items-center justify-content-start w-50 mb-2'>
                                                    <div className="display-7 d-flex flex-wrap align-items-center justify-content-start gap-4 w-100">
                                                        <h2>{benefit?.userImg}</h2>
                                                        <h2>{benefit?.title}</h2>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Container>
                            </div>
                        </section>
                        <section className="available-positions-page-wrapper">
                            <Container>
                                <div className="page-header">
                                    <div className="heading m-auto text-center">
                                        <h1>{careersPageData?.availablePositions?.pageTitle}</h1>
                                    </div>
                                    <AccordionCareerCard data={careersPageData?.availablePositions?.positions} />
                                </div>
                            </Container>
                        </section>
                        <Container>
                            <div className="team-culture-screen">
                                <h2>{ourTeamPageData?.teamCulture?.title}</h2>
                                <p>{ourTeamPageData?.teamCulture?.text}</p>
                                <div className="d-flex flex-wrap justify-content-between align-items-center gap-3 py-5">
                                    {ourTeamPageData?.teamCulture?.images.map((img, index) => <div key={index} className="my-gallery"><img className="img-fluid w-100" src={img?.image} alt={`img${img?.id}`} /></div>)}
                                </div>
                                <div className="m-auto text-center">
                                    <h3>
                                        {ourTeamPageData?.teamCulture?.follow?.title}
                                    </h3>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        {ourTeamPageData?.teamCulture?.follow?.icons?.map((icon) => <div className="d-flex align-items-center justify-content-center icon-div">{icon.icon}</div>)}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

CareersScreen.propTypes = {
    classes: PropTypes.object
};

export default CareersScreen;
