import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ourTeamPageData } from "../../common";
// core components
import { Col, Container, Row } from "reactstrap";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import AboutUsBanner from "../../components/AboutUs/AboutUsBanner";
import YourIdea from "../../components/AboutUs/YourIdea";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import ellipseImageTop from '../../assets/images/landingpage/Ellipse1880.png'
import ellipseImageBottom from '../../assets/images/landingpage/Ellipse1879.png'

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const AboutUsBanner = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutUsBanner'))
// )
// const YourIdea = Loadable(
//     lazy(() => import('../../components/AboutUs/YourIdea'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )

const OurTeamScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper ourTeam-page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <AboutUsBanner bannerImg={ourTeamPageData?.bannerImg} data={ourTeamPageData?.headerData} />
                        <div className="content-tech-page-warper acss-1kbtli">
                            <div className='position-absolute' style={{ inset: 0 }}>
                                <div className="acss-lbif7z acss-b61x89">
                                    <img className="acss-61n50j acss-118odl4" src={ellipseImageTop} alt="ellipseImage" />
                                    <img className="acss-61n50j acss-x14t9p" src={ellipseImageBottom} alt="" />
                                </div>
                                <div className="acss-lbif7z acss-wom5xg">
                                    <img className="acss-61n50j acss-1ptcy1b" src={ellipseImageTop} alt="" />
                                    <img className="acss-61n50j acss-1ht8015" src={ellipseImageBottom} alt="" />
                                </div>
                                <div></div>
                            </div>
                            <Container className="acss-18azjcp">
                                <div className="page-context-text">
                                    <h1>{ourTeamPageData?.aboutText?.title}</h1>
                                    {ourTeamPageData?.aboutText?.textData.map((text, index) => <p key={index}>{text?.paragraph}</p>)}
                                </div>
                                <Row>
                                    {ourTeamPageData?.ourTeamData?.map((tech, index) => {
                                        const isOdd = index % 2 !== 0; // Check if the index is odd
                                        return (
                                            isOdd ? (
                                                <Fragment key={index}>
                                                    <Col lg={6} md={6} className="m-auto text-start my-4">
                                                        <LazyLoadImage
                                                            alt={'Fre8-Pro'}
                                                            className="w-100"
                                                            // height={image.height}
                                                            src={tech?.pageImg} // use normal <img> attributes as props
                                                        // width={image.width}
                                                        />
                                                        {/* <img src={tech?.pageImg} alt="img" /> */}
                                                    </Col>
                                                    <Col className="tech-page-warper d-flex align-items-start flex-column justify-content-center gap-3" lg={6} md={6}>
                                                        <h1>{tech?.title}</h1>
                                                        <p>{tech?.position}</p>
                                                        {tech?.paragraph.map((text, index) => <p key={index} className="par-text">{text?.text}</p>)}
                                                    </Col>
                                                </Fragment>
                                            ) : (
                                                <Fragment key={index}>
                                                    <Col className="tech-page-warper d-flex align-items-start flex-column justify-content-center gap-3" lg={6} md={6}>
                                                        <h1>{tech?.title}</h1>
                                                        <p>{tech?.position}</p>
                                                        {tech?.paragraph.map((text, index) => <p key={index} className="par-text">{text?.text}</p>)}
                                                    </Col>
                                                    <Col lg={6} md={6} className="m-auto text-end">
                                                        <LazyLoadImage
                                                            alt={'Fre8-Pro'}
                                                            className="w-100"
                                                            // height={image.height}
                                                            src={tech?.pageImg} // use normal <img> attributes as props
                                                        // width={image.width}
                                                        />
                                                        {/* <img src={tech?.pageImg} alt="img" /> */}
                                                    </Col>
                                                </Fragment>
                                            )
                                        )
                                    })}
                                </Row>
                            </Container>
                        </div>
                        <div className="special-technologies">
                            <YourIdea data={ourTeamPageData?.yourIdea} />
                        </div>
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                        <Container>
                            <div className="team-culture-screen">
                                <h2>{ourTeamPageData?.teamCulture?.title}</h2>
                                <p>{ourTeamPageData?.teamCulture?.text}</p>
                                <div className="d-flex flex-wrap justify-content-between align-items-center gap-3 py-5">
                                    {ourTeamPageData?.teamCulture?.images.map((img, index) => <div key={index} className="my-gallery">
                                        <LazyLoadImage
                                            className="img-fluid w-100"
                                            alt={`img${img?.id}`}
                                            // height={image.height}
                                            src={img?.image} // use normal <img> attributes as props
                                        // width={image.width}
                                        />
                                        {/* <img className="img-fluid w-100" src={img?.image} alt={`img${img?.id}`} /> */}
                                    </div>)}
                                </div>
                                <div className="m-auto text-center">
                                    <h3>
                                        {ourTeamPageData?.teamCulture?.follow?.title}
                                    </h3>
                                    <div className="d-flex no-block align-items-center justify-content-center gap-2">
                                        {ourTeamPageData?.teamCulture?.follow?.icons?.map((icon) => <div className="d-flex no-block align-items-center justify-content-center icon-div">{icon.icon}</div>)}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

OurTeamScreen.propTypes = {
    classes: PropTypes.object
};

export default OurTeamScreen;
