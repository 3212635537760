import React from "react";
import { EmailIcon, PhoneIcon } from "../../common/icon";

const GetInTouch = (props) => {
    const getInTouchPageData = props.data;
    return (
        <div className="get-in-touch-page">
            <h1 className="page-heading">{getInTouchPageData?.pageTitle}</h1>
            <div className="d-flex align-items-start justify-content-between gap-5 py-5">
                {getInTouchPageData?.data.map((item, index) => {
                    return (
                        <div key={index} className="page-content">
                            <h2>{ item?.officeName}</h2>
                            <p>{item?.address}</p>
                            <div className="d-flex align-items-center justify-content-start gap-4 email-svg">
                                <EmailIcon />
                                <p className="m-0">{ item?.email}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-start gap-4 email-svg">
                                <PhoneIcon />
                                <p className="m-0">{ item?.phoneNo}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default GetInTouch;
