import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';

function AccordionCard(props) {
    const faqData = props?.data;
  const [open, setOpen] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

    return (
        <Accordion open={open} toggle={toggle}>
            {faqData?.map((faq, index) => {
                return (
                    <AccordionItem key={index} className='my-accordion'>
                        <AccordionHeader className={open ? 'open' : ''} targetId={`${index}`}>{ faq?.question}</AccordionHeader>
                        <AccordionBody accordionId={ `${index}`}>{ faq?.answer}</AccordionBody>
                    </AccordionItem>
                )
            })}
        </Accordion>
  );
}

export default AccordionCard