import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Container } from 'reactstrap';
import { bestReasonsData } from '../../common';

const BestReasons = () => {
    return (
        <div className="best-reasons">
            <Container className="static-container">
                <div className='w-75 pe-5'>
                    <h2 className='page-title mb-4 pe-4'>{ bestReasonsData?.pageHeading}</h2>
                </div>
                <div className='position-relative'>
                    <div className='position-absolute' style={{ inset: 0 }}>
                        <div className="acss-lbif7z acss-b61x89"></div>
                    </div>
                </div>
                <div className='pt-5 d-flex flex-column gap-4'>
                    {bestReasonsData?.reasons?.map((item) => {
                        return (
                            <div key={item?.id} className='d-flex align-items-center justify-content-start gap-5'>
                                <div className='icon'>
                                    {item?.userImg}
                                </div>
                                <div>
                                    <h2 className='title m-0'>{ item?.title}</h2>
                                    <p className='paragraph m-0'>{ item?.text}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </div>
    );
}

export default BestReasons;
