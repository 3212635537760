import React, { Fragment } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';
import { projectsDetailsData } from '../../common';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProjectsDetails = () => {
    return (
        <div className="project-details position-relative">
            <Container className="static-container">
                <div className='position-absolute' style={{ inset: 0 }}>
                    <div className="acss-lbif7z acss-b61x89"></div>
                </div>
                <Row className='Fre8-Pro'>
                    {projectsDetailsData?.projects?.map((item, index) => {
                        const isOdd = index % 2 !== 0; // Check if the index is odd
                        return (
                            isOdd ? (
                                <Fragment key={index}>
                                    <Col lg="6" md='6' className='page-warper pe-0 d-flex justify-content-center align-items-center'>
                                        <div className='ps-5 pe-3'>
                                            <h1 className='mb-4'>{ item?.title}</h1>
                                            <p>{ item?.text}</p>
                                        </div>
                                    </Col>
                                    <Col lg="6" md='6' className='page-warper ps-0'>
                                        <LazyLoadImage
                                            className='w-100'
                                            alt={'Fre8-Pro'}
                                            // height={image.height}
                                            src={item?.userImg} // use normal <img> attributes as props
                                            // width={image.width}
                                        />
                                        {/* <img className='w-100' src={ item?.userImg} alt='Fre8-Pro' /> */}
                                    </Col>
                                </Fragment>
                            ) : (
                                <Fragment key={index}>
                                    <Col lg="6" md='6' className='page-warper ps-0'>
                                        <LazyLoadImage
                                            className='w-100'
                                            alt={'Fre8-Pro'}
                                            // height={image.height}
                                            src={item?.userImg} // use normal <img> attributes as props
                                            // width={image.width}
                                        />
                                        {/* <img className='w-100' src={ item?.userImg} alt='Fre8-Pro' /> */}
                                    </Col>
                                    <Col lg="6" md='6' className='page-warper pe-0 d-flex justify-content-center align-items-center'>
                                        <div className='ps-5 pe-3'>
                                            <h1 className='mb-4'>{ item?.title}</h1>
                                            <p>{ item?.text}</p>
                                        </div>
                                    </Col>
                                </Fragment>
                            )
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
}

export default ProjectsDetails;
