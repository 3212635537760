import React from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import AboutUsBanner from "../../components/AboutUs/AboutUsBanner";
// import SpecializedTechnologies from "../../components/SpecialisedinTechnologies/SpecialisedinTechnologies";
import YourIdea from "../../components/AboutUs/YourIdea";
import { faqPageData } from "../../common";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import AccordionCard from "../../components/FrequentlyAskedQuestions/AccordionCard";
import { Card, CardBody, Container } from "reactstrap";
// import TechIndustryQuality from "../../components/TechIndustryQuality/TechIndustryQuality";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const AboutUsBanner = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutUsBanner'))
// )
// const YourIdea = Loadable(
//     lazy(() => import('../../components/AboutUs/YourIdea'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const AccordionCard = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/AccordionCard'))
// )

const FaqPageScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper faqPage-page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <AboutUsBanner bannerImg={faqPageData?.bannerImg} data={faqPageData?.headerData} />
                        <section className='industries-page-card '>
                            <Container className='page-warper'>
                                <Card className='m-0'>
                                    <CardBody className=''>
                                        <div className='w-100'>
                                            <AccordionCard data={faqPageData?.questions} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Container>
                        </section>
                        {/* <ScrumMethodology /> */}
                        <div className="special-technologies">
                            <YourIdea data={faqPageData?.yourIdea} />
                        </div>
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

FaqPageScreen.propTypes = {
    classes: PropTypes.object
};

export default FaqPageScreen;
