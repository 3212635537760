import React from 'react';
import { Row, Col, Card } from 'reactstrap';
// import { HashLink as Link } from 'react-router-hash-link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
    const blogs = props.data;
    console.log('---------blogs---------->', blogs)
    return (
        <Row className="">
            {blogs.map((blog, index) => {
                return (
                    <Col key={index} lg="6" md="6">
                        <Card className='my-card'>
                            <Link to={`/blog-detail?id=${blog?.id}`}>
                                <LazyLoadImage
                                    className="card-img-top"
                                    alt={'Fre8-Pro'}
                                    // height={image.height}
                                    src={blog?.userImg} // use normal <img> attributes as props
                                // width={image.width}
                                />

                                {/* <img className="card-img-top" src={blog.userImg} alt="wrappixel kit" /> */}
                            </Link>
                            <div className='p-4 paragraph'>
                                <p className="">
                                    {blog?.createdDate}
                                </p>
                                <Link to={`/blog-detail?id=${blog?.id}`} className="link">
                                    <div className='text-truncate'>
                                        {blog?.heading && <h5 className='font-medium '>{blog?.heading}</h5>}
                                    </div>
                                    {blog?.paragraph && <h6 className='font-small'>{blog?.paragraph}</h6>}
                                </Link>
                                {/* <h5 className="font-medium text-truncate" title={blog?.paragraph}>
                                    <Link to={`/blog-detail?id=${blog?.id}`} className="link">
                                        {blog?.paragraph}
                                    </Link>
                                </h5> */}
                                <span className="linking text-themecolor pt-2">{blog?.name}</span>
                            </div>
                        </Card>
                    </Col>
                )
            })}
        </Row>
    );
}

export default BlogCard;
