import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';

const DreamSoftware = () => {
    return (
        <section className='dream-software'>
            <Container className='page-warper'>
                <Row>
                    <Col lg='9' md='6' className='m-auto align-items-center'>
                        <h3>The easiest way to <span>build your dream software</span> is with VarahiSoft</h3>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DreamSoftware;
