import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import { HashLink as Link } from 'react-router-hash-link';
import ellipseImageTop from '../../assets/images/landingpage/Ellipse1880.png'
import ellipseImageBottom from '../../assets/images/landingpage/Ellipse1879.png'
import { HoverNextArrowCircle, NextArrowCircle } from '../../common/icon';
import { Link } from 'react-router-dom';

const WeAreVarahiSoft = (props) => {
    const weAreVarahiSoftData = props?.data;
    return (
        <section>
            <div className="acss-1kbtli">
                <div className='position-absolute' style={{ inset:0 }}>
                    <div className="acss-lbif7z acss-b61x89">
                        <img className="acss-61n50j acss-118odl4" src={ellipseImageTop} alt="ellipseImage" />
                        <img className="acss-61n50j acss-x14t9p" src={ellipseImageBottom} alt="" />
                    </div>
                    <div className="acss-lbif7z acss-wom5xg">
                        <img className="acss-61n50j acss-1ptcy1b" src={ellipseImageTop} alt="" />
                        <img className="acss-61n50j acss-1ht8015" src={ellipseImageBottom} alt="" />
                    </div>
                    <div></div>
                </div>
                <Container className='acss-18azjcp' style={{ paddingBlock: '80px' }} >
                    <Row className="justify-content-start">
                        <Col lg="11" md="9" className="align-self-start">
                            <h4>{ weAreVarahiSoftData?.title}</h4>
                            <span>{ weAreVarahiSoftData?.title}</span>
                        </Col>
                    </Row>
                    <div className='custom-pending-top'>
                        <div className='d-flex flex-wrap justify-content-start align-self-start gap-5'>
                            <div className='w-64'>
                                <div className='my-background'>
                                    <img className='w-100' src={ weAreVarahiSoftData?.alignedWithYourBusiness?.bgImg} alt='myImg' />
                                    <div className='product'>
                                        <h5>{ weAreVarahiSoftData?.alignedWithYourBusiness?.title}</h5>
                                        <h6 className='para'>{ weAreVarahiSoftData?.alignedWithYourBusiness?.text}</h6>    
                                    </div>    
                                </div>
                            </div>
                            {weAreVarahiSoftData?.technologies?.map((tech, index) => {
                                return (
                                    < div key={index} className='Product-Engineering'>
                                        <div className='product-inner d-flex flex-column gap-4'>
                                            {tech?.icon}
                                            <div>
                                                <h5>{tech?.title}</h5>
                                                <span>{tech?.text}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className='Product-Engineering d-flex justify-content-center align-items-center'>
                                <Link to={'/services'} className='product-inner nextArrowLst d-flex justify-content-center align-items-center'>
                                    <div className='next-arrow justify-content-center align-self-center'>
                                        <NextArrowCircle />
                                    </div>
                                    <div className='hover-arrow justify-content-center align-self-center'>
                                        <HoverNextArrowCircle />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default WeAreVarahiSoft;
