import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

function AccordionCareerCard(props) {
  const positionData = props?.data;
  const [modal, setModal] = useState({
    open: false,
    title: ''
  });
  const modalOpen = () => setModal({
    open: !open,
    title: ''
  });

  const [open, setOpen] = useState('0');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

    return (
        <Accordion open={open} toggle={toggle}>
            {positionData?.map((position, index) => {
                return (
                    <AccordionItem key={index} className='my-accordion'>
                    <AccordionHeader className={open ? 'open' : ''} targetId={`${index}`}>
                      <div className='d-flex flex-column align-items-start'>
                        <strong>{position?.jobTitle}</strong>
                        <p>{ position?.experience?.label} <strong>{ position?.experience?.value}</strong></p>
                        <p className='m-0'>{ position?.openings?.label} <strong>{ position?.openings?.value}</strong></p>
                      </div>
                    </AccordionHeader>
                    <AccordionBody accordionId={`${index}`}>
                      <div className='job-details-page'>
                        <h2 className='page-title'>{position?.jobDetails?.title}</h2>
                        <p className='text'>{position?.jobDetails?.text}</p>
                        <ul className='mb-4'>
                          {position?.jobDetails?.details.map((detail, index) => {
                            return <li className='my-list mb-2' key={index}>
                              <div className='list d-flex align-items-start justify-content-start gap-3'>
                                {detail?.icon}
                                <h3 className='m-0'>{detail?.text}</h3>
                              </div>
                            </li>
                          })} 
                        </ul>
                        <div className='job-type-page-wrapper'>
                          <div className='heading'>
                            <h2 className='page-title'>{ position?.jobDetails?.jobType?.title}</h2>
                            <h3 className='text m-0'>{ position?.jobDetails?.jobType?.value}</h3>
                            <h3 className='text m-0'>{ position?.jobDetails?.jobType?.flexibleSchedule}</h3>
                            <h3 className='text m-0'>{ position?.jobDetails?.jobType?.timing}</h3>
                          </div>
                        </div>
                        <div className='education-page-wrapper'>
                          <div className='heading'>
                            <h2 className='page-title'>{ position?.jobDetails?.education?.title}</h2>
                            <h3 className='text m-0'>{ position?.jobDetails?.education?.value}</h3>
                          </div>
                        </div>
                        <div className='experience-page-wrapper'>
                          <div className='heading'>
                            <h2 className='page-title'>{ position?.jobDetails?.experience?.title}</h2>
                            <h3 className='text m-0'>{ position?.jobDetails?.experience?.value}</h3>
                            <h3 className='text m-0'>{ position?.jobDetails?.experience?.totalWork}</h3>
                            <h3 className='text m-0'>{ position?.jobDetails?.experience?.location}</h3>
                          </div>
                        </div>
                        <div className='m-auto text-center'>
                          <Button
                            className="m-t-20 m-b-20 Apply-for-this-job-btn"
                            onClick={() => {
                              setModal({
                                open: true,
                                title: position?.jobTitle
                              })
                            }}
                          >
                            <span>
                              Apply for this job
                            </span>
                          </Button>
                        </div>
                      </div>
                      {position?.answer}
                    </AccordionBody>
                    </AccordionItem>
                )
            })}
        <Modal className='career-mo' isOpen={modal.open} toggle={modalOpen} size='lg'>
          <ModalHeader toggle={modalOpen}></ModalHeader>
          <ModalBody className='p-4 pb-0'>
            <div className='modal-contents p-4 pb-0'>
              <h1 className='heading mb-4'>{ modal?.title}</h1>
              <Card className='p-4'>
                <CardBody>
                  <Form className="contact-form-page">
                    <Row>
                        <Col lg='6' md='6' >
                          <FormGroup className="form-input m-t-15">
                              <Label htmlFor="name">First name*</Label>
                              <Input type="text" placeholder="name" />
                          </FormGroup>
                        </Col>
                        <Col lg='6' md='6' >
                          <FormGroup className="form-input m-t-15">
                              <Label htmlFor="name">Last name*</Label>
                              <Input type="text" placeholder="name" />
                          </FormGroup>
                        </Col>
                        <Col lg='6' md='6' >
                          <FormGroup className="form-input m-t-15">
                              <Label htmlFor="name">Email*</Label>
                              <Input type="text" placeholder="name" />
                          </FormGroup>
                        </Col>
                        <Col lg='6' md='6' >
                          <FormGroup className="form-input m-t-15">
                              <Label htmlFor="name">Phone*</Label>
                              <Input type="text" placeholder="name" />
                          </FormGroup>
                        </Col>
                    </Row>
                      <FormGroup className="form-input m-t-15">
                          <Label htmlFor="email">Upload CV*</Label>
                          <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                          />
                      </FormGroup>
                      <FormGroup className="form-input m-t-15">
                          <Label htmlFor="location">Additional files</Label>
                          <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                          />
                      </FormGroup>
                      <FormGroup check>
                        <Input type="checkbox" />
                        {' '}
                        <Label check>
                          By submitting this application, I agree that I have read the privacy policy and confirm that Varahisoft store my personal details to be able to process my job application.
                        </Label>
                    </FormGroup>
                    <div className='m-auto text-center'>
                      <Button
                          type="submit"
                          className="m-t-20 m-b-20 submit-btn"
                      >
                          <span>
                              Submit application
                          </span>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </ModalBody>
        </Modal>
        </Accordion>
  );
}

export default AccordionCareerCard