import React from "react";
import PropTypes from "prop-types";
import { ContactPageData } from "../../common";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import SoftwareTeamHunt from "../../components/CaseStudy/SoftwareTeamHunt";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import ContactBanner from "../../components/banner/ContactBanner";
import FormComponent from "./FormComponent";
import GetInTouch from "./GetInTouch";
import MapMyIndia from "./MapMyIndia";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const SoftwareTeamHunt = Loadable(
//     lazy(() => import('../../components/CaseStudy/SoftwareTeamHunt'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )
// const ContactBanner = Loadable(
//     lazy(() => import('../../components/banner/ContactBanner'))
// )
// const FormComponent = Loadable(
//     lazy(() => import('./FormComponent'))
// )
// const GetInTouch = Loadable(
//     lazy(() => import('./GetInTouch'))
// )
// const MapMyIndia = Loadable(
//     lazy(() => import('./MapMyIndia'))
// )

const ContactScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper Contact-us-page">
                <div className="container-fluid">
                    <div className="project-page">
                        <ContactBanner bannerImg={ContactPageData?.bannerImg} data={ContactPageData?.headerData} />
                        <Container>
                            <div className="contact-form-page d-flex align-items-start justify-content-between position-relative">
                                <div className="content-page w-50 p-5">
                                    <p>
                                        We are committed to upholding integrity and fostering creativity. Our pursuit of technical knowledge enables us to offer innovative solutions, exceed customer expectations, and deliver extraordinary experiences
                                    </p>
                                    <p>
                                        Whether it's a project, getting expert advice on a product, or just saying hello, VarahiSoft is here to support your business needs. Let's start the conversation! 🌟
                                    </p>
                                </div>
                                <div className="position-absolute top-0 start-50 translate-middle w-100">
                                    <Row className="justify-content-end">
                                        <Col lg='5' md='5'>
                                            <Card className="form-page w-100">
                                                <CardBody>
                                                    <FormComponent />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Container>
                        <SoftwareTeamHunt data={ContactPageData?.softwareTeamHunt} />
                        <CustomerReviews />
                        <GetInTouch data={ContactPageData?.getInTouchData} />
                        <MapMyIndia />
                        <FrequentlyAskedQuestions data={ContactPageData?.FrequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

ContactScreen.propTypes = {
    classes: PropTypes.object
};

export default ContactScreen;
