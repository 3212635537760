import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';
// import ClientCounter from './ClientCounter';

const ProjectBanner = (props) => {
    const bannerData = props?.data;
    return (
        <div className="project-banner">
            <LazyLoadImage
                className='w-100 h-100'
                alt={'banner-img'}
                // height={image.height}
                src={props?.bannerImg} // use normal <img> attributes as props
                // width={image.width}
            />
            {/* <img className='w-100 h-100' src={props?.bannerImg} alt='banner-img' /> */}
            <Container className="static-container h-100">
                <Row className="justify-content-start align-items-center h-100">
                    <Col lg="8" md="8" className="align-self-center">
                        <h4 className="title m-0 mb-4">{ bannerData?.title}</h4>
                        <p className='subTitle m-0'>{ bannerData?.subTitle}</p>
                    </Col>
                </Row>
                {/* <ClientCounter /> */}
            </Container>
        </div>
    );
}

export default ProjectBanner;
