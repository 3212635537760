import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Fre8ProApplicationData } from "../../common";
import { Button, Col, Row } from "reactstrap";

const Fre8ProApplicationDetailContent = () => {
    return (
        <Fragment>
            <div className='position-absolute' style={{ inset: 0 }}>
                <div className="acss-lbif7z acss-b61x89"></div>
            </div>
            <Row className="Fre8Pro-Application-Detail-Content pb-5">
                <Col lg='10' md='10' sm='12'>
                    <div className="key-roles mb-3">
                        <h1>{Fre8ProApplicationData?.keyRoles?.title}</h1>
                        <p>{Fre8ProApplicationData?.keyRoles?.description}</p>
                    </div>
                    <div className="usedTechnology mb-3">
                        <h1>{Fre8ProApplicationData?.technologyUsed?.title}</h1>
                        <div className="d-flex align-items-center gap-3 flex-wrap">
                            {Fre8ProApplicationData?.technologyUsed?.technologies.map((tech, index) => {
                                return (
                                    <Button key={index}>{tech?.name}</Button>
                                )
                            })}
                        </div>
                    </div>
                    <div className="problem-statement mb-3">
                        <h1>{Fre8ProApplicationData?.problemStatement?.title}</h1>
                        <ul>
                            {Fre8ProApplicationData?.problemStatement?.statements.map((statement, index) => {
                                return (
                                    <li key={index}>{statement?.name}</li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="problem-statement mb-3">
                        <h1>{Fre8ProApplicationData?.challengesFaced?.title}</h1>
                        <ul>
                            {Fre8ProApplicationData?.challengesFaced?.challenges.map((challenge, index) => {
                                return (
                                    <li key={index}>{challenge?.name}</li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="problem-statement mb-3">
                        <h1>{Fre8ProApplicationData?.solution?.title}</h1>
                        <ul>
                            {Fre8ProApplicationData?.solution?.solutions.map((solution, index) => {
                                return (
                                    <li key={index}>{solution?.name}</li>
                                )
                            })}
                        </ul>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
}

Fre8ProApplicationDetailContent.propTypes = {
    classes: PropTypes.object
};

export default Fre8ProApplicationDetailContent;
