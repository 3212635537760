import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';
import serviceBg from '../../assets/images/service/service-bg.png'
import serviceBgMobile from '../../assets/images/service/service-img.png'

const ProductEngineering = (props) => {
    const productEngineering = props.data;
    return (
        <div className="product-engineering">
            <Container className="static-container">
                <Row className='pb-4'>
                    <Col lg="11" md="11" className="align-self-start">
                        <h2 className='page-title'>{ productEngineering?.title}</h2>
                        <div className='listing'>
                            <ul>
                                {productEngineering?.listing?.map((list, index) => {
                                    return (
                                        <li key={index} className='mb-2'>{ list?.text}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
                <div className='project-engineering-design position-relative mt-4'>
                    <img className='w-100 web' src={serviceBg} alt='service-bg-img' style={{ height: '410px'}} />
                    <img className='w-100 mobile' src={serviceBgMobile} alt='service-bg-img' />
                    <div className='web position-absolute top-0 start-0 w-100'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex flex-column gap-5 w-100'>
                                <div className='first-card-one px-4'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <h4>Ideation</h4>
                                            <ul className='m-0 ps-3'>
                                                <li>Research and technical innovation</li>
                                                <li>Collaborative creativity focused on user</li>
                                            </ul>
                                        </div>
                                        <div className='circle'>1</div>
                                    </div>
                                </div>
                                <div className='first-card-two px-4'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <h4>Design</h4>
                                            <ul className='m-0 ps-3'>
                                                <li>Scalable Architecture and Design</li>
                                                <li>Appealing UI and Stunning UX</li>
                                            </ul>
                                        </div>
                                        <div className='circle'>2</div>
                                    </div>
                                </div>
                                <div className='first-card-three px-4'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <h4>Development</h4>
                                            <ul className='m-0 ps-3'>
                                                <li>Fresh Technology Stack</li>
                                                <li>Best Coding Practice</li>
                                            </ul>
                                        </div>
                                        <div className='circle'>3</div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-column gap-3 justify-content-center align-items-center w-100'>
                                <h4 className='product-bg-title'>Product Engineering</h4>
                            </div>
                            <div className='d-flex flex-column gap-5 w-100 ps-2'>
                                <div className='first-card-one px-4'>
                                    <div className='d-flex justify-content-start gap-3 align-items-center'>
                                        <div className='circle'>4</div>
                                        <div>
                                            <h4>Testing</h4>
                                            <ul className='m-0 ps-3'>
                                                <li>Testing for all platforms​</li>
                                                <li>Quality that optimize costs</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='first-card-two px-4'>
                                    <div className='d-flex justify-content-start gap-3 align-items-center'>
                                        <div className='circle'>5</div>
                                        <div>
                                            <h4>Deployment and Release</h4>
                                            <ul className='m-0 ps-3'>
                                                <li>Rock Star Product Launch</li>
                                                <li>Future Upgrades</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='first-card-three px-4'>
                                    <div className='d-flex justify-content-start gap-3 align-items-center'>
                                        <div className='circle'>6</div>
                                        <div>
                                            <h4>Maintenance</h4>
                                            <ul className='m-0 ps-3'>
                                                <li>Feature Releases</li>
                                                <li>Performance Improvement</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default ProductEngineering;
