import React, { useState, useEffect, useRef, Fragment } from 'react';
import { customerReviewsData } from '../../common';

function TestimonialCarousel({ visibleItems = 3, autoPlay = true, autoPlayDelay = 3000, mainItemWidth = 0.5 }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const itemCount = customerReviewsData.reviews.length;
  const intervalRef = useRef(null);

  const startAutoPlay = () => {
    if (autoPlay) {
      intervalRef.current = setInterval(() => {
        next();
      }, autoPlayDelay);
    }
  };

  const stopAutoPlay = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    startAutoPlay();
    return () => stopAutoPlay();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay, autoPlayDelay, activeIndex]);

  const next = () => {
    if (animating) return;
    setAnimating(true);
    setActiveIndex((prevIndex) => (prevIndex + 1) % itemCount);
    setTimeout(() => setAnimating(false), 500);
  };

  const goToIndex = (index) => {
    if (animating) return;
    setActiveIndex(index);
  };

  const calculateStyles = (index) => {
    const offset = (index - activeIndex + itemCount) % itemCount;
    const isActive = offset === 0;
    const containerWidth = 100;
    const mainItemSize = containerWidth * mainItemWidth;
    const secondaryItemSize = (containerWidth - mainItemSize) / (visibleItems - 1);

    let width, left, zIndex, opacity, scale;

    if (isActive) {
      width = `50%`;
      // width = `${mainItemSize}%`;
      left = `${(containerWidth - mainItemSize) / 2}%`;
      zIndex = visibleItems + 1;
      opacity = 1;
      scale = 1;
    } else {
      width = `40%`;
      // width = `${secondaryItemSize}%`;
      left = offset < visibleItems / 2
        ? `${(containerWidth - mainItemSize) / 3 - (visibleItems / 2 - offset) * secondaryItemSize}%`
        : `${(containerWidth + mainItemSize) / 3 + (offset - visibleItems / 2) * secondaryItemSize}%`;
      zIndex = visibleItems - offset;
      opacity = 1 - offset * 0.2;
      scale = 1 - offset * 0.1;
    }

    return {
      width,
      left,
      zIndex,
      opacity,
      transform: `scale(${scale})`,
      transition: 'all 0.5s ease',
      display: offset < visibleItems ? 'block' : 'none'
    };
  };

  return (
    <div className="carousel-container"
      onMouseEnter={stopAutoPlay}
      onMouseLeave={startAutoPlay}
      // onMouseOver={stopAutoPlay}
      // onMouseEnter={stopAutoPlay}
      // onMouseLeave={startAutoPlay}
    >
      <div className="carousel-items">
        {customerReviewsData.reviews.map((item, index) => (
          <div
            key={index}
            className="carousel-item"
            style={calculateStyles(index)}
          >
            <div className="review-box d-flex align-items-center justify-content-center flex-column">
              <div className="review-box-paragraph testimonial-slide">{item.paragraph}</div>
              {activeIndex === index && <Fragment>
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="16" viewBox="0 0 37 16" fill="none">
                  <path d="M36.4858 0.126953H0.48584L12.1164 13.0149C15.757 17.049 22.115 16.9614 25.643 12.8286L36.4858 0.126953Z" fill="white" />
                </svg>
                <div className="reviewer-img mt-2" style={{ background: `url(${item.userImg}) lightgray 50% / cover no-repeat` }} />
                <h2 className="m-0">{item.name}</h2>
                <h3 className="m-0">{item.designation}</h3>
              </Fragment>}
            </div>
          </div>
        ))}
      </div>
      {/* <div className="carousel-nav">
        <button className="prev" onClick={previous}>Previous</button>
        <button className="next" onClick={next}>Next</button>
      </div> */}
      <div className="carousel-dots">
        {customerReviewsData.reviews.map((_, index) => (
          <span
            key={index}
            className={`dot ${activeIndex === index ? 'active' : ''}`}
            onClick={() => goToIndex(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default TestimonialCarousel;

