/* eslint-disable */
import React from 'react';
import { Row, Col, Container } from 'reactstrap';

const YourIdea = (props) => {
    const yourIdeaData = props.data;
    return (
        <section className='your-ideas' style={{ backgroundImage: `url(${yourIdeaData?.bgImg})` }}>
            <div className="client-count text-white">
                <Container>
                    <Row className='Fre8-Pro'>
                        <Col lg="10" md='9' className='ps-0 m-auto d-flex justify-content-center align-items-center gap-5'>
                            <div className="display-7 align-self-center">
                                <h1 className='m-0'>{yourIdeaData?.title} <span>{yourIdeaData?.subtitle1}</span> { yourIdeaData?.subtitle2}</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}
export default YourIdea;