import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
// import { HashLink as Link } from 'react-router-hash-link';

const SpecializedTechnologies = (props) => {
    const techIndustry = props.data;
    return (
        <section className='Specialized-Technologies'>
            <Container className="py-5 pe-5">
                <div className='d-flex justify-content-start align-self-start gap-5 pb-4'>
                    <div className='w-50'>
                        <h4>{ techIndustry?.title}</h4>
                        {techIndustry?.paragraph && <p>{techIndustry?.paragraph}</p>}
                    </div>
                    {techIndustry?.btnName && <div className='d-flex no-block justify-content-end align-self-end w-50'>
                        <Link to={'/technology'} className='View-All-button px-4'>
                            <p className='m-0'>{techIndustry?.btnName}</p>
                        </Link>
                    </div>}
                </div>
                <div className='technologiesButton d-flex justify-content-between align-items-start'>
                    {techIndustry?.technologies?.map((item => <Button outline key={item?.id}>{ item?.name}</Button>))}
                </div>
            </Container>
        </section>
    );
}

export default SpecializedTechnologies;
