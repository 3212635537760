import React, { Fragment } from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import AboutUsBanner from "../../components/AboutUs/AboutUsBanner";
import YourIdea from "../../components/AboutUs/YourIdea";
import { technologiesPageData } from "../../common";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ellipseImageTop from '../../assets/images/landingpage/Ellipse1880.png'
import ellipseImageBottom from '../../assets/images/landingpage/Ellipse1879.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const AboutUsBanner = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutUsBanner'))
// )
// const YourIdea = Loadable(
//     lazy(() => import('../../components/AboutUs/YourIdea'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )


const TechnologyScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper technology-page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <AboutUsBanner bannerImg={technologiesPageData?.bannerImg} data={technologiesPageData?.headerData} />
                        <section className='industries-page-card '>
                            <Container className='page-warper'>
                                <Card className='m-0 p-5'>
                                    <CardBody className='p-0'>
                                        <h1 className="mb-3">{technologiesPageData?.backEndTechnologies?.pageTitle}</h1>
                                        <div className='d-flex no-block flex-wrap align-items-center justify-content-start gap-3 w-100'>
                                            {technologiesPageData?.backEndTechnologies?.technologies?.map((content, index) => {
                                                return <Button key={index} className="tech-button">{content?.name}</Button>
                                            })}
                                        </div>
                                        <h1 className="my-3">{technologiesPageData?.frontEndTechnologies?.pageTitle}</h1>
                                        <div className='d-flex no-block flex-wrap align-items-center justify-content-start gap-3 w-100'>
                                            {technologiesPageData?.frontEndTechnologies?.technologies?.map((content, index) => {
                                                return <Button key={index} className="tech-button">{content?.name}</Button>
                                            })}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Container>
                        </section>
                        <div className="content-tech-page-warper acss-1kbtli">
                            <div className='position-absolute' style={{ inset: 0 }}>
                                <div className="acss-lbif7z acss-b61x89">
                                    <img className="acss-61n50j acss-118odl4" src={ellipseImageTop} alt="ellipseImage" />
                                    <img className="acss-61n50j acss-x14t9p" src={ellipseImageBottom} alt="" />
                                </div>
                                <div className="acss-lbif7z acss-wom5xg">
                                    <img className="acss-61n50j acss-1ptcy1b" src={ellipseImageTop} alt="" />
                                    <img className="acss-61n50j acss-1ht8015" src={ellipseImageBottom} alt="" />
                                </div>
                                <div></div>
                            </div>
                            <Container className="acss-18azjcp">
                                <Row>
                                    {technologiesPageData?.technologiesData?.map((tech, index) => {
                                        const isOdd = index % 2 !== 0; // Check if the index is odd
                                        return (
                                            isOdd ? (
                                                <Fragment key={index}>
                                                    <Col lg={6} md={6} className="m-auto text-start mb-5">
                                                        <LazyLoadImage
                                                            alt={'Fre8-Pro'}
                                                            // height={image.height}
                                                            src={tech?.pageImg} // use normal <img> attributes as props
                                                        // width={image.width}
                                                        />
                                                        {/* <img src={tech?.pageImg} alt="img" /> */}
                                                    </Col>
                                                    <Col className="tech-page-warper d-flex align-items-start flex-column justify-content-center gap-3" lg={6} md={6}>
                                                        <h1>{tech?.pageTitle}</h1>
                                                        <ul className="d-flex w-75 flex-wrap align-items-center justify-content-between">
                                                            {tech?.technologies?.map((item, id) => {
                                                                return <li className="page-tech-li" key={id}>{item?.name}</li>
                                                            })}
                                                        </ul>
                                                    </Col>
                                                </Fragment>
                                            ) : (
                                                <Fragment key={index}>
                                                    <Col className="tech-page-warper d-flex align-items-start flex-column justify-content-center gap-3" lg={6} md={6}>
                                                        <h1>{tech?.pageTitle}</h1>
                                                        <ul className="d-flex w-75 flex-wrap align-items-center justify-content-between">
                                                            {tech?.technologies?.map((item, id) => {
                                                                return <li className="page-tech-li" key={id}>{item?.name}</li>
                                                            })}
                                                        </ul>
                                                    </Col>
                                                    <Col lg={6} md={6} className="m-auto text-end mb-5">
                                                        <LazyLoadImage
                                                            alt={'Fre8-Pro'}
                                                            // height={image.height}
                                                            src={tech?.pageImg} // use normal <img> attributes as props
                                                        // width={image.width}
                                                        />
                                                        {/* <img src={tech?.pageImg} alt="img" /> */}
                                                    </Col>
                                                </Fragment>
                                            )
                                        )
                                    })}
                                </Row>
                            </Container>
                        </div>
                        <div className="special-technologies">
                            <YourIdea data={technologiesPageData?.yourIdea} />
                        </div>
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                        <FrequentlyAskedQuestions data={technologiesPageData?.frequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

TechnologyScreen.propTypes = {
    classes: PropTypes.object
};

export default TechnologyScreen;
