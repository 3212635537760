import React, { Suspense } from "react";
import { routesData } from "../../common";
import { Route, Routes } from "react-router-dom";

const LayoutBox = () => {
    console.log('------------------route----->', routesData)
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {
                    routesData?.map((route, index) => {
                        return <Route key={index} path={route?.path} element={route?.element} />
                    })
                }
            </Routes>
        </Suspense>
    )
}

export default LayoutBox;
