import React from "react";
import PropTypes from "prop-types";
import { aboutPageData, clientCounterData, FrequentlyAskedQuestionsData, ourTeam } from "../../common";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import AboutUsBanner from "../../components/AboutUs/AboutUsBanner";
import ClientCounter from "../../components/AboutUs/ClientCounter";
import AboutContent from "../../components/AboutUs/AboutContent";
import OurTeam from "../../components/AboutUs/OurTeam";
import WhyHow from "../../components/AboutUs/WhyHow";
import ScrumMethodology from "../../components/AboutUs/ScrumMethodology";
import SpecializedTechnologies from "../../components/SpecialisedinTechnologies/SpecialisedinTechnologies";
import YourIdea from "../../components/AboutUs/YourIdea";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";

// import Loadable from "../../components/Loadable"


// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const AboutUsBanner = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutUsBanner'))
// )
// const ClientCounter = Loadable(
//     lazy(() => import('../../components/AboutUs/ClientCounter'))
// )
// const AboutContent = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutContent'))
// )
// const OurTeam = Loadable(
//     lazy(() => import('../../components/AboutUs/OurTeam'))
// )
// const WhyHow = Loadable(
//     lazy(() => import('../../components/AboutUs/WhyHow'))
// )
// const ScrumMethodology = Loadable(
//     lazy(() => import('../../components/AboutUs/ScrumMethodology'))
// )
// const SpecializedTechnologies = Loadable(
//     lazy(() => import('../../components/SpecialisedinTechnologies/SpecialisedinTechnologies'))
// )
// const YourIdea = Loadable(
//     lazy(() => import('../../components/AboutUs/YourIdea'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )

const AboutScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper about-page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <AboutUsBanner bannerImg={aboutPageData?.bannerImg} data={aboutPageData?.headerData} />
                        <ClientCounter data={clientCounterData} />
                        <AboutContent data={aboutPageData?.aboutText} />
                        <OurTeam data={ourTeam} />
                        <WhyHow />
                        <ScrumMethodology />
                        <SpecializedTechnologies data={aboutPageData?.techIndustry} />
                        <YourIdea data={aboutPageData?.yourIdea} />
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                        <FrequentlyAskedQuestions data={FrequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

AboutScreen.propTypes = {
    classes: PropTypes.object
};

export default AboutScreen;
