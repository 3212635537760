import React from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import AboutUsBanner from "../../components/AboutUs/AboutUsBanner";
import SpecializedTechnologies from "../../components/SpecialisedinTechnologies/SpecialisedinTechnologies";
import YourIdea from "../../components/AboutUs/YourIdea";
import { FrequentlyAskedQuestionsData, industriesPageData } from "../../common";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import { Card, CardBody, Container } from "reactstrap";
import TechIndustryQuality from "../../components/TechIndustryQuality/TechIndustryQuality";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const AboutUsBanner = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutUsBanner'))
// )
// const SpecializedTechnologies = Loadable(
//     lazy(() => import('../../components/SpecialisedinTechnologies/SpecialisedinTechnologies'))
// )
// const YourIdea = Loadable(
//     lazy(() => import('../../components/AboutUs/YourIdea'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )
// const TechIndustryQuality = Loadable(
//     lazy(() => import('../../components/TechIndustryQuality/TechIndustryQuality'))
// )

const IndustriesScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper industries-page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <AboutUsBanner bannerImg={industriesPageData?.bannerImg} data={industriesPageData?.headerData} />
                        <section className='industries-page-card '>
                            <Container className='page-warper'>
                                <Card className='m-0'>
                                    <CardBody className=''>
                                        <div className='d-flex flex-wrap gap-3 w-100'>
                                            {industriesPageData?.industries?.map((content, index) => {
                                                return <div key={index} className='Product-Engineering'>
                                                    <div className='product-inner'>
                                                        <div>
                                                            {content?.icon}
                                                        </div>
                                                        <div>
                                                            <h5>{content?.text}</h5>
                                                            <span>{content?.paragraph}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Container>
                        </section>
                        {/* <ScrumMethodology /> */}
                        <div className="special-technologies">
                            <SpecializedTechnologies data={industriesPageData?.techIndustry} />
                            <TechIndustryQuality data={industriesPageData?.techIndustryQuality} />
                            <YourIdea data={industriesPageData?.yourIdea} />
                        </div>
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                        <FrequentlyAskedQuestions data={FrequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

IndustriesScreen.propTypes = {
    classes: PropTypes.object
};

export default IndustriesScreen;
