import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';

const SoftwareTeamHunt = (props) => {
    const teamHuntData = props?.data;
    return (
        <section className='software-team-hunt' style={{backgroundImage: `url(${teamHuntData?.bgImg})`}}>
            <Container className='page-warper'>
                <Row>
                    <Col lg='12' md='12' className='m-auto'>
                        <span>{teamHuntData?.title} </span>
                        {teamHuntData?.subTitle1 && <span>{teamHuntData?.subTitle1} </span>}
                        {teamHuntData?.subTitle2 && <span>{teamHuntData?.subTitle2} </span>}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SoftwareTeamHunt;
