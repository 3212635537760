import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';

const AboutUsBanner = (props) => {
    const bannerData = props?.data;
    return (
        <div className="aboutUs-banner">
            <LazyLoadImage
                className='w-100 h-100'
                alt={'banner-img'}
                // height={image.height}
                src={props?.bannerImg} // use normal <img> attributes as props
                // width={image.width}
            />
            {/* <img className='w-100 h-100' src={props?.bannerImg} alt='banner-img' /> */}
            <Container className="static-container">
                <Row>
                    <Col lg="9" md="10" className="banner-main">
                        <h4 className="title font-light text-start">{bannerData?.title} <span className='subtitle1'> {bannerData?.subTitle} </span> {bannerData?.subTitle2 && <span className='subtitle2' >{bannerData?.subTitle2}</span>}</h4>
                        {bannerData?.text && <p className='paragraph m-0'>{bannerData?.text}</p>}
                    </Col>
                </Row>
                {bannerData?.btnName && <div className='m-auto text-center w-100'>
                    <Link to="/contact" className="btn btn-md m-t-30 schedule-a-call-btn font-14 rounded-5 mb-4">
                        {bannerData?.btnName}
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.39556 0.987879C9.63213 0.75404 10.0156 0.75404 10.2522 0.987879L15.906 6.57654C16.0197 6.6888 16.0835 6.84113 16.0835 6.99993C16.0835 7.15873 16.0197 7.31106 15.906 7.42339L10.2522 13.0121C10.0156 13.246 9.63213 13.246 9.39556 13.0121C9.15899 12.7783 9.15899 12.3991 9.39556 12.1653L14.0152 7.59872H0.939265C0.604711 7.59872 0.333496 7.33062 0.333496 6.99993C0.333496 6.66924 0.604711 6.40114 0.939265 6.40114H14.0152L9.39556 1.8347C9.15899 1.60086 9.15899 1.22173 9.39556 0.987879Z" fill="white" />
                        </svg>
                    </Link>
                </div>}
                {/* <ClientCounter /> */}
            </Container>
        </div>
    );
}

export default AboutUsBanner;
