import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import { OurServicesAtAGlanceData } from '../../common';

const OurServicesAtAGlance = () => {
    return (
        <div className="our-services-at-a-glance">
            <Container className="static-container">
                <h2 className='page-title mb-4'>{ OurServicesAtAGlanceData?.pageHeading}</h2>
                <Row className='pt-5'>
                    {OurServicesAtAGlanceData?.glance?.map((item) => {
                        return (
                            <Col key={item?.id} lg="4" md="4" className="align-self-start">
                                <Card className='mb-3 card-one'>
                                    <CardBody className='p-0'>
                                        <div className='d-flex flex-column gap-4 '>
                                            <div>
                                                <CardHeader className='border-0 p-0'>
                                                    { item?.userImg}
                                                </CardHeader>
                                            </div>
                                            <div>
                                                <CardTitle>{ item?.title}</CardTitle>
                                                <CardText>{ item?.text}</CardText>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    }
                    )}
                </Row>
            </Container>
        </div>
    );
}

export default OurServicesAtAGlance;
