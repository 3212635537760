import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  // HashRouter
} from "react-router-dom";
// import Components from "./views/components/components.jsx";
// import CustomComponents from "./views/custom-components/custom-components.jsx";
// import HomeScreen from "./views/Home/index.js";
// import { routesData } from "./common/index.js";
import LayoutBox from "./components/LayoutBox/index.js";
import ScrollToTop from "./components/ScrollToTop/index.jsx";



const root = ReactDOM.createRoot(document.getElementById('root'));

// var hist = createBrowserHistory();
root.render(
  <BrowserRouter>
    {/* // <HashRouter history={hist}> */}
    {/* <div className="loader"></div> */}
    <ScrollToTop />
    <LayoutBox />
    {/* <Routes> */}
    {/* <Route path="/custom-components" element={<CustomComponents />} />
      <Route path="/components" element={<Components />} />
      <Route path="/" element={<HomeScreen />} /> */}
    {/* </Routes> */}
    {/* </HashRouter> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
