import React from 'react';
import { Container} from 'reactstrap';
import FormComponent from '../../views/Contact/FormComponent';
// import { HashLink as Link } from 'react-router-hash-link';

const ContactUsComponent = () => {
    return (
        <section className='Contact-Us-form'>
            <div className='custom-pending-top'>
                <div className='my-background'>
                    <div className='product'>
                        <Container className='custom-padding-py-100' >
                            <div className='text-center pb-4'>
                                <h4>Lets connect</h4>
                                <p className='m-0'>
                                    Let's work together to bring your ideas and vision to software reality.
                                </p>
                            </div>
                            <div className='connect-form-page'>
                                <FormComponent />
                            </div>
                        </Container>
                    </div>    
                </div>
            </div>
        </section>
    );
}

export default ContactUsComponent;
