import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
// import { HashLink as Link } from 'react-router-hash-link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const CaseStudyApplications = (props) => {
    const applicationData = props?.data;
    return (
        <Row className='CaseStudy-applications Fre8-Pro'>
            {applicationData?.map((item, index) => {
                const isOdd = index % 2 !== 0; // Check if the index is odd
                return (
                    isOdd ? (
                        <Fragment key={index}>
                            {item?.title && <Col lg="6" md='6' className='pe-0 d-flex justify-content-center align-items-center '>
                                <div className='pe-4 custom-p-4'>
                                    <h1>{item?.title}</h1>
                                    <h2>{item?.subTitle}</h2>
                                    <p>{item?.text}</p>
                                    {(item?.teamSize || item?.projectDuration) && <div className='d-flex no-block align-items-center gap-2'>
                                        {item?.teamSize && <h3 className='team-size m-0'>Team Size: {item?.teamSize}</h3>}
                                        {(item?.teamSize || item?.projectDuration) && <h3 className='team-size m-0'>|</h3>}
                                        {item?.projectDuration && <h3 className='team-size m-0'>Project duration: {item?.projectDuration}</h3>}
                                    </div>}
                                    {item?.link && <Link to={item?.link} className='btn viewCaseStudyBtn'>view Case Study</Link>}
                                </div>
                            </Col>}
                            {item?.img && <Col lg="6" md='6' className='ps-0'>
                                <LazyLoadImage
                                    className='w-100 h-auto'
                                    alt={'Fre8-Pro'}
                                    // height={image.height}
                                    src={item?.img} // use normal <img> attributes as props
                                    // width={image.width}
                                />
                                {/* <img className='w-100 h-auto' src={item?.img} alt='Fre8-Pro' /> */}
                            </Col>}
                        </Fragment>
                    ) : (
                        <Fragment key={index}>
                            {item?.img && <Col lg="6" md='6' className='ps-0'>
                                <LazyLoadImage
                                    className='w-100 h-auto'
                                    alt={'Fre8-Pro'}
                                    // height={image.height}
                                    src={item?.img} // use normal <img> attributes as props
                                    // width={image.width}
                                />
                                    {/* <img className='w-100 h-auto' src={item?.img} alt='Fre8-Pro' /> */}
                            </Col>}
                            {item?.title && <Col lg="6" md='6' className='pe-0 d-flex justify-content-center align-items-center'>
                                <div className='ps-5 pe-2 custom-p-5'>
                                    <h1>{item?.title}</h1>
                                    <h2>{item?.subTitle}</h2>
                                    <p>{item?.text}</p>
                                    {(item?.teamSize || item?.projectDuration) && <div className='d-flex no-block align-items-center gap-2'>
                                        {item?.teamSize && <h3 className='team-size m-0'>Team Size: {item?.teamSize}</h3>}
                                        {(item?.teamSize || item?.projectDuration) && <h3 className='team-size m-0'>|</h3>}
                                        {item?.projectDuration && <h3 className='team-size m-0'>Project duration: {item?.projectDuration}</h3>}
                                    </div>}
                                    {item?.link && <Link to={item?.link} className='btn viewCaseStudyBtn'>view Case Study</Link>}
                                </div>
                            </Col>}
                        </Fragment>
                    )
                );
            })}
            
        </Row>
    );
}

export default CaseStudyApplications;
