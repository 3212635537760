import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// core components
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import { blogsDetailData, ContactPageData } from '../../common'
import SoftwareTeamHunt from '../../components/CaseStudy/SoftwareTeamHunt'
import FrequentlyAskedQuestions from '../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'
import ContactBanner from '../../components/banner/ContactBanner'
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
// import { HashLink as Link } from 'react-router-hash-link';
import { BenefitsIcon, ConclusionIcon, FacebookIconOne, InstagramIcon, LinkedinIcon, QuestionMarkFillIcon, QuestionMarkOutlineIcon, RoleOfSolutionArchitectIcon } from '../../common/icon'
import OurLatestBlogs from '../../components/OurLatestBlogs/OurLatestBlogs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { BlogDetailsData } from '../../common/BlogDetailsData'
// import BenefitsImg from '../../assets/images/blog/blog-home/blogDetails/New-Project.png'

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const SoftwareTeamHunt = Loadable(
//     lazy(() => import('../../components/CaseStudy/SoftwareTeamHunt'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )
// const ContactBanner = Loadable(
//     lazy(() => import('../../components/banner/ContactBanner'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )

const ViewBlogDetailScreen = () => {
    const location = useLocation()
    const [filteredContent, setFilteredContent] = useState(false)
    // Parse the URL query parameters
    const searchParams = new URLSearchParams(location.search)
    const contentId = searchParams.get('id') // Extracts the id from the URL
    useEffect(() => {
        // Filter contentDetails by id
        setFilteredContent(
            BlogDetailsData.find((content) => content.id === Number(contentId))
        )
    }, [contentId])

    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper blogs_details">
                <div className="container-fluid">
                    <div className="project-page">
                        <ContactBanner
                            bannerImg={blogsDetailData?.bannerImg}
                            data={filteredContent?.bannerData}
                        />
                        {filteredContent ? <Container className="page-blog-content pb-3">
                            {/* <img src={filteredContent?.bodyImg} className="w-100 img-fluid" alt="img" /> */}
                            <Row className="m-0">
                                <Col lg="8" md="8">
                                    <LazyLoadImage
                                        className="w-100 img-fluid"
                                        alt={'Fre8-Pro'}
                                        // height={image.height}
                                        src={filteredContent?.headerData?.image} // use normal <img> attributes as props
                                    // width={image.width}
                                    />
                                    <div className="page-top-content">
                                        <h1 className="heading">{filteredContent?.headerData?.title}</h1>
                                        <p className="paragraph">{filteredContent?.headerData?.paragraph}</p>
                                    </div>
                                    {/* introduction */}
                                    <div className="page-intro-content">
                                        <h1 className="heading">{filteredContent?.pageData?.introduction?.heading}</h1>
                                        {filteredContent?.pageData?.introduction?.paragraph?.map((intro, introIndex) =>
                                            <p key={introIndex} className="paragraph">{intro?.text}</p>
                                        )}
                                    </div>
                                    {/* Get to know everything about solution architecture */}
                                    <div className="page-intro-content">
                                        <h1 className="heading">{filteredContent?.pageData?.aboutSolutionArchitecture?.heading}</h1>
                                        {filteredContent?.pageData?.aboutSolutionArchitecture?.paragraph?.map((intro, introIndex) =>
                                            <p key={introIndex} className="paragraph">{intro?.text}</p>
                                        )}
                                    </div>
                                    {/* Do you need a custom-made application? */}
                                    <Card className='page-custom-made-application-card overflow-hidden'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='custom-made-title-div p-4'>
                                                {filteredContent?.pageData?.doYouNeedCustomMadeApplication?.heading && <h3 className='m-0'>{filteredContent?.pageData?.doYouNeedCustomMadeApplication?.heading}</h3>}
                                                {filteredContent?.pageData?.doYouNeedCustomMadeApplication?.headingTwo && <h3 className='m-0'>{filteredContent?.pageData?.doYouNeedCustomMadeApplication?.headingTwo}</h3>}
                                            </div>
                                            <div className='d-flex gap-2 pe-4'>
                                                <div className='d-flex gap-3'>
                                                    <div className='mt-2'>
                                                        <QuestionMarkOutlineIcon />
                                                    </div>
                                                    <QuestionMarkFillIcon />
                                                </div>
                                                <div className='d-flex align-items-end' style={{ marginBottom: '-13px' }}>
                                                    <QuestionMarkOutlineIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <div className="page-intro-content">
                                        {filteredContent?.pageData?.doYouNeedCustomMadeApplication?.paragraph?.map((intro, introIndex) =>
                                            <Fragment key={introIndex}>
                                                <p className="paragraph">{intro?.text}</p>
                                                {intro?.listing?.length > 0 && <ul className='page-custom-made-application-list'>
                                                    {intro?.listing?.map((introList, introListIndex) => <li key={introListIndex}>{introList?.listData}</li>)}
                                                </ul>}
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* Solution architecture – the silent hero */}
                                    <div className="page-intro-content">
                                        <h1 className="heading">{filteredContent?.pageData?.solutionArchitectureTheSilentHero?.heading}</h1>
                                        {filteredContent?.pageData?.solutionArchitectureTheSilentHero?.paragraph?.map((intro, introIndex) =>
                                            <Fragment key={introIndex}>
                                                <p className="paragraph">{intro?.text}</p>
                                                {intro?.listing?.length > 0 && <ul className='page-custom-made-application-list'>
                                                    {intro?.listing?.map((introList, introListIndex) => <li key={introListIndex}>{introList?.listData}</li>)}
                                                </ul>}
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* How does Solution Architecture help */}
                                    <div className="page-intro-content">
                                        <h1 className="heading">{filteredContent?.pageData?.howDoesSolutionArchitectureHelp?.heading}</h1>
                                        {filteredContent?.pageData?.howDoesSolutionArchitectureHelp?.paragraph?.map((intro, introIndex) =>
                                            <Fragment key={introIndex}>
                                                <p className="paragraph">{intro?.text}</p>
                                                {intro?.steps?.length > 0 && intro?.steps?.map((step, stepIndex) => <div key={stepIndex} className='SolutionArchitectureHelp-step-div'>
                                                    <div className='step-button mb-3' style={{ width: 'fit-content' }}>
                                                        <span>step</span>
                                                        <span>{stepIndex + 1}</span>
                                                    </div>
                                                    <h1 className="heading mb-3">{step?.title}</h1>
                                                    <p className="paragraph mb-3">{step?.paragraph}</p>
                                                    {step?.listing?.length > 0 && <ul className='page-custom-made-application-list'>
                                                        {step?.listing?.map((introList, introListIndex) => <li key={introListIndex}>{introList?.list}</li>)}
                                                    </ul>}
                                                </div>)}
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* What Are the Components of a Solution Architecture */}
                                    <div className="page-intro-content">
                                        <h1 className="heading">{filteredContent?.pageData?.whatAreTheComponentsOfASolutionArchitecture?.heading}</h1>
                                        {filteredContent?.pageData?.whatAreTheComponentsOfASolutionArchitecture?.paragraph?.map((intro, introIndex) =>
                                            <Fragment key={introIndex}>
                                                <p className="paragraph">{intro?.text}</p>

                                                {intro?.steps?.length > 0 && intro?.steps?.map((step, stepIndex) => <div key={stepIndex} className='whatAreTheComponentsOfASolutionArchitecture-step-div'>
                                                    <h4 className="heading m-0">{step?.title}</h4>
                                                    <p className="paragraph mb-3">{step?.paragraph}</p>
                                                    {step?.listing?.length > 0 && <ul className='page-custom-made-application-list'>
                                                        {step?.listing?.map((introList, introListIndex) => <li key={introListIndex}>{introList?.list}</li>)}
                                                    </ul>}
                                                </div>)}
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* Role of Solution Architect */}
                                    <Card className='page-custom-made-application-card overflow-hidden mt-4'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='custom-made-title-div px-4 py-5 w-100'>
                                                {filteredContent?.pageData?.roleOfSolutionArchitect?.heading && <h2 className='m-0'>{filteredContent?.pageData?.roleOfSolutionArchitect?.heading}</h2>}
                                                {/* {filteredContent?.pageData?.doYouNeedCustomMadeApplication?.headingTwo && <h3 className='m-0'>{filteredContent?.pageData?.doYouNeedCustomMadeApplication?.headingTwo}</h3>} */}
                                            </div>
                                            <div className='d-flex gap-2 pe-4 justify-content-end w-100'>
                                                <div className='d-flex align-items-end' style={{ marginBottom: '-13px' }}>
                                                    <RoleOfSolutionArchitectIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <div className="page-intro-content">
                                        {filteredContent?.pageData?.roleOfSolutionArchitect?.paragraph?.map((intro, introIndex) =>
                                            <Fragment key={introIndex}>
                                                <p className="paragraph">{intro?.text}</p>
                                                {intro?.listing?.length > 0 && <ul className='page-custom-made-application-list'>
                                                    {intro?.listing?.map((introList, introListIndex) => <li key={introListIndex}>{introList?.listData}</li>)}
                                                </ul>}
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* What does a solution architect do? BAITS */}
                                    <div className="page-intro-content">
                                        <h1 className="heading">{filteredContent?.pageData?.whatDoesASolutionArchitectDoBAITS?.heading} <span>{filteredContent?.pageData?.whatDoesASolutionArchitectDoBAITS?.headingTwo}</span></h1>
                                        {filteredContent?.pageData?.whatDoesASolutionArchitectDoBAITS?.paragraph?.map((intro, introIndex) =>
                                            <Fragment key={introIndex}>
                                                <div className='whatAreTheComponentsOfASolutionArchitecture-step-div'>
                                                    <h4 className="heading m-0">{intro?.title}</h4>
                                                </div>
                                                <p className="paragraph">{intro?.text}</p>

                                                {intro?.steps?.length > 0 && intro?.steps?.map((step, stepIndex) => <div key={stepIndex} className='whatAreTheComponentsOfASolutionArchitecture-step-div'>
                                                    <p className="paragraph mb-3">{step?.paragraph}</p>
                                                    {step?.listing?.length > 0 && <ul className='page-custom-made-application-list'>
                                                        {step?.listing?.map((introList, introListIndex) => <li key={introListIndex}>{introList?.list}</li>)}
                                                    </ul>}
                                                </div>)}
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* Benefits of a Solution Architecture */}
                                    <Card className='page-custom-made-application-card overflow-hidden mt-4'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='custom-made-title-div px-4 py-5 w-100'>
                                                {filteredContent?.pageData?.benefitsOfASolutionArchitecture?.heading && <h2 className='m-0'>{filteredContent?.pageData?.benefitsOfASolutionArchitecture?.heading}</h2>}
                                                {filteredContent?.pageData?.benefitsOfASolutionArchitecture?.headingTwo && <h3 className='m-0'>{filteredContent?.pageData?.benefitsOfASolutionArchitecture?.headingTwo}</h3>}
                                            </div>
                                            <div className='d-flex gap-2 pe-4 justify-content-end w-100'>
                                                <div className='d-flex align-items-end' style={{ marginBottom: '-32px' }}>
                                                    <BenefitsIcon />
                                                    {/* <LazyLoadImage
                                                        className="w-100 img-fluid m-0"
                                                        alt={'Fre8-Pro'}
                                                        // height={image.height}
                                                        src={BenefitsImg} // use normal <img> attributes as props
                                                    // width={image.width}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <div className="page-intro-content">
                                        {filteredContent?.pageData?.benefitsOfASolutionArchitecture?.paragraph?.map((intro, introIndex) =>
                                            <p key={introIndex} className="paragraph">{intro?.text}</p>
                                        )}
                                        {filteredContent?.pageData?.benefitsOfASolutionArchitecture?.steps?.length > 0 && filteredContent?.pageData?.benefitsOfASolutionArchitecture?.steps?.map((step, stepIndex) => <div key={stepIndex} className='whatAreTheComponentsOfASolutionArchitecture-step-div'>
                                            <h4 className="heading m-0">{step?.title}</h4>
                                            <p className="paragraph mb-3">{step?.paragraph}</p>
                                            {step?.listing?.length > 0 && <ul className='page-custom-made-application-list'>
                                                {step?.listing?.map((introList, introListIndex) => <li key={introListIndex}>{introList?.list}</li>)}
                                            </ul>}
                                        </div>)}
                                    </div>
                                    {/* Conclusion */}
                                    <Card className='page-custom-made-application-card overflow-hidden mt-4'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='custom-made-title-div px-4 py-5 w-100'>
                                                {filteredContent?.pageData?.conclusion?.heading && <h2 className='m-0'>{filteredContent?.pageData?.conclusion?.heading}</h2>}
                                            </div>
                                            <div className='d-flex gap-2 pe-4 justify-content-end w-100'>
                                                <div className='d-flex align-items-end' style={{ marginBottom: '-0px' }}>
                                                    <ConclusionIcon />
                                                    {/* <LazyLoadImage
                                                        className="w-100 img-fluid m-0"
                                                        alt={'Fre8-Pro'}
                                                        // height={image.height}
                                                        src={BenefitsImg} // use normal <img> attributes as props
                                                    // width={image.width}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <div className="page-intro-content">
                                        {filteredContent?.pageData?.conclusion?.paragraph?.map((intro, introIndex) =>
                                            <p key={introIndex} className="paragraph">{intro?.text}</p>
                                        )}
                                    </div>
                                </Col>
                                <Col lg="4" md="4">
                                    <Card className="custom_card">
                                        <CardHeader>Share</CardHeader>
                                        <CardBody>
                                            <div className="round-social-icon d-flex no-block gap-2">
                                                <Link
                                                    href="#"
                                                    className="link rounded-circle d-flex no-block align-items-center justify-content-center">
                                                    <LinkedinIcon />
                                                </Link>
                                                <Link
                                                    href="#"
                                                    className="link rounded-circle d-flex no-block align-items-center justify-content-center">
                                                    <InstagramIcon />
                                                </Link>
                                                <Link
                                                    href="#"
                                                    className="link rounded-circle d-flex no-block align-items-center justify-content-center">
                                                    <FacebookIconOne />
                                                </Link>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="custom_card">
                                        <CardHeader>Tags</CardHeader>
                                        <CardBody>
                                            <p className="tag">Lorem Ipsum</p>
                                            <p>Lorem Ipsum</p>
                                        </CardBody>
                                    </Card>
                                    <Card className="custom_card">
                                        <CardHeader>About Author</CardHeader>
                                        <CardBody>
                                            <div className="author-about">
                                                <h1>Vasumathi Padmanabh</h1>
                                                <p>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                                    industry. Lorem Ipsum has been the industry's standard dummy
                                                    text ever since the 1500s, when an unknown printer took a galley
                                                    of type and scrambled it to make a type specimen book. It has
                                                    survived not only five centuries, but also the leap into
                                                    electronic typesetting, remaining essentially unchanged. It was
                                                    popularised in the 1960s with the release of Letraset sheets
                                                    containing Lorem Ipsum passages, and more recently with desktop
                                                    publishing software like Aldus PageMaker including versions of
                                                    Lorem Ipsum.
                                                </p>
                                            </div>
                                        </CardBody>
                                        <CardFooter>Blogs By Vasu Padmanaban</CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Container> : <Container className="page-blog-content pb-3 text-center"><p>No content found for this ID.</p></Container>}
                        <div className="our-latest-blogs position-relative overflow-hidden">
                            <div className="position-absolute" style={{ inset: 0 }}>
                                <div className="acss-lbif7z acss-b61x89"></div>
                            </div>
                            <OurLatestBlogs />
                        </div>
                        <SoftwareTeamHunt data={blogsDetailData?.softwareTeamHunt} />
                        <FrequentlyAskedQuestions
                            data={ContactPageData?.FrequentlyAskedQuestionsData}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

ViewBlogDetailScreen.propTypes = {
    classes: PropTypes.object,
}

export default ViewBlogDetailScreen
