import React, { useState } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Container, Row } from 'reactstrap';

const ProductEvolution = (props) => {
    const productEvolution = props?.data;
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    
    return (
        <div className="ProductEvolution">
            <Container className="static-container">
                <Row className='pb-4'>
                    <Col lg="11" md="11" className="align-self-center m-auto product-evolution-bg">
                        <Accordion open={open} toggle={toggle}>
                            {productEvolution?.map((product, index) => {
                                return (
                                    <AccordionItem key={index} className='my-accordion'>
                                        <AccordionHeader className={open ? 'open' : ''} targetId={`${index}`}>{ product?.question}</AccordionHeader>
                                        {product?.answer?.map((ans, innerIndex) => {
                                            return (
                                                < AccordionBody key={innerIndex} accordionId={`${index}`}>
                                                    <p>
                                                        <strong>{ans?.title}</strong> {ans?.text}
                                                    </p>
                                                </AccordionBody>
                                            )
                                        })}
                                    </AccordionItem>
                                )
                            })}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductEvolution;
