import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const CaseStudyHeader = (props) => {
    const headerData = props?.data;

    return (
        <Container className='top-container'>
            <Row className="justify-content-start">
                <Col lg="10" md="10" className="align-self-start">
                    <h4>{ headerData?.title}</h4>
                    <p className='w-75 m-0 pe-5'>{ headerData?.subTitle}</p>
                </Col>
            </Row>
        </Container>
    );
}

export default CaseStudyHeader;
