/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';

const ClientCount = (props) => {
    const counts = props?.data;
    const [animatedCounts, setAnimatedCounts] = useState(counts.map(({ value }) => 0));

    useEffect(() => {
        counts.forEach((count, index) => {
            let start = 0;
            const end = count.value;
            if (typeof end === 'number') {
                const incrementTime = Math.floor(2000 / end); // Total animation duration: 2 seconds
                const timer = setInterval(() => {
                    start += 1;
                    setAnimatedCounts((prev) =>
                        prev?.map((val, i) => (i === index ? Math.min(start, end) : val))
                    );
                    if (start === end) clearInterval(timer);
                }, incrementTime);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <section className='main-client'>
            <div className="mini-spacer client-count text-white">
                <Container>
                    <div className="d-flex justify-content-between">
                        {counts?.map((count, index) => (
                            <div key={count.id} className="display-7 text-center">
                                <h1 className="m-0">
                                    {index === 3 ? `${animatedCounts[index]}%` : animatedCounts[index] > 10 ? animatedCounts[index] : `0${animatedCounts[index]}`}
                                </h1>
                                <span>{count.label}</span>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default ClientCount;