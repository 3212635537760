import React from "react";
import PropTypes from "prop-types";
import { CaseStudyData, techIndustryQualityData } from "../../common";
import { Container } from "reactstrap";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import ProjectBanner from "../../components/Project/ProjectBanner";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import CaseStudyApplications from "../../components/CaseStudy/CaseStudyApplications";
import TechIndustryQuality from "../../components/TechIndustryQuality/TechIndustryQuality";
import OptimizedPoc from "../../components/CaseStudy/OptimizedPoc";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const ProjectBanner = Loadable(
//     lazy(() => import('../../components/Project/ProjectBanner'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )
// const CaseStudyApplications = Loadable(
//     lazy(() => import('../../components/CaseStudy/CaseStudyApplications'))
// )
// const TechIndustryQuality = Loadable(
//     lazy(() => import('../../components/TechIndustryQuality/TechIndustryQuality'))
// )
// const OptimizedPoc = Loadable(
//     lazy(() => import('../../components/CaseStudy/OptimizedPoc'))
// )

const CaseStudyScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="project-page">
                        <ProjectBanner bannerImg={CaseStudyData?.bannerImg} data={CaseStudyData?.headerData} />
                        <Container className='acss-18azjcp' >
                            <div className='custom-pending-top'>
                                <CaseStudyApplications data={CaseStudyData?.models} />
                            </div>
                        </Container>
                        <TechIndustryQuality data={techIndustryQualityData} />
                        <OptimizedPoc />
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                        <FrequentlyAskedQuestions data={CaseStudyData?.FrequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

CaseStudyScreen.propTypes = {
    classes: PropTypes.object
};

export default CaseStudyScreen;
