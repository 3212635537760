import React from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { ContactPageData, viewAllLatestBlogsData } from "../../common";
import SoftwareTeamHunt from "../../components/CaseStudy/SoftwareTeamHunt";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import ContactBanner from "../../components/banner/ContactBanner";
import BlogCard from "../../components/OurLatestBlogs/BlogCard";
import { Container, Form, FormGroup, Input, Label } from "reactstrap";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const SoftwareTeamHunt = Loadable(
//     lazy(() => import('../../components/CaseStudy/SoftwareTeamHunt'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )
// const ContactBanner = Loadable(
//     lazy(() => import('../../components/banner/ContactBanner'))
// )
// const BlogCard = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/BlogCard'))
// )

const ViewBlogScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper view-all-blogs">
                <div className="container-fluid">
                    <div className="project-page">
                        <ContactBanner bannerImg={viewAllLatestBlogsData?.bannerImg} data={viewAllLatestBlogsData?.headerData} />
                        <Container className="page-blog-content py-3">
                            <Form className="d-flex align-items-center flex-wrap justify-content-center gap-3 w-75 m-auto">
                                <Label htmlFor="name">Filter by :</Label>
                                <FormGroup className="form-input m-t-15 w-25">
                                    <Input type="select" className="py-2" name="Budget" id="exampleSelect" placeholder="Tag">
                                        <option>Tag</option>
                                        {viewAllLatestBlogsData?.tags.map((tag, index) => {
                                            return (
                                                <option key={index} value={tag.value}>{tag.label}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="form-input m-t-15 w-25">
                                    <Input className="py-2" type="date" name="Budget" id="exampleSelect" />
                                </FormGroup>
                            </Form>
                            <div className="py-3">
                                <BlogCard data={viewAllLatestBlogsData?.blogs} />
                            </div>
                        </Container>
                        <SoftwareTeamHunt data={viewAllLatestBlogsData?.softwareTeamHunt} />
                        <CustomerReviews />
                        <FrequentlyAskedQuestions data={ContactPageData?.FrequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

ViewBlogScreen.propTypes = {
    classes: PropTypes.object
};

export default ViewBlogScreen;
