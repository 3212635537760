import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
// import { HashLink as Link } from 'react-router-hash-link';
// import techImg from '../../assets/images/landingpage/smiling-executives-waiting-meeting.png'

const OurTeam = (props) => {
    const ourTeamData = props.data;

    return (
        <section className='our-team-component'>
            <div className='tech-content' style={{ backgroundImage: `url(${ourTeamData?.bgImg})` }}>
                <Container className=" pe-5">
                    <Row className="justify-content-start">
                        <Col lg="7" md="7" className="align-self-start">
                            <div className='custom-padding'>
                                <h4>{ourTeamData?.title}</h4>
                                {ourTeamData?.aboutTeam?.map((content, index) => <p key={index}>{content?.paragraph}</p>)}<div className='w-100 Specialized-Technologies p-0 d-flex justify-content-end' style={{ backgroundColor: 'transparent' }}>
                                    <Link to="/our-team" className='View-All-button px-4' style={{ width: 'fit-content' }}>know more</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default OurTeam;
