import React from 'react';
import { Card, CardBody, CardHeader, CardText, Container } from 'reactstrap';
import { BusinessModelsData } from '../../common';
// import { HashLink as Link } from 'react-router-hash-link';

const BusinessModels = () => {
    return (
        <section className='business-models'>
            <div className='custom-pending-top'>
                <div className='my-background'>
                    <div className='product'>
                        <Container className='custom-padding-py-100' >
                            <div className='pb-4 text-center'>
                                <h4 className='mb-4'>{ BusinessModelsData?.pageHeading}</h4>
                                <p className='m-0 px-4'>{ BusinessModelsData?.pageParagraph}</p>
                            </div>
                            <div className='d-flex justify-content-between gap-4'>
                                {BusinessModelsData?.models?.map((item) => {
                                    return (
                                        <Card key={item.id} className='p-4 mt-4 business-card w-100'>
                                            <CardHeader className='border-0'>{ item?.title}</CardHeader>
                                            <CardBody>
                                                <CardText>{ item?.text}</CardText>
                                            </CardBody>
                                        </Card>
                                    )
                                })}
                            </div>
                        </Container>
                    </div>    
                </div>
            </div>
        </section>
    );
}

export default BusinessModels;
