import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';

const OptimizedPoc = () => {
    return (
        <section className='OptimizedPoc-software'>
            <Container className='page-warper'>
                <Row>
                    <Col lg='9' md='6' className='m-auto align-items-center'>
                        <h3>Optimized POC for quick yes <span>nod from stakeholders and investors.</span></h3>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default OptimizedPoc;
