import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';
// import backgroundVideo from '../../assets/images/service/service-banner.png'
// import ClientCounter from './ClientCounter';

const ServicesBanner = (props) => {
    const bannerData = props?.data;
    return (
        <div className="service-banner">
            {/* <img className='w-100' src={backgroundVideo} alt='banner-img' /> */}
            <img className='w-100 h-100' src={props?.bannerImg} alt='banner-img' />
            <Container className="static-container">
                <Row className="justify-content-center pb-4">
                    <Col lg="9" md="10" className="align-self-start">
                        <h4 className="title m-0">{ bannerData?.title}</h4>
                        <p className='subTitle m-0'>{ bannerData?.subTitle}</p>
                    </Col>
                </Row>
                {/* <ClientCounter /> */}
            </Container>
        </div>
    );
}

export default ServicesBanner;
