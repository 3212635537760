import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

const FormComponent = () => {
    return (
        <Form className="contact-form-page">
            <FormGroup className="form-input m-t-15">
                <Label htmlFor="name">Name</Label>
                <Input type="text" placeholder="name" />
            </FormGroup>
            <FormGroup className="form-input m-t-15">
                <Label htmlFor="email">Email</Label>
                <Input type="text" placeholder="you@gmail.com" />
            </FormGroup>
            <FormGroup className="form-input m-t-15">
                <Label htmlFor="location">Location</Label>
                <Input type="text" placeholder="Location" />
            </FormGroup>
            <FormGroup className="form-input m-t-15">
                <Label for="exampleSelect">Budget</Label>
                <Input type="select" name="Budget" id="exampleSelect">
                    <option>50k - 150k</option>
                    <option>150k - 200k</option>
                    <option>200k - 250k</option>
                    <option>250k - 300</option>
                    <option>300k - 350k</option>
                    <option>350k - 400k</option>
                    <option>400k - 450k</option>
                    <option>450k - 500k</option>
                </Input>
            </FormGroup>
            <FormGroup className="form-input form-text-area m-t-15">
                <Label htmlFor="project-description">Project description</Label>
                <Input
                    type="textarea"
                    name="text"
                    placeholder="Project description"
                />
            </FormGroup>
            <Button
                type="submit"
                className="m-t-20 submit-btn"
            >
                <span>
                    Get in Touch <i className="ti-arrow-right"></i>
                </span>
            </Button>
        </Form>
    );
}

export default FormComponent;
