import React from 'react';
import { Link } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';

const CaseStudyTakeALook = () => {
    return (
        <div className='no-block d-flex justify-content-between'>
            <div className='Take-a-Look'>
                <div className='d-flex justify-content-start align-items-start'>
                    <div>
                        <h1 className='m-0'>Take a Look</h1>
                        <div className='d-flex gap-4 align-items-end'>
                            <h1 className='m-0'>at What</h1>
                            <div className='mb-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="153" height="2" viewBox="0 0 153 2" fill="none">
                                    <path d="M0.656738 1.27482H153.005" stroke="#444444" strokeWidth="0.674754"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='custom-circle-view-all'>
                <Link to={'/case-study'}>
                    <h2>View All</h2>
                </Link>
            </div>
            <div className='no-block Take-a-Look d-flex align-items-end'>
                <div>
                    <h1 className='text-end m-0'>We Can Do</h1>
                    <div className='no-block d-flex gap-4 align-items-end'>
                        <div className='mb-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="153" height="2" viewBox="0 0 153 2" fill="none">
                                <path d="M0.656738 1.27482H153.005" stroke="#444444" strokeWidth="0.674754"/>
                            </svg>
                        </div>
                        <h1 className='text-end m-0'>For You</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaseStudyTakeALook;
