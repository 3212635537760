import React, { useEffect } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';
import thumbnailImg from '../../assets/images/landingpage/Hero-thumbnail.jpg';
import { Link } from 'react-router-dom';
// import backgroundVideo from '../../assets/images/landingpage/Hero-Banner.mp4'

const HeaderBanner = (props) => {
    // console.log('---data-->', props)
    const bannerData = props?.data;
    const bannerBgImg = props?.bannerImg;
    function myFunction() { 
         document.getElementById("mp4_src").src = bannerBgImg;
        //  document.getElementById("ogg_src").src = "movie.ogg";
         document.getElementById("myVideo").load();
         console.log('===11111======bannerBgImg====>',document.getElementById("myVideo").load())
         console.log('===22222======bannerBgImg====>',document.getElementById("mp4_src").src = bannerBgImg  )
     } 
    useEffect(() => {
        myFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    console.log('=========bannerBgImg====>',bannerBgImg)
    return (
        <div className="static-slider-head">
            <video id="myVideo" loop muted autoPlay preload="metadata" poster={thumbnailImg} className='w-100'>
                <source id="mp4_src" src={bannerBgImg} type="video/mp4" />
                {/* <source id="ogg_src" src="mov_bbb.ogg" type="video/ogg" /> */}
                Your browser does not support HTML5 video.
            </video>
                {/* <video autoPlay loop muted id='video' preload="auto" poster="../../assets/images/landingpage/Hero-thumbnail.webp" className='w-100'>
                    <source src={bannerBgImg} type='video/mp4' />
                </video> */}
            <Container className="static-container">
                <Row className="justify-content-start">
                    <Col lg="8" md="6" className="align-self-start">
                        <h4>{bannerData?.title} <span>{bannerData?.subTitle}</span> {bannerData?.subTitle2}</h4>
                        <p>{bannerData?.text}</p>
                        {/* <h1 className="title">VarahiSoft Lite</h1> */}
                        {/* <h4 className="subtitle font-light">Powerful Reactstrap UI Kit with<br /> Beautiful Pre-Built Demos</h4> */}
                        <Link to={bannerData?.link} className="btn btn-md m-t-30 btn-info-gradiant font-14 rounded-5">
                            {bannerData?.btnName} {bannerData?.btnIcon}
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderBanner;
