import React, { Fragment, useState } from 'react';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Container } from 'reactstrap';
import TestimonialCarousel from './Slider';
import { customerReviewsData } from '../../common';
// import { HashLink as Link } from 'react-router-hash-link';


const CustomerReviews = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === customerReviewsData?.reviews?.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? customerReviewsData?.reviews?.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }


    const slides = customerReviewsData?.reviews?.map((item, index) => (
        <CarouselItem
            className="custom-tag"
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={index}
        >
            <div className="review-box no-block d-flex align-items-center justify-content-center flex-column">
              <div className="review-box-paragraph testimonial-slide">{item.paragraph}</div>
              {activeIndex === index && <Fragment>
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="16" viewBox="0 0 37 16" fill="none">
                  <path d="M36.4858 0.126953H0.48584L12.1164 13.0149C15.757 17.049 22.115 16.9614 25.643 12.8286L36.4858 0.126953Z" fill="white" />
                </svg>
                <div className="reviewer-img mt-2" style={{ background: `url(${item.userImg}) lightgray 50% / cover no-repeat` }} />
                <h2 className="m-0">{item.name}</h2>
                <h3 className="m-0">{item.designation}</h3>
              </Fragment>}
            </div>
            {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
        </CarouselItem>
    ));

    return (
        <section className='customer-reviews'>
            <Container className='custom-padding-py-100' >
                <div className='py-2 text-center mb-5'>
                    <h4>{ customerReviewsData?.pageHeading}</h4>
                    <p className='m-0'>{ customerReviewsData?.pageParagraph}</p>
                </div>
                <div className='h-100 muCustom-carousel' style={{height: '400px'}}>
                    <TestimonialCarousel />
                </div>
                <div className='only-mobile-view'>
                    <Carousel
                            activeIndex={activeIndex}
                            next={next.bind(null)}
                            previous={previous.bind(null)}
                        >
                            <CarouselIndicators items={customerReviewsData.reviews} activeIndex={activeIndex} onClickHandler={goToIndex.bind(null)} />
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous.bind(null)} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next.bind(null)} />
                        </Carousel>
                </div>
            </Container>
        </section>
    );
}

export default CustomerReviews;
