import React from "react";
import PropTypes from "prop-types";
import { Fre8ProApplicationData } from "../../common";
import { Container } from "reactstrap";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import ProjectBanner from "../../components/Project/ProjectBanner";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import CaseStudyApplications from "../../components/CaseStudy/CaseStudyApplications";
import Fre8ProApplicationDetailContent from "../../components/CaseStudy/Fre8ProApplicationDetailContent";
import SoftwareTeamHunt from "../../components/CaseStudy/SoftwareTeamHunt";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const ProjectBanner = Loadable(
//     lazy(() => import('../../components/Project/ProjectBanner'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )
// const CaseStudyApplications = Loadable(
//     lazy(() => import('../../components/CaseStudy/CaseStudyApplications'))
// )
// const Fre8ProApplicationDetailContent = Loadable(
//     lazy(() => import('../../components/CaseStudy/Fre8ProApplicationDetailContent'))
// )
// const SoftwareTeamHunt = Loadable(
//     lazy(() => import('../../components/CaseStudy/SoftwareTeamHunt'))
// )

const Fre8ProApplicationDetail = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper fre8Pro-application-detail">
                <div className="container-fluid">
                    <div className="project-page">
                        <ProjectBanner bannerImg={Fre8ProApplicationData?.bannerImg} data={Fre8ProApplicationData?.headerData} />
                        <div className="position-relative">
                            <Container>
                                <div className='custom-pending-top'>
                                    <div className="py-5">
                                        <CaseStudyApplications data={Fre8ProApplicationData?.models} />
                                    </div>
                                    <Fre8ProApplicationDetailContent />
                                </div>
                            </Container>
                        </div>
                        <SoftwareTeamHunt data={Fre8ProApplicationData?.softwareTeamHunt} />
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                        <FrequentlyAskedQuestions data={Fre8ProApplicationData?.FrequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

Fre8ProApplicationDetail.propTypes = {
    classes: PropTypes.object
};

export default Fre8ProApplicationDetail;
