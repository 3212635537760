import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { methodologyData } from '../../common';

const ScrumMethodology = () => {
    return (
        <section className='ScrumMethodology'>
            <Container className='page-warper'>
                <Row className='page-heading'>
                    <Col lg="7" md="7" className="align-self-start">
                        <h4>{ methodologyData?.title}</h4>
                        <p>{ methodologyData?.paragraph}</p>
                    </Col>
                </Row>
                <div className='custom-card d-flex align-items-center justify-content-between gap-4'>
                    {methodologyData?.methodologies?.map((methodology, index) => {
                        return (
                            <Card key={index} className='p-4 mt-4'>
                                <CardHeader className='border-0'>
                                    { methodology?.icon}
                                </CardHeader>
                                <CardBody>
                                    <p>{ methodology?.paragraph}</p>
                                </CardBody>
                            </Card>
                        )
                    })}
                </div>
            </Container>
        </section>
    );
}

export default ScrumMethodology;
