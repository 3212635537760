import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Introducing delay using setTimeout
    // const scrollDelay = 900; // Delay in milliseconds (0.9 seconds)

    if (typeof window !== "undefined" && window.scrollTo) {

      // Apply fade-out effect (if using it) before scrolling
      // document.body.classList.add('fade-out');

      // setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth', // Enables smooth scrolling
      });

      // Apply fade-in effect after scrolling
      // document.body.classList.remove('fade-out');
      // document.body.classList.add('fade-in');

      // }, scrollDelay);
    }
    // window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
