import React from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import AboutUsBanner from "../../components/AboutUs/AboutUsBanner";
import { TermsOfUsePageData } from "../../common";
import { Container } from "reactstrap";
// import { HashLink as Link } from 'react-router-hash-link';

// import Loadable from "../../components/Loadable"
import { Link } from "react-router-dom";

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const AboutUsBanner = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutUsBanner'))
// )

const TermsOfUseScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper privacy-policy-page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <AboutUsBanner bannerImg={TermsOfUsePageData?.bannerImg} data={TermsOfUsePageData?.headerData} />
                        <section className="privacy-policy-page-wrapper">
                            <Container>
                                <div className="content">
                                    {TermsOfUsePageData?.aboutText?.title && <h2 className="title m-t-0">{TermsOfUsePageData?.aboutText?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.textData.map((item, index) => <div key={index} className="first-content">
                                        {item?.paragraph && <p >{item?.paragraph} {item?.link && <Link className="link" to={item?.link}>{item?.link}</Link>} {item?.paragraph1}</p>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}>{ list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {TermsOfUsePageData?.aboutText?.agreement?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.agreement?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.agreement?.collects?.map((item, index) => <div key={index} className="first-content">
                                        {item?.title && <strong>{item?.title} </strong>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && item?.listData.map((list, index) => <p key={index}><strong>{ list?.title} </strong>{ list?.text}</p>)}
                                    </div>)}
                                    {TermsOfUsePageData?.aboutText?.commentsAndPosting?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {TermsOfUsePageData?.aboutText?.contentLiability?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.contentLiability?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.contentLiability?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* cookies */}
                                    {TermsOfUsePageData?.aboutText?.cookies?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.cookies?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.cookies?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* Disclaimer of Warranties */}
                                    {TermsOfUsePageData?.aboutText?.disclaimerOfWarranties?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.disclaimerOfWarranties?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.disclaimerOfWarranties?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* effective Date */}
                                    {TermsOfUsePageData?.aboutText?.effectiveDate?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.effectiveDate?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.effectiveDate?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* hyper linking To Our Content */}
                                    {TermsOfUsePageData?.aboutText?.hyperlinkingToOurContent?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.hyperlinkingToOurContent?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.hyperlinkingToOurContent?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* iFrames */}
                                    {TermsOfUsePageData?.aboutText?.iFrames?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.iFrames?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.iFrames?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* links To Third Party Sites */}
                                    {TermsOfUsePageData?.aboutText?.linksToThirdPartySites?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.linksToThirdPartySites?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.linksToThirdPartySites?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* license And Intellectual Property Ownership */}
                                    {TermsOfUsePageData?.aboutText?.licenseAndIntellectualPropertyOwnership?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.licenseAndIntellectualPropertyOwnership?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.licenseAndIntellectualPropertyOwnership?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* indemnity */}
                                    {TermsOfUsePageData?.aboutText?.indemnity?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.indemnity?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.indemnity?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* limitationOfLiability */}
                                    {TermsOfUsePageData?.aboutText?.limitationOfLiability?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.limitationOfLiability?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.limitationOfLiability?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* Removal of Links From Varahisoft.com */}
                                    {TermsOfUsePageData?.aboutText?.removalOfLinksFromVarahisoft?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.removalOfLinksFromVarahisoft?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.removalOfLinksFromVarahisoft?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* Reservation Of Rights */}
                                    {TermsOfUsePageData?.aboutText?.reservationOfRights?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.reservationOfRights?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.reservationOfRights?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* Use of Terminology */}
                                    {TermsOfUsePageData?.aboutText?.useOfTerminology?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.useOfTerminology?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.useOfTerminology?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* Your Privacy */}
                                    {TermsOfUsePageData?.aboutText?.yourPrivacy?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.yourPrivacy?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.yourPrivacy?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                    {/* Miscellaneous */}
                                    {TermsOfUsePageData?.aboutText?.miscellaneous?.title && <h2 className="title">{TermsOfUsePageData?.aboutText?.miscellaneous?.title}</h2>}
                                    {TermsOfUsePageData?.aboutText?.miscellaneous?.collects?.map((item, index) => <div key={index} className="first-content m-t-20">
                                        {item?.title && <p><strong>{item?.title} </strong></p>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}><strong>{list?.title} </strong>{list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                </div>
                                <div className="footer-content-my">
                                    <h1>{TermsOfUsePageData?.aboutText?.footerTitle}</h1>
                                </div>
                            </Container>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

TermsOfUseScreen.propTypes = {
    classes: PropTypes.object
};

export default TermsOfUseScreen;
