import React from 'react';
import { Container } from 'reactstrap';
import AccordionCard from './AccordionCard';
import { Link } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';

function FrequentlyAskedQuestions(props) {
    const faqData = props?.data;

    return (
        <div className='frequently-asked-questions'>
            <div className='custom-padding-py-100'>
                <Container>
                    <div className={`d-flex justify-content-${faqData?.buttonName ? 'start' : 'center'} align-self-${faqData?.buttonName ? 'start' : 'center'} gap-5 pb-4`}>
                        <div className={`w-100 faq-header text-${faqData?.buttonName ? 'start' : 'center'}`}>
                            <h4>{ faqData?.title}</h4>
                            <p className='m-0'>{ faqData?.subTitle}</p>    
                        </div>
                        {faqData?.buttonName && <div className='d-flex no-block justify-content-end align-self-end w-50'>
                            <Link to={'/faq'} className='View-All-button px-4'>
                                <p className='m-0'>{faqData?.buttonName}</p>
                            </Link>
                        </div>}
                    </div>
                    <div className='m-t-20'>
                        <AccordionCard data={faqData?.questions} />
                    </div>
                </Container>
            </div>
        </div>
  );
}

export default FrequentlyAskedQuestions;