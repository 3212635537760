import React from 'react';
import { Container } from 'reactstrap';

import { ourLatestBlogsData } from '../../common';
// import { HashLink as Link } from 'react-router-hash-link';
import BlogCard from './BlogCard';
import { Link } from 'react-router-dom';

const OurLatestBlogs = () => {
    return (
        <section className='our-latest-blogs'>
            <div className="blog-home2 spacer">
                <Container>
                    <div className={`d-flex justify-content-start align-self-start gap-5 pb-4`}>
                        <div className={`w-100`}>
                            <h2 className="title m-0">{ourLatestBlogsData?.pageHeading}</h2>
                            <h6 className="subtitle m-0">{ourLatestBlogsData?.pageParagraph}</h6>
                        </div>
                        {ourLatestBlogsData?.buttonName && <div className='d-flex justify-content-end align-self-end w-50'>
                            <Link to="/view-all-blog" className="btn View-All-button btn-md">
                                <p className='m-0'>{ourLatestBlogsData?.buttonName}</p>
                            </Link>
                        </div>}
                    </div>
                    {/* <Row className="justify-content-start">
                        <Col md="8" className="text-left">
                            <h2 className="title m-0">{ ourLatestBlogsData?.pageHeading}</h2>
                            <h6 className="subtitle m-0">{ ourLatestBlogsData?.pageParagraph}</h6>
                        </Col>
                    </Row> */}
                    <div className='m-t-40 '>
                        <BlogCard data={ourLatestBlogsData?.blogs} />
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default OurLatestBlogs;
