import React from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import ProjectBanner from "../../components/Project/ProjectBanner";
import ProjectsDetails from "../../components/Project/ProjectsDetails";
import BusinessModels from "../../components/Project/BusinessModels";
import DreamSoftware from "../../components/Project/DreamSoftware";
import { projectsDetailsData } from "../../common";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )
// const ProjectBanner = Loadable(
//     lazy(() => import('../../components/Project/ProjectBanner'))
// )
// const ProjectsDetails = Loadable(
//     lazy(() => import('../../components/Project/ProjectsDetails'))
// )
// const BusinessModels = Loadable(
//     lazy(() => import('../../components/Project/BusinessModels'))
// )
// const DreamSoftware = Loadable(
//     lazy(() => import('../../components/Project/DreamSoftware'))
// )

const ProjectScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="project-page">
                        <ProjectBanner bannerImg={projectsDetailsData?.bannerImg} data={projectsDetailsData?.headerData} />
                        <ProjectsDetails data={projectsDetailsData?.productEngineering} />
                        <BusinessModels />
                        <DreamSoftware />
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                        <FrequentlyAskedQuestions data={projectsDetailsData?.FrequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

ProjectScreen.propTypes = {
    classes: PropTypes.object
};

export default ProjectScreen;
