import React from 'react';
import { Container } from 'reactstrap';
import ellipseImageTop from '../../assets/images/landingpage/Ellipse1880.png'

const AboutContent = (props) => {
    const aboutContentData = props?.data;
    return (
        <section className='about-content'>
            <div className="acss-1kbtli">
                <div className='position-absolute' style={{ inset:0 }}>
                    <div className="acss-lbif7z acss-b61x89">
                        <img className="acss-61n50j acss-118odl4" src={ellipseImageTop} alt="ellipseImage" />
                    </div>
                    <div></div>
                </div>
                <Container className='' style={{ paddingBlock: '50px' }} >
                    <div className='page-content'>
                        {aboutContentData?.map((content, index) => <p key={index}>{ content?.paragraph}</p>)}
                    </div>                    
                </Container>
            </div>
        </section>
    );
}

export default AboutContent;
