import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

const ClientCounter = (props) => {
    const counts = props?.data;
    const [animatedCounts, setAnimatedCounts] = useState(counts.map(({ value }) => 0));

    useEffect(() => {
        counts.forEach((count, index) => {
            let start = 0;
            const end = count.value;
            if (typeof end === 'number') {
                const incrementTime = Math.floor(2000 / end); // Total animation duration: 2 seconds
                const timer = setInterval(() => {
                    start += 1;
                    setAnimatedCounts((prev) =>
                        prev?.map((val, i) => (i === index ? Math.min(start, end) : val))
                    );
                    if (start === end) clearInterval(timer);
                }, incrementTime);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="client-counter">
            <Container className="page-warper">
                <Card className="p-4 mt-4">
                    <CardBody className="p-4">
                        <div className="d-flex no-block gap-1 justify-content-between">
                            {counts?.map((count, index) => (
                                <div key={count.id} className="display-7 text-center">
                                    <h1 className="m-0">
                                        {index === 3 ? `${animatedCounts[index]}%` : animatedCounts[index] > 10 ? animatedCounts[index] : `0${animatedCounts[index]}`}
                                    </h1>
                                    <span>{count.label}</span>
                                </div>
                            ))}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </section>
    );
}

export default ClientCounter;
