import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Container } from 'reactstrap';

const PocBox = (props) => {
    const pocBoxData = props?.data;
    return (
        <section className='poc-service'>
            <Container className='page-warper'>
                <h3>{ pocBoxData?.title} <span>{ pocBoxData?.subtitle}</span>{ pocBoxData?.subtitle2}</h3>
            </Container>
        </section>
    );
}

export default PocBox;
