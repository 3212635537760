/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';

const YourIdea = (props) => {
    const yourIdeaData = props?.data;
    const [text, setText] = useState(yourIdeaData?.subtitle1);

    useEffect(() => {
        const interval = setInterval(() => {
            setText((prevText) => {
                if (prevText.includes("Idea")) {
                    return "Time";
                } else if(prevText.includes("Time")) {
                    return "Product";
                } else if(prevText.includes("Product")) {
                    return "Money"
                } else {
                    return "Idea"
                }
            });
        }, 1000); // Change text every second

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);
    return (
        <section className='your-ideas' style={{ backgroundImage: `url(${yourIdeaData?.bgImg})` }}>
            <div className="client-count text-white">
                <Container>
                    <Row className='Fre8-Pro'>
                        <Col lg="9" md='9' className='ps-0 m-auto d-flex justify-content-between align-items-center gap-3'>
                            <div className="display-7 align-self-center">
                                <h1 className='m-0 your'>{ yourIdeaData?.title}</h1>
                            </div>
                            <div className="display-7 align-self-center">
                                <h1 className='m-0 idea'>{text}</h1>
                                {/* <div>{text}</div> */}
                            </div>
                            <div className="display-7 align-self-center">
                                <h1 className='m-0 is-more'>{ yourIdeaData?.subtitle2}</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default YourIdea;
