import React from 'react';
import { Container } from 'reactstrap';
import { howVarahiSoftWorks, whyVarahiSoft } from '../../common';
// import { HashLink as Link } from 'react-router-hash-link';
// import techImg from '../../assets/images/landingpage/smiling-executives-waiting-meeting.png'

const WhyHow = () => {

    return (
        <section className='why-how-component'>
            <div className='tech-content'>
                <Container className="">
                    <div className='custom-padding d-flex align-items-center justify-content-between gap-4'>
                        <div className='w-100'>
                            <h4>{whyVarahiSoft?.title}</h4>
                            {whyVarahiSoft?.paragraph?.map((item, i) => <p key={i}>{ item?.text}</p>)}
                        </div>
                        <div className='pe-5'>
                            <div className='pe-5'>
                                <h4>{ howVarahiSoftWorks?.title}</h4>
                                <ul>
                                    {howVarahiSoftWorks?.worksData?.map((workData, index) => <li key={index}><p>{ workData?.text}</p></li> )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default WhyHow;
