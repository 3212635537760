import React from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import OurLatestBlogs from "../../components/OurLatestBlogs/OurLatestBlogs";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import ServicesBanner from "../../components/Services/ServicesBanner";
import ProductEngineering from "../../components/Services/ProductEngineering";
import ProductEvolution from "../../components/Services/ProductEvolution";
import OurServicesAtAGlance from "../../components/Services/OurServicesAtAGlance";
import BestReasons from "../../components/Services/BestReasons";
import PocBox from "../../components/Services/PocBox";
import { FrequentlyAskedQuestionsData, ServicesPageData } from "../../common";

// import Loadable from "../../components/Loadable"

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const CustomerReviews = Loadable(
//     lazy(() => import('../../components/CustomerReviews/CustomerReviews'))
// )
// const OurLatestBlogs = Loadable(
//     lazy(() => import('../../components/OurLatestBlogs/OurLatestBlogs'))
// )
// const ContactUsComponent = Loadable(
//     lazy(() => import('../../components/ContactUsComponent/ContactUsComponent'))
// )
// const FrequentlyAskedQuestions = Loadable(
//     lazy(() => import('../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'))
// )
// const ServicesBanner = Loadable(
//     lazy(() => import('../../components/Services/ServicesBanner'))
// )
// const ProductEngineering = Loadable(
//     lazy(() => import('../../components/Services/ProductEngineering'))
// )
// const ProductEvolution = Loadable(
//     lazy(() => import('../../components/Services/ProductEvolution'))
// )
// const OurServicesAtAGlance = Loadable(
//     lazy(() => import('../../components/Services/OurServicesAtAGlance'))
// )
// const BestReasons = Loadable(
//     lazy(() => import('../../components/Services/BestReasons'))
// )
// const PocBox = Loadable(
//     lazy(() => import('../../components/Services/PocBox'))
// )

const ServicesScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <ServicesBanner bannerImg={ServicesPageData?.bannerImg} data={ServicesPageData?.headerData} />
                        <ProductEngineering data={ServicesPageData?.productEngineering} />
                        <ProductEvolution data={ServicesPageData?.productEvolution} />
                        <OurServicesAtAGlance />
                        <BestReasons />
                        <PocBox data={ServicesPageData?.pocBox} />
                        <CustomerReviews />
                        <OurLatestBlogs />
                        <ContactUsComponent />
                        <FrequentlyAskedQuestions data={FrequentlyAskedQuestionsData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

ServicesScreen.propTypes = {
    classes: PropTypes.object
};

export default ServicesScreen;
