import React from "react";
import PropTypes from "prop-types";
// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import AboutUsBanner from "../../components/AboutUs/AboutUsBanner";
import { PrivacyPolicyPageData } from "../../common";
import { Container } from "reactstrap";
// import { HashLink as Link } from 'react-router-hash-link';

// import Loadable from "../../components/Loadable"
import { Link } from "react-router-dom";

// const Header = Loadable(
//     lazy(() => import('../../components/header/header'))
// )
// const Footer = Loadable(
//     lazy(() => import('../../components/footer/footer'))
// )
// const AboutUsBanner = Loadable(
//     lazy(() => import('../../components/AboutUs/AboutUsBanner'))
// )

const PrivacyPolicyScreen = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper privacy-policy-page-wrapper">
                <div className="container-fluid">
                    <div className="page-content-top">
                        <AboutUsBanner bannerImg={PrivacyPolicyPageData?.bannerImg} data={PrivacyPolicyPageData?.headerData} />
                        <section className="privacy-policy-page-wrapper">
                            <Container>
                                <div className="content">
                                    {PrivacyPolicyPageData?.aboutText?.textData.map((item, index) => <div key={index} className="first-content">
                                        {item?.paragraph && <p >{item?.paragraph} {item?.link && <Link className="link" to={item?.link}>{item?.link}</Link>} {item?.paragraph1}</p>}
                                        {item?.listData.length > 0 && <ul>
                                            {item?.listData.map((list, index) => <li key={index}>{ list?.text}</li>)}
                                        </ul>}
                                    </div>)}
                                        {PrivacyPolicyPageData?.aboutText?.informationWeCollect?.title && <h2 className="title">{PrivacyPolicyPageData?.aboutText?.informationWeCollect?.title}</h2>}
                                    {PrivacyPolicyPageData?.aboutText?.informationWeCollect?.collects?.map((item, index) => <div key={index} className="first-content">
                                        {item?.title && <strong>{item?.title} </strong>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && item?.listData.map((list, index) => <p key={index}><strong>{ list?.title} </strong>{ list?.text}</p>)}
                                    </div>)}
                                        {PrivacyPolicyPageData?.aboutText?.legitimateUseAndTransfersOfData?.title && <h2 className="title">{PrivacyPolicyPageData?.aboutText?.legitimateUseAndTransfersOfData?.title}</h2>}
                                    {PrivacyPolicyPageData?.aboutText?.legitimateUseAndTransfersOfData?.collects?.map((item, index) => <div key={index} className="first-content">
                                        {item?.title && <strong>{item?.title} </strong>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && item?.listData.map((list, index) => <p key={index}><strong>{ list?.title} </strong>{ list?.text}</p>)}
                                    </div>)}
                                        {PrivacyPolicyPageData?.aboutText?.safeguardOfYourInformation?.title && <h2 className="title">{PrivacyPolicyPageData?.aboutText?.safeguardOfYourInformation?.title}</h2>}
                                    {PrivacyPolicyPageData?.aboutText?.safeguardOfYourInformation?.collects?.map((item, index) => <div key={index} className="first-content">
                                        {item?.title && <strong>{item?.title} </strong>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && item?.listData.map((list, index) => <p key={index}><strong>{ list?.title} </strong>{ list?.text}</p>)}
                                    </div>)}
                                        {PrivacyPolicyPageData?.aboutText?.retentionPeriod?.title && <h2 className="title">{PrivacyPolicyPageData?.aboutText?.retentionPeriod?.title}</h2>}
                                    {PrivacyPolicyPageData?.aboutText?.retentionPeriod?.collects?.map((item, index) => <div key={index} className="first-content">
                                        {item?.title && <strong>{item?.title} </strong>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && item?.listData.map((list, index) => <p key={index}><strong>{ list?.title} </strong>{ list?.text}</p>)}
                                    </div>)}
                                        {PrivacyPolicyPageData?.aboutText?.SubscribeOrUnsubscribe?.title && <h2 className="title">{PrivacyPolicyPageData?.aboutText?.SubscribeOrUnsubscribe?.title}</h2>}
                                    {PrivacyPolicyPageData?.aboutText?.SubscribeOrUnsubscribe?.collects?.map((item, index) => <div key={index} className="first-content">
                                        {item?.title && <strong>{item?.title} </strong>}
                                        {item?.paragraph && <span>{item?.paragraph}</span>}
                                        {item?.listData.length > 0 && item?.listData.map((list, index) => <p key={index}><strong>{ list?.title} </strong>{ list?.text}</p>)}
                                    </div>)}
                                        {PrivacyPolicyPageData?.aboutText?.questionsAboutThisPolicy?.title && <h2 className="title">{PrivacyPolicyPageData?.aboutText?.questionsAboutThisPolicy?.title}</h2>}
                                    {PrivacyPolicyPageData?.aboutText?.questionsAboutThisPolicy?.collects?.map((item, index) => <div key={index} className="first-content">
                                        {item?.title && <strong>{item?.title} </strong>}
                                        {item?.paragraph && <span>{item?.paragraph} <Link to={item?.link}>{ item?.link}</Link>.</span>}
                                        {item?.listData.length > 0 && item?.listData.map((list, index) => <p key={index}><strong>{list?.title} </strong>{list?.text}</p>)}
                                    </div>)}
                                </div>
                                <div className="footer-content-my">
                                    <h1>{PrivacyPolicyPageData?.aboutText?.footerTitle}</h1>
                                </div>
                            </Container>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

PrivacyPolicyScreen.propTypes = {
    classes: PropTypes.object
};

export default PrivacyPolicyScreen;
