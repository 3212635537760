import AboutScreen from "../views/About"
import CaseStudyScreen from "../views/CaseStudy"
import ContactScreen from "../views/Contact"
// import Components from "../views/components/components"
// import CustomComponents from "../views/custom-components/custom-components"
import HomeScreen from "../views/Home"
import ProjectScreen from "../views/Project"
import ServicesScreen from "../views/Services"
import Fre8ProApplicationDetail from "../views/Fre8ProApplicationDetail"
import JobPortalDetail from "../views/JobPortalDetail"
import ViewBlogScreen from "../views/view-all-blog"
import ViewBlogDetailScreen from "../views/view-all-blog/blogDetails"
import IndustriesScreen from "../views/Industries"
import TechnologyScreen from "../views/Technology"
import FaqPageScreen from "../views/FaqPage"
import OurTeamScreen from "../views/OurTeam"
import CareersScreen from "../views/Careers"
import PrivacyPolicyScreen from "../views/PrivacyPolicy"
import TermsOfUseScreen from "../views/TermsOfUse"
import userImgDaljit from '../assets/images/team/Daljit.jpg';
import userImgFRE8 from '../assets/images/team/NewProject.jpg';
import userImgNoImg from '../assets/images/team/NoImg.jpg';
// import userImgFRE8 from '../assets/images/team/FRE8-Logo-13.svg';
import userImg1 from '../assets/images/blog/blog-home/b-01.webp';
import Fre8ProImg from '../assets/images/landingpage/Fre8-Pro-img.webp';
import bodyImg1 from '../assets/images/blog/blog-home/blog_detail_1.webp';
import SocialNetworkingAppImg from '../assets/images/landingpage/Social-Networking-App.webp';
import RestaurantSoftwareImg from '../assets/images/landingpage/Restaurant-Software.webp';
import EventManagementImg from '../assets/images/landingpage/Event-Management.webp';
import HospitalityApplication from '../assets/images/landingpage/HospitalityApplication.webp';
import FinancialRisk from '../assets/images/landingpage/bg-ClientCount.webp';
import SoftwareTeamHuntBg from '../assets/images/landingpage/bg-ClientCount-prime.webp';
import LmsImg from '../assets/images/landingpage/lms.webp';
import OurTeamImg from '../assets/images/aboutUs/Whyus.webp';
import JobPortalImg from '../assets/images/landingpage/Job-Portal.webp'
import bannerImg from '../assets/images/project/project-banner.webp'
import Fre8ProApplicationBannerImg from '../assets/images/project/fre8Pro-application-banner.webp'
import JobPortalBannerImg from '../assets/images/project/job-portal-banner.webp'
import techIndustry from '../assets/images/landingpage/smiling-executives-waiting-meeting.webp'
import techIndustry2 from '../assets/images/landingpage/group-positive-young-people-posing-together.webp'
import yourIdeaImg from '../assets/images/landingpage/bg-ClientCount.webp'
import WebServerImg from '../assets/images/Technologies/WebServer.webp'
import CloudImg from '../assets/images/Technologies/Cloud.webp'
import DevOpsImg from '../assets/images/Technologies/DevOps.webp'
import UXImg from '../assets/images/Technologies/UX.webp'
import ArchitectureImg from '../assets/images/Technologies/Architecture.webp'
import DistributedTechnologiesImg from '../assets/images/Technologies/DistributedTechnologies.webp'
import DatabaseImg from '../assets/images/Technologies/Database.webp'
import serviceBanner from '../assets/images/service/service-banner.webp'
import DishaJaniImg from '../assets/images/team/Disha.webp'
import PadmanabanImg from '../assets/images/team/Padmanabh.webp'
import MihirParekhImg from '../assets/images/team/Mihir.webp'
import G1 from '../assets/images/team/g1.webp'
import G2 from '../assets/images/team/g2.webp'
import G3 from '../assets/images/team/g3.webp'
import G4 from '../assets/images/team/g4.webp'
import G5 from '../assets/images/team/g5.webp'
import G6 from '../assets/images/team/g6.webp'
import backgroundVideo from '../assets/images/landingpage/Hero-Banner.mp4'
import myImg from '../assets/images/landingpage/bg-Image.webp'
import PerksAndBenefitImg from '../assets/images/Career/Short-banner.webp'
import { AccountingIcon, ArrowIcon, DataFileBarsStarIcon, DesktopSystemIcon, DoableArrowSignIcon, ECommerceCartIcon, ECommerceIcon, EmailIcon, EmergingTechnologies, EmergingTechnologiesIcon, FacebookIcon, FacebookIconOne, FinanceIcon, GovernmentIcon, HealthIcon, HotelAndRestaurantsIcon, HRIcon, InstagramIcon, InsuranceIcon, JewelryIcon, LaptopUploadIcon, LinkedinIcon, LMSIcon, LocationIcon, LogisticsIcon, ManufacturingIcon, MobileAppDevelopment, MobileIcon, NightClubDiscoBallIcon, PharmaIcon, PhoneIcon, PhoneMonitorExchangeIcon, ProductEngineeringIcon, QualityAssurance, RightClick, TargetCenterIcon, TargetCorrectIcon, ThumbLikeStarIcon, TravelingAndTourismIcon, UIAndUXDesign, UsbPortIcon, UserNetworkIcon, WebApplications, WebSearchIcon } from "./icon"
// import { lazy } from "react"
// import Loadable from "../components/Loadable"

// const HomeScreen = Loadable(
//     lazy(() => import('../views/Home'))
// )
// const AboutScreen = Loadable(
//     lazy(() => import('../views/About'))
// )
// const CaseStudyScreen = Loadable(
//     lazy(() => import('../views/CaseStudy'))
// )
// const ContactScreen = Loadable(
//     lazy(() => import('../views/Contact'))
// )
// const ProjectScreen = Loadable(
//     lazy(() => import('../views/Project'))
// )
// const ServicesScreen = Loadable(
//     lazy(() => import('../views/Services'))
// )
// const TermsOfUseScreen = Loadable(
//     lazy(() => import('../views/TermsOfUse'))
// )
// const PrivacyPolicyScreen = Loadable(
//     lazy(() => import('../views/PrivacyPolicy'))
// )
// const CareersScreen = Loadable(
//     lazy(() => import('../views/Careers'))
// )
// const OurTeamScreen = Loadable(
//     lazy(() => import('../views/OurTeam'))
// )
// const FaqPageScreen = Loadable(
//     lazy(() => import('../views/FaqPage'))
// )
// const TechnologyScreen = Loadable(
//     lazy(() => import('../views/Technology'))
// )
// const IndustriesScreen = Loadable(
//     lazy(() => import('../views/Industries'))
// )
// const ViewBlogScreen = Loadable(
//     lazy(() => import('../views/view-all-blog'))
// )
// const JobPortalDetail = Loadable(
//     lazy(() => import('../views/JobPortalDetail'))
// )
// const Fre8ProApplicationDetail = Loadable(
//     lazy(() => import('../views/Fre8ProApplicationDetail'))
// )
// const ViewBlogDetailScreen = Loadable(
//     lazy(() => import('../views/view-all-blog/blogDetails'))
// )

export const routesData = [
    {
        id: 1,
        path: '/',
        element: <HomeScreen title={'Home'} />,
        label: 'Home',
    },
    {
        id: 2,
        path: '/about',
        element: <AboutScreen title={'About'} />,
        label: 'About',
    },
    {
        id: 3,
        path: '/services',
        element: <ServicesScreen title={'Services'} />,
        label: 'Services',
    },
    {
        id: 4,
        path: '/project',
        element: <ProjectScreen title={'Project'} />,
        label: 'Project',
    },
    {
        id: 5,
        path: '/case-study',
        element: <CaseStudyScreen title={'Case Study'} />,
        label: 'Case Study',
    },
    {
        id: 6,
        path: '/case-study/fre8Pro-application',
        element: <Fre8ProApplicationDetail title={'Case Study'} />,
        label: 'Case Study',
        hidden: true,
    },
    {
        id: 7,
        path: '/case-study/job-portal',
        element: <JobPortalDetail title={'Case Study'} />,
        label: 'Case Study',
        hidden: true,
    },
    {
        id: 8,
        path: '/contact',
        element: <ContactScreen title={'Contact'} />,
        label: 'Contact',
    },
    // {
    //     id: 9,
    //     key: '/components',
    //     element: <Components title={'Components'} />,
    //     label: 'Components',
    // },
    // {
    //     id: 10,
    //     key: '/custom-components',
    //     element: <CustomComponents title={'Custom-Components'} />,
    //     label: 'Custom-Components',
    // },
    {
        id: 11,
        path: '/view-all-blog',
        element: <ViewBlogScreen title={'view-all-blog'} />,
        label: 'View all blog',
        hidden: true,
    },
    {
        id: 12,
        path: '/blog-detail',
        element: <ViewBlogDetailScreen title={'Blog Detail'} />,
        label: 'blog detail',
        hidden: true,
    },
    {
        id: 13,
        path: '/industries',
        element: <IndustriesScreen title={'Industries'} />,
        label: 'Industries',
        hidden: true,
    },
    {
        id: 14,
        path: '/technology',
        element: <TechnologyScreen title={'Technology'} />,
        label: 'Technology',
        hidden: true,
    },
    {
        id: 15,
        path: '/faq',
        element: <FaqPageScreen title={'FAQ'} />,
        label: 'FAQ',
        hidden: true,
    },
    {
        id: 16,
        path: '/our-team',
        element: <OurTeamScreen title={'Our Team'} />,
        label: 'Our Team',
        hidden: true,
    },
    {
        id: 17,
        path: '/careers',
        element: <CareersScreen title={'Careers'} />,
        label: 'Careers',
        hidden: true,
    },
    {
        id: 18,
        path: '/privacy-policy',
        element: <PrivacyPolicyScreen title={'Privacy Policy'} />,
        label: 'Privacy Policy',
        hidden: true,
    },
    {
        id: 19,
        path: '/terms-of-use',
        element: <TermsOfUseScreen title={'Terms of use'} />,
        label: 'Terms of use',
        hidden: true,
    },
];
export const items = [
    {
        data: [
            {
                icon: <FacebookIcon />,
                text: 'Social Media',
                paragraph: 'Apps that connect with your loved ones the right way'
            },
            {
                icon: <ECommerceIcon />,
                text: 'E-commerce',
                paragraph: 'Connecting sellers and buyers and making sales'
            },
            {
                icon: <TravelingAndTourismIcon />,
                text: 'Traveling and Tourism',
                paragraph: 'Apps that are gateway to the world'
            },
            {
                icon: <HotelAndRestaurantsIcon />,
                text: 'Hotel and Restaurants',
                paragraph: 'Apps that work for your business to grow'
            },
        ]
    },
    {
        data: [
            {
                icon: <JewelryIcon />,
                text: 'Jewellery - Retail',
                paragraph: 'The apps that shine your business'
            },
            {
                icon: <ManufacturingIcon />,
                text: 'Manufacturing ',
                paragraph: 'Apps that streamline the process and automate'
            },
            {
                icon: <AccountingIcon />,
                text: 'Accounting',
                paragraph: 'Apps that give you the real picture'
            },
            {
                icon: <FinanceIcon />,
                text: 'Finance ',
                paragraph: 'Apps that give you more clarity'
            },
        ]
    },
    {
        data: [
            {
                icon: <InsuranceIcon />,
                text: 'Insurance',
                paragraph: 'Apps that delivers more protection'
            },
            {
                icon: <LMSIcon />,
                text: 'LMS',
                paragraph: 'Apps that makes learning easy'
            },
            {
                icon: <NightClubDiscoBallIcon />,
                text: 'Events',
                paragraph: 'Apps that conduct sans stress'
            },
            {
                icon: <LogisticsIcon />,
                text: 'Logistics and Transport',
                paragraph: 'Apps that connect the logistics industry'
            },
        ]
    },
    {
        data: [
            {
                icon: <HealthIcon />,
                text: 'Health Care',
                paragraph: 'Apps that are top notch'
            },
            {
                icon: <PharmaIcon />,
                text: 'Hospital and Pharma',
                paragraph: 'Apps that make lives easier'
            },
            {
                icon: <HRIcon />,
                text: 'Human Resources',
                paragraph: 'Apps that are people driven'
            },
            {
                icon: <GovernmentIcon />,
                text: 'Government',
                paragraph: 'Apps that are top notch'
            },
        ]
    }
];

// Home page data 
export const homePageData = {
    bannerImg: backgroundVideo,
    headerData: {
        title: 'Build',
        subTitle: 'scalable and secure',
        subTitle2: 'software with VarahiSoft that is future-proof',
        text: `When partnering with VarahiSoft, you can expect to achieve a flawless design, a well-structured and adaptable code, aesthetically captivating user interface, immersive user experience, and a refined, error-free application.`,
        btnName: 'Get in Touch',
        link: '/contact',
        btnIcon: <ArrowIcon />
    },
    weAreVarahiSoftData: {
        title: `We are VarahiSoft`,
        text: `The collaboration with VarahiSoft culminates in the delivery of well-designed applications, future-proof code, visually captivating user interfaces, seamless user experiences, and bug-free applications.`,
        alignedWithYourBusiness: {
            title: `A product that is aligned with your business`,
            bgImg: myImg,
            text: `At VarahiSoft, we believe in exclusivity. We're not about quantity, but about crafting a unique, exceptional product just for you. Our dedicated team focuses on your project, ensuring it's not lost in a sea of others.`,
        },
        technologies: [
            {
                title: 'Product Engineering',
                text: 'VarahiSoft meticulously crafts and perfects a single exceptional product for you.',
                icon: <ProductEngineeringIcon />
            },
            {
                title: 'UI and UX Design',
                text: 'For all the future apps we have the technology stack ready now.',
                icon: <UIAndUXDesign />
            },
            {
                title: 'Mobile app development',
                text: 'Mobile apps that offer unparalleled UI/UX for diverse global audiences.',
                icon: <MobileAppDevelopment />
            },
            {
                title: 'Web applications',
                text: 'Applications that are robust, optimized and designed for web and mobile.',
                icon: <WebApplications />
            },
            {
                title: 'Quality Assurance',
                text: 'Incorporate testing into your SDLC to boost efficiency and cut costs.',
                icon: <QualityAssurance />
            },
            {
                title: 'Emerging Technologies',
                text: 'Transform your business for the future with our cutting-edge technology team.',
                icon: <EmergingTechnologies />

            },
        ],
    },
    industriesWeSpecialize: {
        title: `Industries we specialize`,
        text: `We have achieved outstanding results in numerous domains and verticals`,
    },
    recentProjects: {
        title: `Recent Projects - Ongoing Initiatives`,
        text: `We are a bespoke software development company focussed on crafting on high quality over quantity of projects that we work on`,
        projects: [
            {
                id: 1,
                title: `Fre8 Pro`,
                projectImg: Fre8ProImg,
                text: 'The application serves as a unifying platform for the entire freight industry, offering a wide array of features including social media, a comprehensive job portal, event management tools, and additional services such as couch services and LMS. The list of features is extensive, catering to the diverse needs of the industry.'
            },
            {
                id: 2,
                title: `Event Management`,
                projectImg: EventManagementImg,
                text: `The application incorporates all the essential features required by event organizers, ranging from event creation and social media sharing to traffic source tracking and review management. Emphasizing usability and user experience, it is designed to prioritize users' needs.`
            },
            {
                id: 3,
                title: `Restaurant Software`,
                projectImg: RestaurantSoftwareImg,
                text: `The application encompasses a comprehensive array of features that cater to the diverse operational elements within the restaurant industry. Features includes pre-planning, production indent, costing, table service, and door deliveries. Moreover, the application includes both frontend and backend functions, as well as provisions for human resources and vendor management.`
            },
            {
                id: 4,
                title: `TSN (Social Networking App)`,
                projectImg: SocialNetworkingAppImg,
                text: `The application encompasses all the contemporary features found in leading social media platforms. It boasts a robust set of social media functionalities and a streamlined interface and delivers an exceptional user experience.`
            },
        ]
    },
    yourIdea: {
        bgImg: yourIdeaImg,
        // bgImg: SoftwareTeamHuntBg,
        title: 'Your',
        subtitle1: 'Idea',
        subtitle2: 'is more valued at VarahiSoft'
    }
}

// Footer Page Data ======
export const footerPageData = {
    companyName: 'Varahisoft',
    title: `Want to Start a Project?`,
    rightContent: `Build your dream software is with VarahiSoft`,
    btnText: `Let’s Talk`,
    copyRightText: `© 2024 Varahisoft. All rights reserved`,
    bottomFirstBarText: `VarahiSoft is brand and Amarvelly is a legal entity. Amarvelly Soft Tech Bharat Private Limited - U62011GJ2023PTC140997`,
    btnIcon: <ArrowIcon />,
    listData: [
        {
            id: 1,
            title: `Contact us`,
            navigateList: [],
            ourServices: [],
            ourPolicies: [],
            contactUsList: [
                {
                    icon: <LocationIcon />,
                    subTitle: `Vadodara, Head Office`,
                    link: '',
                    text: `203, 2nd Floor, Capri House - 2, Near Chakla Circle, Jetal pur road,Alkapuri Vadodara - 390007, Gujarat`
                },
                {
                    icon: <PhoneIcon />,
                    subTitle: ``,
                    link: '',
                    text: `0265 296 6000`
                },
                {
                    icon: <EmailIcon />,
                    subTitle: ``,
                    link: '',
                    text: `sales@varahisoft.com`
                },

            ]
        },
        {
            id: 2,
            title: `Navigate`,
            contactUsList: [],
            ourServices: [],
            ourPolicies: [],
            navigateList: [
                {
                    icon: '',
                    subTitle: ``,
                    link: '/',
                    text: `Home`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/our-team',
                    text: `Our team`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/about',
                    text: `About`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/industries',
                    text: `Industries`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/services',
                    text: `Services`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/technology',
                    text: `Technologies`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/project',
                    text: `Project`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/view-all-blog',
                    text: `Blogs`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/case-study',
                    text: `Case Study`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/faq',
                    text: `FAQ`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/contact',
                    text: `Contact`
                },

            ]
        },
        {
            id: 3,
            title: `Our Services`,
            contactUsList: [],
            navigateList: [],
            ourPolicies: [],
            ourServices: [
                {
                    icon: '',
                    subTitle: ``,
                    link: '/services',
                    text: `Product Engineering`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/services',
                    text: `UI and UX Design`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/services',
                    text: `Mobile App Development`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/services',
                    text: `Web App Development`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/services',
                    text: `E-commerce Applications`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/services',
                    text: `Quality Assurance`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/services',
                    text: `Emerging Technologies`
                },

            ]
        },
        {
            id: 4,
            title: `Our Policies`,
            contactUsList: [],
            navigateList: [],
            ourServices: [],
            ourPolicies: [
                {
                    icon: '',
                    subTitle: ``,
                    link: '/privacy-policy',
                    text: `Privacy Policies`
                },
                {
                    icon: '',
                    subTitle: ``,
                    link: '/terms-of-use',
                    text: `Terms of use`
                }
            ]
        },
    ],
    followUs: {
        title: `Follow us`,
        icons: [
            {
                id: 1,
                icon: <LinkedinIcon />,
                link: '#'
            },
            {
                id: 2,
                icon: <InstagramIcon />,
                link: '#'
            },
            {
                id: 3,
                icon: <FacebookIconOne />,
                link: '#'
            },
        ]
    }
}
export const techIndustryQualityData =
{
    bgImg: techIndustry,
    pageHeading: '18+ years of valuable experience in the Tech Industry',
    pageParagraph: 'Quality is our top priority, driving our successful project completions. We deliver products that exceed expectations, align with your business goals, and meet unique requirements.',
    faqData: [
        {
            id: 1,
            title: 'Client Focussed',
            paragraph: 'We transform your ideas into sustainable, scalable, and visually stunning software solutions. We focus on creating meaningful user experiences, believing that success lies in exceptional design and functionality.'
        },
        {
            id: 2,
            title: 'Partner with Us',
            paragraph: 'We excel in transforming concepts into robust, user-friendly software with seamless functionality and captivating designs. Our focus is on delivering feature-rich software that brings joy and exceptional user experiences.'
        },
        {
            id: 3,
            title: 'User Experience',
            paragraph: 'We transform concepts into robust, user-friendly software with seamless functionality and captivating designs. Our focus is on delivering feature-rich software that brings joy and exceptional user experiences.'
        },
    ]
};
export const customerReviewsData =
{
    pageHeading: 'Our customers are absolutely amazing',
    pageParagraph: 'Know about us from our client words',
    reviews: [
        {
            id: 1,
            name: 'Daljit Gill',
            designation: 'CTO, FRE8',
            userImg: userImgDaljit,
            paragraph: `“This team can convert any rough requirement in to great quality product in short time with future scalability in mind in advance with awesome application security.”`
        },
        {
            id: 2,
            name: 'Vikram Dua',
            designation: 'CEO, FRE8',
            userImg: userImgFRE8,
            paragraph: `“Very Happy to see my complex brain child in to great real product in no time in peanuts spending.”`
        },
        {
            id: 3,
            name: 'Yvette Taylor',
            designation: 'CEO, TTSN LLP.',
            userImg: userImgNoImg,
            paragraph: `“It was an is great pleasure working  with Varahi team seeing my big Dream  coming true. Always work to love with this team.”`
        },
        {
            id: 4,
            name: 'Daljit Gill',
            designation: 'CEO, AikSphere',
            userImg: userImgDaljit,
            paragraph: `“Great Proactive team , would like to work with them again and again.”`
        },

    ]
};
export const ourLatestBlogsData =
{
    pageHeading: 'Our latest blogs',
    pageParagraph: 'Read about software industry trends and best practices',
    buttonName: 'View All',
    blogs: [
        {
            id: 1,
            name: 'By Vasu Padmanaban',
            createdDate: 'THU JUL 25 2024',
            userImg: userImg1,
            paragraph: `Solution Architecture in building software products.`
        }

    ]
}
export const OurServicesAtAGlanceData =
{
    pageHeading: 'Our Services at a Glance',
    pageParagraph: '',
    glance: [
        {
            id: 1,
            title: 'UI and UX Design',
            userImg: <DesktopSystemIcon />,
            text: `For all the future apps we have stunning interfaces ready now.`
        },
        {
            id: 2,
            title: 'Mobile App Development',
            userImg: <MobileIcon />,
            text: `Mobile apps that offer unparalleled UI/UX for diverse global audiences.`
        },
        {
            id: 3,
            title: 'Web App Development',
            userImg: <WebSearchIcon />,
            text: `Applications that are robust, optimized and designed for web and mobile.`
        },
        {
            id: 4,
            title: 'E-Commerce Applications',
            userImg: <ECommerceCartIcon />,
            text: `Transform your business for the future with our cutting-edge technology team.`
        },
        {
            id: 5,
            title: 'Quality Assurance',
            userImg: <ThumbLikeStarIcon />,
            text: `Incorporate testing strategies into your SDLC for performance`
        },
        {
            id: 6,
            title: 'Emerging Technologies',
            userImg: <EmergingTechnologiesIcon />,
            text: `Transform your business for the future with our cutting-edge technology team.`
        },
    ]
}
export const bestReasonsData =
{
    pageHeading: 'Best Reasons Why VarahiSoft is your ideal technology partner',
    pageParagraph: '',
    reasons: [
        {
            id: 1,
            title: 'Solution Architecture',
            userImg: <TargetCorrectIcon />,
            text: `VarahiSoft's unique approach to solution architecture sets us apart. It's the key to turning your idea into a technical vision that delivers an excellent return on investment.`
        },
        {
            id: 2,
            title: 'Robust delivery',
            userImg: <UsbPortIcon />,
            text: `Thanks to the agile framework at VarahiSoft, we deliver robustly.`
        },
        {
            id: 3,
            title: 'Foundations based on scalability ',
            userImg: <LaptopUploadIcon />,
            text: `Every code we write allows you to scale up in the ever-changing business needs and increased workloads. `
        },
        {
            id: 4,
            title: 'Efficient performance',
            userImg: <PhoneMonitorExchangeIcon />,
            text: `Our products are designed to perform well in real-world situations, with a strong focus on continuous integration and profitability, ensuring a solid return on your investment. `
        },
        {
            id: 5,
            title: 'Approach',
            userImg: <UserNetworkIcon />,
            text: `We cover the entire product lifecycle, from documentation to addressing critical issues. Our expertise lies in handling data corruption, loss, and disruptions. We are skilled protocol experts equipped with the tools to test protocol compliance.`
        },
        {
            id: 6,
            title: 'Quality Assurance',
            userImg: <DataFileBarsStarIcon />,
            text: `We utilize testing and analysis tools, as well as industry-specific tools. We complement internal testing with external validation, resulting in a high-quality product.`
        },
    ]
}
export const projectsDetailsData =
{
    bannerImg: bannerImg,
    headerData: {
        title: 'Our Ongoing/Recent Projects',
        subTitle: 'We are a bespoke software development company focused on crafting high quality products robustly.'
    },
    pageHeading: '',
    pageParagraph: '',
    projects: [
        {
            id: 1,
            title: 'Fre8 Pro ',
            userImg: Fre8ProImg,
            text: `The application serves as a unifying platform for the entire freight industry, offering a wide array of features including social media, a comprehensive job portal, event management tools, and additional services such as couch services and LMS. The list of features is extensive, catering to the diverse needs of the industry. `
        },
        {
            id: 2,
            title: 'TSN (Social Networking App) ',
            userImg: SocialNetworkingAppImg,
            text: `The application includes all the modern features found on top social media platforms. It has a robust set of social media functionalities and a streamlined interface and delivers an exceptional user experience.`
        },
        {
            id: 3,
            title: 'Restaurant Software',
            userImg: RestaurantSoftwareImg,
            text: `The application encompasses a comprehensive array of features that cater to the diverse operational elements within the restaurant industry. These encompass pre-planning, production indent, costing, table service, and door deliveries. Moreover, the application includes both frontend and backend functions, as well as provisions for human resources and vendor management.`
        },
        {
            id: 4,
            title: 'Event Management',
            userImg: EventManagementImg,
            text: `The application incorporates all the essential features required by event organizers, ranging from event creation and social media sharing to traffic source tracking and review management. Emphasizing usability and user experience, it is designed to prioritize users' needs.`
        },
        {
            id: 5,
            title: 'Hospitality Application ',
            userImg: HospitalityApplication,
            text: `The application offers comprehensive coverage of room availability across various time zones and locations, accompanied by detailed imagery and descriptions of hospitality facilities. It facilitates guest bookings, payment processing, refund inquiries for cancellations, and the ability to provide reviews of the hospitality establishments. This application is fully equipped to cater to the diverse needs of the hospitality industry.`
        },
        {
            id: 6,
            title: 'LMS',
            userImg: LmsImg,
            text: `Education has transitioned to the online realm, mirroring the flexible nature of remote work. Our platform is designed to empower online educators to create and distribute courses, while students can conveniently access purchased or free courses, and engage in collaborative learning by providing feedback to their peers.`
        },
    ],
    FrequentlyAskedQuestionsData: {
        title: 'Frequently asked questions',
        subTitle: 'Your queries will be addressed here. If not, please reach out to us',
        buttonName: '',
        questions: [
            {
                question: 'Why should I opt for custom software development instead of off-the-shelf solutions?',
                answer: `Off-the-shelf solutions only provide generic features and do not cater to your business's unique needs. Custom software is the way to go if you want to address all your business challenges with software.`
            },
            {
                question: 'What is VarahiSoft’s custom software development?',
                answer: `VarahiSoft creates scalable, high-performing applications with a seamless user experience that helps your business achieve maximum value for your business.`
            },
        ]
    }
}
export const BusinessModelsData =
{
    pageHeading: 'Business Models',
    pageParagraph: 'We customize business models to your needs, ensuring excellent results. As a high-tech startup, we offer customized options with transparent effort, time, and cost estimates.',
    models: [
        {
            id: 1,
            title: 'Fixed Model',
            text: `Software development is meticulous and resource-intensive. We focus on identifying requirements, creating prototypes, setting budgets, allocating resources, and adhering to timelines while mitigating project risks effectively.`
        },
        {
            id: 2,
            title: 'Agile Model',
            text: `Agile development allows flexibility to adapt to changing business goals and ideas. Our agile experts and scrum team promptly incorporate feasible changes, ensuring custom software that meets your preferences while managing time and budget.`
        },
        {
            id: 3,
            title: 'Time and Material Model',
            text: `The time and material model suits large-scale projects with variable scope, functionalities, and budget. It allows prompt resource allocation based on evolving software development requirements.`
        },
    ]
}
export const CaseStudyData =
{
    bannerImg: bannerImg,
    headerData: {
        title: 'Case Study',
        subTitle: 'We are a bespoke software development company focussed on crafting on high quality over quantity of projects that we work'
    },
    models: [
        {
            id: 1,
            title: 'Case Studies',
            subTitle: 'Fre8Pro Application',
            img: Fre8ProImg,
            link: '/case-study/fre8Pro-application',
            text: `We are on a journey to create a revolutionary product from scratch. With our innovative approach, this project aims to meet the needs of millions of users and redefine the freight industry landscape.`
        },
        {
            id: 2,
            title: 'Job Portal',
            subTitle: 'Job Portal',
            img: JobPortalImg,
            link: '/case-study/job-portal',
            text: `We created a job portal that has a minimalistic design and feature-rich functionalities for the recruiter and the applicant.`
        },
    ],
    FrequentlyAskedQuestionsData: {
        title: 'Frequently asked questions',
        subTitle: 'Your queries will be addressed here. If not, please reach out to us',
        buttonName: '',
        questions: [
            {
                question: 'Why should I opt for custom software development instead of off-the-shelf solutions?',
                answer: `Off-the-shelf solutions only provide generic features and do not cater to your business's unique needs. Custom software is the way to go if you want to address all your business challenges with software.`
            },
            {
                question: 'What is VarahiSoft’s custom software development?',
                answer: `VarahiSoft creates scalable, high-performing applications with a seamless user experience that helps your business achieve maximum value for your business.`
            },
        ]
    }
}
export const Fre8ProApplicationData =
{
    bannerImg: Fre8ProApplicationBannerImg,
    headerData: {
        title: 'Fre8Pro Application',
        subTitle: 'Freight Industry',
        text: ''
    },
    models: [
        {
            id: 1,
            title: 'Freight Industry',
            subTitle: 'Fre8Pro Application',
            img: Fre8ProImg,
            teamSize: 14,
            projectDuration: '2 years',
            link: '',
            text: `We are on a journey to create a revolutionary product from scratch. With our innovative approach, this project aims to meet the needs of millions of users and redefine the freight industry landscape.`
        }
    ],
    keyRoles: {
        title: 'Key roles',
        description: 'Business Analyst, Senior Solution Architect, Backend Developer - Lead, Front End Developer - Lead, UI/UX designer - Lead'
    },
    technologyUsed: {
        title: 'Technology used',
        technologies: [
            {
                id: 1,
                name: 'Java 8+'
            },
            {
                id: 2,
                name: 'Spring'
            },
            {
                id: 3,
                name: 'Spring Boot'
            },
            {
                id: 4,
                name: 'Postgres'
            },
            {
                id: 5,
                name: 'Rabbit MQ'
            },
            {
                id: 6,
                name: 'EhCache'
            },
            {
                id: 7,
                name: 'Elastic Search'
            },
            {
                id: 8,
                name: 'React JS'
            },
            {
                id: 9,
                name: 'HTML5'
            },
            {
                id: 10,
                name: ' CSS3'
            },
            {
                id: 11,
                name: 'JavaScript'
            },
            {
                id: 12,
                name: 'Apache Tomcat Architecture'
            },
            {
                id: 13,
                name: 'OAuth2'
            },
            {
                id: 14,
                name: 'Microservices with Event Driven AWS'
            },
        ]
    },
    problemStatement: {
        title: 'Problem statement',
        statements: [
            {
                id: 1,
                name: 'Client wanted to revolutionize logistics and transportation industries and unite the freight industry under one platform'
            },
            {
                id: 2,
                name: 'The client had a very long term vision for the product'
            },
            {
                id: 3,
                name: 'The ground level requirements were vague and one liners and not consistent'
            },
            {
                id: 4,
                name: 'The client had already made failed time, money and investment with many companies and had lost hope and was very scared to try a start up company like VarahiSoft'
            },
        ]
    },
    challengesFaced: {
        title: 'Challenges faced',
        challenges: [
            {
                id: 1,
                name: 'The client wanted many features and the design to be minimalistic'
            },
            {
                id: 2,
                name: 'The requirements comprised were from different existing job portals and job sites'
            },
            {
                id: 3,
                name: 'The entire application was built from scratch focussing on rich user experience and functionally rich'
            },
            {
                id: 4,
                name: 'The focus was to optimize the performance of the application'
            },
        ]
    },
    solution: {
        title: 'Solution',
        solutions: [
            {
                id: 1,
                name: 'Delivered an application that is robust, secure, scalable and futuristic'
            },
            {
                id: 2,
                name: 'Focussed on requirement engineering, reliable and scalable architecture with clean and stunning user interface and user experience'
            },
            {
                id: 3,
                name: 'Chose the best technology stack for each service , followed principle of coding and delivered clean and maintainable code'
            },
            {
                id: 4,
                name: 'Delivered pluggable and extendable architecture micro service, event driven and monolithic'
            },
            {
                id: 5,
                name: 'Priority smart sprint planning that were aligned to business goals'
            },
        ]
    },
    softwareTeamHunt: {
        bgImg: SoftwareTeamHuntBg,
        title: 'Put an end to',
        subTitle1: 'your software team hunt',
        subTitle2: ''
    },
    FrequentlyAskedQuestionsData: {
        title: 'Frequently asked questions',
        subTitle: 'Your queries will be addressed here. If not, please reach out to us',
        buttonName: '',
        questions: [
            {
                question: 'Why should I opt for custom software development instead of off-the-shelf solutions?',
                answer: `Off-the-shelf solutions only provide generic features and do not cater to your business's unique needs. Custom software is the way to go if you want to address all your business challenges with software.`
            },
            {
                question: 'What is VarahiSoft’s custom software development?',
                answer: `VarahiSoft creates scalable, high-performing applications with a seamless user experience that helps your business achieve maximum value for your business.`
            },
        ]
    }
}
export const JobPortalData =
{
    bannerImg: JobPortalBannerImg,
    headerData: {
        title: 'Job Portal',
        subTitle: 'Job Portal',
        text: ''
    },
    models: [
        {
            id: 1,
            title: 'Job Portal',
            subTitle: 'Fre8Pro Application',
            img: JobPortalImg,
            teamSize: 10,
            projectDuration: '6 months',
            link: '',
            text: `We created a job portal that has a minimalistic design and feature-rich functionalities for the recruiter and the applicant.`
        }
    ],
    keyRoles: {
        title: 'Key roles',
        description: 'Business Analyst, Senior Solution Architect, Backend Developer - Lead, Front End Developer - Lead, UI/UX designer - Lead'
    },
    technologyUsed: {
        title: 'Technology used',
        technologies: [
            {
                id: 1,
                name: 'Java 8+'
            },
            {
                id: 2,
                name: 'Spring'
            },
            {
                id: 3,
                name: 'Spring Boot'
            },
            {
                id: 4,
                name: 'Postgres'
            },
            {
                id: 5,
                name: 'Rabbit MQ'
            },
            {
                id: 6,
                name: 'EhCache'
            },
            {
                id: 7,
                name: 'Elastic Search'
            },
            {
                id: 8,
                name: 'React JS'
            },
            {
                id: 9,
                name: 'HTML5'
            },
            {
                id: 10,
                name: ' CSS3'
            },
            {
                id: 11,
                name: 'JavaScript'
            },
            {
                id: 12,
                name: 'Apache Tomcat Architecture'
            },
            {
                id: 13,
                name: 'OAuth2'
            },
            {
                id: 14,
                name: 'Microservices with Event Driven AWS'
            },
        ]
    },
    problemStatement: {
        title: 'Problem statement',
        statements: [
            {
                id: 1,
                name: 'Client wanted to revolutionize logistics and transportation industries and unite the freight industry under one platform'
            },
            {
                id: 2,
                name: 'The client had a very long term vision for the product'
            },
            {
                id: 3,
                name: 'The ground level requirements were vague and one liners and not consistent'
            },
            {
                id: 4,
                name: 'The client had already made failed time, money and investment with many companies and had lost hope and was very scared to try a start up company like VarahiSoft'
            },
        ]
    },
    challengesFaced: {
        title: 'Challenges faced',
        challenges: [
            {
                id: 1,
                name: 'The client wanted many features and the design to be minimalistic'
            },
            {
                id: 2,
                name: 'The requirements comprised were from different existing job portals and job sites'
            },
            {
                id: 3,
                name: 'The entire application was built from scratch focussing on rich user experience and functionally rich'
            },
            {
                id: 4,
                name: 'The focus was to optimize the performance of the application'
            },
        ]
    },
    solution: {
        title: 'Solution',
        solutions: [
            {
                id: 1,
                name: 'Delivered an application that is robust, secure, scalable and futuristic'
            },
            {
                id: 2,
                name: 'Focussed on requirement engineering, reliable and scalable architecture with clean and stunning user interface and user experience'
            },
            {
                id: 3,
                name: 'Chose the best technology stack for each service , followed principle of coding and delivered clean and maintainable code'
            },
            {
                id: 4,
                name: 'Delivered pluggable and extendable architecture micro service, event driven and monolithic'
            },
            {
                id: 5,
                name: 'Priority smart sprint planning that were aligned to business goals'
            },
        ]
    },
    softwareTeamHunt: {
        bgImg: SoftwareTeamHuntBg,
        title: 'Put an end to the guess work of',
        subTitle1: 'software building',
        subTitle2: ''
    },
    FrequentlyAskedQuestionsData: {
        title: 'Frequently asked questions',
        subTitle: 'Your queries will be addressed here. If not, please reach out to us',
        buttonName: '',
        questions: [
            {
                question: 'Why should I opt for custom software development instead of off-the-shelf solutions?',
                answer: `Off-the-shelf solutions only provide generic features and do not cater to your business's unique needs. Custom software is the way to go if you want to address all your business challenges with software.`
            },
            {
                question: 'What is VarahiSoft’s custom software development?',
                answer: `VarahiSoft creates scalable, high-performing applications with a seamless user experience that helps your business achieve maximum value for your business.`
            },
        ]
    }
}
export const FrequentlyAskedQuestionsData = {
    title: 'Frequently asked questions',
    subTitle: 'Your queries will be addressed here. If not, please reach out to us',
    buttonName: 'View All',
    questions: [
        {
            question: 'Why should I opt for custom software development instead of off-the-shelf solutions?',
            answer: `Off-the-shelf solutions only provide generic features and do not cater to your business's unique needs. Custom software is the way to go if you want to address all your business challenges with software.`
        },
        {
            question: 'What is VarahiSoft’s custom software development?',
            answer: `VarahiSoft creates scalable, high-performing applications with a seamless user experience that helps your business achieve maximum value for your business.`
        },
    ]
}
export const ContactPageData =
{
    bannerImg: bannerImg,
    headerData: {
        title: 'Contact us',
        subTitle: '',
        text: 'Creativity thrives at our core as we constantly push boundaries, elevate our technical prowess, and deliver extraordinary customer experiences.'
    },
    models: [
        {
            id: 1,
            title: 'Job Portal',
            subTitle: 'Fre8Pro Application',
            img: JobPortalImg,
            teamSize: 10,
            projectDuration: '6 months',
            link: '',
            text: `We created a job portal that has a minimalistic design and feature-rich functionalities for the recruiter and the applicant.`
        }
    ],
    softwareTeamHunt: {
        bgImg: FinancialRisk,
        title: 'Budget friendly',
        subTitle1: ' software building sans financial risk',
        subTitle2: ' '
    },
    getInTouchData: {
        pageTitle: 'Get in touch with VarahiSoft',
        data: [
            {
                id: 1,
                officeName: 'Vadodara, Head Office',
                address: '203, 2nd Floor, Capri House - 2,Near Chakla Circle, Jetal pur road, Alkapuri Vadodara - 390007, Gujarat',
                email: 'sales@varahisoft.com',
                phoneNo: '0265 296 6000'
            },
            {
                id: 2,
                officeName: 'Bengaluru',
                address: '19/1, Second floor, Shree Ram mandir road, Basavanagudi - 560004, Karnataka',
                email: 'sales@varahisoft.com',
                phoneNo: '+91 80 2656 8000'
            },
            {
                id: 3,
                officeName: 'Coimbatore',
                address: 'VBM Complex, No:11-C27, 2nd Floor, Ramasamy Road, R.S Puram, Coimbatore - 641 002, Tamil Nadu',
                email: 'sales@varahisoft.com',
                phoneNo: '+91 94 8782 2072'
            },
        ]
    },
    FrequentlyAskedQuestionsData: {
        title: 'Frequently asked questions',
        subTitle: 'Your queries will be addressed here. If not, please reach out to us',
        buttonName: '',
        questions: [
            {
                question: 'Why should I opt for custom software development instead of off-the-shelf solutions?',
                answer: `Off-the-shelf solutions only provide generic features and do not cater to your business's unique needs. Custom software is the way to go if you want to address all your business challenges with software.`
            },
            {
                question: 'What is VarahiSoft’s custom software development?',
                answer: `VarahiSoft creates scalable, high-performing applications with a seamless user experience that helps your business achieve maximum value for your business.`
            },
        ]
    }
}
export const viewAllLatestBlogsData =
{
    bannerImg: bannerImg,
    headerData: {
        title: 'Our Blogs',
        subTitle: '',
        text: 'Read about software industry trends and best practices'
    },
    tags: [
        {
            id: 1,
            label: 'Java 8+',
            value: 'java_8+'
        },
        {
            id: 2,
            label: 'Spring',
            value: 'spring'
        },
        {
            id: 3,
            label: 'Spring Boot',
            value: 'spring_boot'
        },
        {
            id: 4,
            label: 'Postgres',
            value: 'postgres'
        },
        {
            id: 5,
            label: 'Rabbit MQ',
            value: 'rabbit_MQ'
        },
        {
            id: 6,
            label: 'Elastic Search',
            value: 'elastic_Search'
        },
        {
            id: 7,
            label: 'React JS',
            value: 'react_JS'
        },
        {
            id: 8,
            label: 'HTML',
            value: 'html'
        },
        {
            id: 9,
            label: 'CSS',
            value: 'css'
        },
    ],
    blogs: [
        {
            id: 1,
            name: 'By Vasu Padmanaban',
            createdDate: 'THU JUL 25 2024',
            userImg: userImg1,
            heading: `Solution Architecture`,
            paragraph: `Discover the unique value of Solution Architecture in building software products with this comprehensive guide.`
        }

    ],
    softwareTeamHunt: {
        bgImg: FinancialRisk,
        title: 'Every sale starts with a research.',
        subTitle1: 'Stop researching',
        subTitle2: 'with VarahiSoft beside you.'
    },
}
export const blogsDetailData =
{
    bannerImg: bannerImg,
    contentDetails: [
        {
            id: 1,
            bodyImg: bodyImg1,
            headerData: {
                title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
                subTitle: 'Thurs July 25, 2024',
                text: 'By Vasu Padmanaban'
            },
            bodyText: [
                {
                    id: 1,
                    name: 'What is Lorem Ipsum?',
                    paragraph: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
                    paragraph2: ``
                },
                {
                    id: 2,
                    name: 'Why do we use it?',
                    paragraph: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).`,
                    paragraph2: ``
                },
                {
                    id: 3,
                    name: 'Where does it come from?',
                    paragraph: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`,
                    paragraph2: `The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
                },

            ]
        },
        {
            id: 2,
            bodyImg: bodyImg1,
            headerData: {
                title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
                subTitle: 'Thurs July 25, 2024',
                text: 'By Vasu Padmanaban'
            },
            bodyText: [
                {
                    id: 1,
                    name: 'What is Lorem Ipsum?',
                    paragraph: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
                    paragraph2: ``
                },
                {
                    id: 2,
                    name: 'Why do we use it?',
                    paragraph: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).`,
                    paragraph2: ``
                },
                {
                    id: 3,
                    name: 'Where does it come from?',
                    paragraph: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`,
                    paragraph2: `The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
                },

            ]
        }
    ],
    softwareTeamHunt: {
        bgImg: SoftwareTeamHuntBg,
        title: '5G is here. And now',
        subTitle1: 'experience robust software development',
        subTitle2: 'with VarahiSoft'
    },
}
export const industriesPageData =
{
    bannerImg: serviceBanner,
    headerData: {
        title: 'Industries',
        subTitle: 'we specialize',
        subTitle2: '',
        text: 'We have achieved outstanding results in numerous domains and verticals',
        btnName: ''
    },
    industries: [
        {
            icon: <FacebookIcon />,
            text: 'Social Media',
            paragraph: 'Apps that connect with your loved ones the right way'
        },
        {
            icon: <ECommerceIcon />,
            text: 'E-commerce',
            paragraph: 'Connecting sellers and buyers and making sales'
        },
        {
            icon: <TravelingAndTourismIcon />,
            text: 'Traveling and Tourism',
            paragraph: 'Apps that are gateway to the world'
        },
        {
            icon: <HotelAndRestaurantsIcon />,
            text: 'Hotel and Restaurants',
            paragraph: 'Apps that work for your business to grow'
        },
        {
            icon: <JewelryIcon />,
            text: 'Jewellery - Retail',
            paragraph: 'The apps that shine your business'
        },
        {
            icon: <ManufacturingIcon />,
            text: 'Manufacturing ',
            paragraph: 'Apps that streamline the process and automate'
        },
        {
            icon: <AccountingIcon />,
            text: 'Accounting',
            paragraph: 'Apps that give you the real picture'
        },
        {
            icon: <FinanceIcon />,
            text: 'Finance ',
            paragraph: 'Apps that give you more clarity'
        },
        {
            icon: <InsuranceIcon />,
            text: 'Insurance',
            paragraph: 'Apps that delivers more protection'
        },
        {
            icon: <LMSIcon />,
            text: 'LMS',
            paragraph: 'Apps that makes learning easy'
        },
        {
            icon: <NightClubDiscoBallIcon />,
            text: 'Events',
            paragraph: 'Apps that conduct sans stress'
        },
        {
            icon: <LogisticsIcon />,
            text: 'Logistics and Transport',
            paragraph: 'Apps that connect the logistics industry'
        },
        {
            icon: <HealthIcon />,
            text: 'Health Care',
            paragraph: 'Apps that are top notch'
        },
        {
            icon: <PharmaIcon />,
            text: 'Hospital and Pharma',
            paragraph: 'Apps that make lives easier'
        },
        {
            icon: <HRIcon />,
            text: 'Human Resources',
            paragraph: 'Apps that are people driven'
        },
        {
            icon: <GovernmentIcon />,
            text: 'Government',
            paragraph: 'Apps that are top notch'
        },
    ],
    techIndustry: {
        title: '18+ years of valuable experience in the Tech Industry',
        paragraph: `Throughout our company's history, quality has always been our top priority, guiding our focus towards the successful completion of all our projects. Our commitment is to exceed your expectations by delivering a product that precisely aligns with your business goals. We consistently strive to go the extra mile to achieve exceptional results and provide a product that fulfills your unique business requirements`,
        btnName: 'View All',
        technologies: [
            {
                id: 1,
                name: 'Java 8+',
            },
            {
                id: 2,
                name: 'J2EE',
            },
            {
                id: 3,
                name: 'Spring 5',
            },
            {
                id: 4,
                name: 'Spring Boot',
            },
            {
                id: 5,
                name: 'Hibernate',
            },
            {
                id: 6,
                name: 'JWT',
            },
            {
                id: 7,
                name: 'NLP',
            },
        ]
    },
    techIndustryQuality: {
        bgImg: techIndustry2,
        pageHeading: 'Client Focused is our mantra',
        pageParagraph: `At our core, we are a dedicated team that transforms your ideas into tangible software solutions. We are deeply passionate about product engineering, integrating it into every aspect of our lives. Our expertise lies in not only converting your ideas into software but also ensuring they are sustainable and scalable. Our goal is to create software that has a meaningful impact on people's lives, offering functional and visually stunning experiences. We strongly believe that the success of software is rooted in the user experience it provides.`,
        faqData: []
    },
    yourIdea: {
        bgImg: yourIdeaImg,
        title: 'Work on',
        subtitle1: 'big ideas',
        subtitle2: 'without fear of failure'
    },
    clientData: {
        title: 'Client Focused is our mantra',
        paragraph: `At our core, we are a dedicated team that transforms your ideas into tangible software solutions. We are deeply passionate about product engineering, integrating it into every aspect of our lives. Our expertise lies in not only converting your ideas into software but also ensuring they are sustainable and scalable. Our goal is to create software that has a meaningful impact on people's lives, offering functional and visually stunning experiences. We strongly believe that the success of software is rooted in the user experience it provides.`
    },
    softwareTeamHunt: {
        bgImg: SoftwareTeamHuntBg,
        title: 'Work on',
        subTitle1: 'big ideas',
        subTitle2: 'without fear of failure'
    },
}
export const yourIdea = {
    bgImg: yourIdeaImg,
    // bgImg: SoftwareTeamHuntBg,
    title: 'We are',
    subtitle1: 'result driven',
    subtitle2: 'workforce'
}
export const aboutPageData =
{
    bannerImg: bannerImg,
    headerData: {
        title: 'Discover unparalleled product',
        subTitle: 'creation with innovative',
        subTitle2: 'software development.',
        text: '',
        btnName: 'Schedule a call',
    },

    aboutText: [
        {
            id: 1,
            paragraph: `We admire original thinkers and innovators and are committed to supporting them in every possible way through technology. In this digital age, technology has a profound impact on people's lives.`
        },
        {
            id: 2,
            paragraph: `If you have a vision to create your own application and are seeking a team that shares your passion, then Varahisoft is the right choice for you. Our track record of delivering impactful products on time makes the journey of building your product smooth and hassle-free.`
        },
    ],
    techIndustry: {
        title: 'Specialized in Technologies',
        paragraph: ``,
        btnName: 'View All',
        technologies: [
            {
                id: 1,
                name: 'Java 8+',
            },
            {
                id: 2,
                name: 'J2EE',
            },
            {
                id: 3,
                name: 'Spring 5',
            },
            {
                id: 4,
                name: 'Spring Boot',
            },
            {
                id: 5,
                name: 'Hibernate',
            },
            {
                id: 6,
                name: 'JWT',
            },
            {
                id: 7,
                name: 'NLP',
            },
        ]
    },
    yourIdea: {
        bgImg: yourIdeaImg,
        title: 'We are',
        subtitle1: 'result driven',
        subtitle2: 'workforce'
    },
}
export const clientCounterData = [
    {
        id: 1,
        label: 'Worldwide customers',
        value: 3
    },
    {
        id: 2,
        label: 'Happy Customers',
        value: 3
    },
    {
        id: 3,
        label: 'Products Delivered',
        value: 7
    },
    {
        id: 4,
        label: 'Client Satisfaction',
        value: 99
    },
]
export const ourTeam = {
    title: 'Our Team',
    bgImg: OurTeamImg,
    aboutTeam: [
        {
            id: 1,
            paragraph: `Mihir is an accomplished senior software architect and a former principal engineer at leading multinational corporations worldwide. He possesses a strong enthusiasm for open-source technologies, finding them invigorating due to the challenges and opportunities they offer for customization. Mihir excels as a team leader, adept at extracting the best from his team members.`
        },
        {
            id: 2,
            paragraph: `With a bachelor’s degree in information technology, Mihir is poised to become a successful entrepreneur, relishing challenges along the way. His passion lies in crafting unique software products from the ground up, showcasing his aptitude for exceptional user interfaces. When he's not busy working, he loves travelling and exploring tasty vegetarian cuisine across the globe`
        }
    ]
}
export const whyVarahiSoft = {
    title: 'Why VarahiSoft',
    paragraph: [
        {
            id: 1,
            text: `We are a dedicated team that transforms your ideas into software reality. We are deeply passionate about product engineering and fully committed to making your idea sustainable and scalable.`
        },
        {
            id: 2,
            text: `Our expertise lies in creating software that not only functions flawlessly but also provide visually stunning user experiences. We believe that the real measure of success is the positive impact your idea has on its users.`
        },
        {
            id: 3,
            text: `We understand that while anyone can build software, creating a product that is rich in functionality and offers exceptional user experience requires expertise, and it's something VarahiSoft excel at. Explore our product engineering services to get to know us better.`
        },
    ],
}
export const howVarahiSoftWorks = {
    title: 'How VarahiSoft works',
    worksData: [
        {
            id: 1,
            text: `Optimized solutions for hectic business operations`
        },
        {
            id: 2,
            text: `Robust design and super-quality code`
        },
        {
            id: 3,
            text: `Expertise in future-proof technologies`
        },
        {
            id: 4,
            text: `Efficient testing and debugging`
        },
        {
            id: 5,
            text: `Our goal is to ensure the profitable launch of your product, setting the stage for its success in the market.`
        },
    ]
}
export const methodologyData = {
    title: 'Agile – Scrum Methodology',
    paragraph: `At VarahiSoft, we excel in feature-driven development. This approach ensures that our entire team is well-versed in each feature and its impact on the software, guaranteeing a comprehensive understanding and high-quality output.`,
    methodologies: [
        {
            id: 1,
            icon: <DoableArrowSignIcon />,
            paragraph: `Our kickstart meeting is pivotal for comprehensive knowledge transfer about the product, its purpose, and impact on people's lives. It ensures the team fully understands the product before starting the software development process.`
        },
        {
            id: 2,
            icon: <DoableArrowSignIcon />,
            paragraph: `Once the project scope is clear and defined, we conduct fewer meetings, saving time and energy that can be directed towards efficient software development. Additionally, our regular short Scrum meetings help us stay on track and address any deviations.`
        },
        {
            id: 3,
            icon: <TargetCenterIcon />,
            paragraph: `At VarahiSoft, we prioritize a user-centric approach in our design process. This approach not only leads to a cost-effective design process but also ensures that all user needs are effectively met, making it adaptable and suitable for projects of all sizes.`
        },
    ]
}
export const technologiesPageData =
{
    bannerImg: serviceBanner,
    headerData: {
        title: '18+ years',
        subTitle: 'of valuable experience in the Tech Industry',
        subTitle2: '',
        text: '',
        btnName: ''
    },
    backEndTechnologies: {
        pageTitle: 'Back End Technologies',
        technologies: [
            {
                id: 1,
                name: 'Java 8+'

            },
            {
                id: 2,
                name: 'J2EE'

            },
            {
                id: 3,
                name: 'Spring 5'

            },
            {
                id: 4,
                name: 'Spring Boot'

            },
            {
                id: 5,
                name: 'Hibernate'

            },
            {
                id: 6,
                name: 'JWT'

            },
            {
                id: 7,
                name: 'NLP'

            },
            {
                id: 8,
                name: 'Junit'

            },
            {
                id: 9,
                name: 'Spring Security'

            },
            {
                id: 10,
                name: 'oAuth 2'

            },
            {
                id: 11,
                name: 'Logback'

            },
            {
                id: 12,
                name: 'Spring Data'

            },
            {
                id: 13,
                name: 'Python'

            },
            {
                id: 14,
                name: 'Open AI'

            },
            {
                id: 15,
                name: 'DBUnit'

            },
            {
                id: 16,
                name: 'Spring Test'

            },
            {
                id: 17,
                name: 'Node'

            },
            {
                id: 18,
                name: 'Elastic Search'

            },
            {
                id: 19,
                name: 'Log stash'

            },
            {
                id: 20,
                name: 'Kibana'

            },
            {
                id: 21,
                name: 'Spring Rest'

            },
            {
                id: 22,
                name: 'Swift'

            },
            {
                id: 23,
                name: 'Kotlin'

            },
            {
                id: 24,
                name: 'Active MQ'

            },
            {
                id: 25,
                name: 'Rabbit MQ'

            },
            {
                id: 26,
                name: 'Vert.x'

            },
            {
                id: 27,
                name: 'RxJava'

            },
            {
                id: 28,
                name: 'Grafana'

            },
            {
                id: 29,
                name: 'C'

            },
            {
                id: 30,
                name: 'C++'

            },
            {
                id: 31,
                name: 'Akka'

            },
            {
                id: 32,
                name: 'Executor Framework'

            },
            {
                id: 33,
                name: 'Json Processing Technologies'

            },
            {
                id: 34,
                name: 'XML Processing Technologies'

            },
        ],
    },
    frontEndTechnologies: {
        pageTitle: 'Front End Technologies',
        technologies: [
            {
                id: 1,
                name: 'HTML 5'

            },
            {
                id: 2,
                name: 'CSS 3'

            },
            {
                id: 3,
                name: 'Javascript'

            },
            {
                id: 4,
                name: 'React JS'

            },
            {
                id: 5,
                name: 'React Native'

            },
            {
                id: 6,
                name: 'Angular JS'

            },
            {
                id: 7,
                name: 'NLP'

            },
            {
                id: 8,
                name: 'Node'

            },
            {
                id: 9,
                name: 'Flutter'

            },
            {
                id: 10,
                name: 'Material UI'

            },
        ],
    },
    technologiesData: [
        {
            pageTitle: 'Database',
            pageImg: DatabaseImg,
            technologies: [
                {
                    id: 1,
                    name: 'Postgres'

                },
                {
                    id: 2,
                    name: 'Cassandra'

                },
                {
                    id: 3,
                    name: 'MySQL'

                },
                {
                    id: 4,
                    name: 'SQL Lite'

                },
                {
                    id: 5,
                    name: 'Oracle'

                },
                {
                    id: 6,
                    name: 'H2 DB'

                },
                {
                    id: 7,
                    name: 'Mongo DB'

                },
            ],
        },
        {
            pageTitle: 'Distributed Technologies',
            pageImg: DistributedTechnologiesImg,
            technologies: [
                {
                    id: 1,
                    name: 'Zoo Keeper'

                },
                {
                    id: 2,
                    name: 'Cassandra'

                },
                {
                    id: 3,
                    name: 'Kafka'

                },
                {
                    id: 4,
                    name: 'Mongo DB'

                },
                {
                    id: 5,
                    name: 'Hadoop'

                },
                {
                    id: 6,
                    name: 'Postgres'

                },
                {
                    id: 7,
                    name: 'ELk Stack'

                },
                {
                    id: 8,
                    name: 'Red shift'

                }
            ],
        },
        {
            pageTitle: 'Architecture',
            pageImg: ArchitectureImg,
            technologies: [
                {
                    id: 1,
                    name: 'Monolithic'

                },
                {
                    id: 2,
                    name: 'Micro services'

                },
                {
                    id: 3,
                    name: 'SOA'

                },
                {
                    id: 4,
                    name: 'Event Driven'

                },
            ],
        },
        {
            pageTitle: 'UX',
            pageImg: UXImg,
            technologies: [
                {
                    id: 1,
                    name: 'Axure RP'

                },
                {
                    id: 2,
                    name: 'Figma'

                },
            ],
        },
        {
            pageTitle: 'Dev Ops',
            pageImg: DevOpsImg,
            technologies: [
                {
                    id: 1,
                    name: 'Git'

                },
                {
                    id: 2,
                    name: 'Ansibe'

                },
                {
                    id: 3,
                    name: 'Git Lab'

                },
                {
                    id: 4,
                    name: 'Puppet'

                },
                {
                    id: 5,
                    name: 'Jenkins'

                },
                {
                    id: 6,
                    name: 'Sonar Qube'
                },
                {
                    id: 7,
                    name: 'Docker'
                },
                {
                    id: 8,
                    name: 'JIRA'
                },
                {
                    id: 9,
                    name: 'Kubernates'
                },
                {
                    id: 10,
                    name: 'COCOA'
                },
                {
                    id: 11,
                    name: 'Chef'
                },
                {
                    id: 12,
                    name: 'PMD'
                },
            ],
        },
        {
            pageTitle: 'Cloud',
            pageImg: CloudImg,
            technologies: [
                {
                    id: 1,
                    name: 'AWS'

                },
                {
                    id: 2,
                    name: 'GCP'

                },
                {
                    id: 3,
                    name: 'Azure'

                },
                {
                    id: 4,
                    name: 'Open shift'

                }
            ],
        },
        {
            pageTitle: 'Web Server',
            pageImg: WebServerImg,
            technologies: [
                {
                    id: 1,
                    name: 'Tomcat'

                },
                {
                    id: 2,
                    name: 'Jetty'

                },
                {
                    id: 3,
                    name: 'Apache'

                },
                {
                    id: 4,
                    name: 'Netty'

                },
                {
                    id: 4,
                    name: 'Nginx'

                }
            ],
        }
    ],
    yourIdea: {
        bgImg: SoftwareTeamHuntBg,
        title: '',
        subtitle1: 'Dream big , think ahead , plan and perform',
        subtitle2: 'with VarahiSoft'
    },
    clientData: {
        title: 'Client Focused is our mantra',
        paragraph: `At our core, we are a dedicated team that transforms your ideas into tangible software solutions. We are deeply passionate about product engineering, integrating it into every aspect of our lives. Our expertise lies in not only converting your ideas into software but also ensuring they are sustainable and scalable. Our goal is to create software that has a meaningful impact on people's lives, offering functional and visually stunning experiences. We strongly believe that the success of software is rooted in the user experience it provides.`
    },
    softwareTeamHunt: {
        bgImg: SoftwareTeamHuntBg,
        title: 'Work on',
        subTitle1: 'big ideas',
        subTitle2: 'without fear of failure'
    },
    frequentlyAskedQuestionsData: {
        title: 'Frequently asked questions',
        subTitle: 'Your queries will be addressed here. If not, please reach out to us',
        buttonName: '',
        questions: [
            {
                question: 'Why should I opt for custom software development instead of off-the-shelf solutions?',
                answer: `Off-the-shelf solutions only provide generic features and do not cater to your business's unique needs. Custom software is the way to go if you want to address all your business challenges with software.`
            },
            {
                question: 'What is VarahiSoft’s custom software development?',
                answer: `VarahiSoft creates scalable, high-performing applications with a seamless user experience that helps your business achieve maximum value for your business.`
            },
        ]
    }
}
export const faqPageData =
{
    bannerImg: bannerImg,
    headerData: {
        title: 'Frequently asked questions',
        subTitle: '',
        subTitle2: '',
        text: 'Your queries will be addressed here. If not, please reach out to us',
        btnName: ''
    },
    questions: [
        {
            question: 'What kind of support can I expect after my custom software is developed?',
            answer: `We give you complete support that suits your business needs.`
        },
        {
            question: 'How much does custom software development cost at VarahiSoft?',
            answer: `We don't overcharge nor mint you. We are transparent, and that is our promise.`
        },
        {
            question: 'How long does it take to develop custom software at VarahiSoft?',
            answer: `That's completely dependent on your software's size. But one thing is assured: We take the optimum time to develop your software.`
        },
        {
            question: 'What is the typical process for custom software development at VarahiSoft?',
            answer: `We begin with gathering requirements, then create a business understanding document that clears all the ambiguity. Then it is super professional design, development, testing and launch.`
        },
        {
            question: 'Why should I opt for custom software development instead of off-the-shelf solutions?',
            answer: `Off-the-shelf solutions only provide generic features and do not cater to your business's unique needs. Custom software is the way to go if you want to address all your business challenges with software.`
        },
        {
            question: 'What is VarahiSoft’s custom software development?',
            answer: `VarahiSoft creates scalable, high-performing applications with a seamless user experience that helps your business achieve maximum value for your business.`
        },
    ],
    yourIdea: {
        bgImg: yourIdeaImg,
        title: `You can't move mountains , but you can`,
        subtitle1: 'build giant software',
        subtitle2: 'with VarahiSoft'
    },
}
export const ourTeamPageData =
{
    bannerImg: bannerImg,
    headerData: {
        title: 'Our Team',
        subTitle: '',
        subTitle2: '',
        text: 'We are a bespoke software development company focussed on crafting on high quality over quantity of projects that we work',
        btnName: ''
    },
    aboutText: {
        id: 1,
        title: 'At VarahiSoft',
        textData: [
            {
                id: 1,
                paragraph: `Although we are headquartered in Vadodara, in the Indian state of Gujarat, we’ve always been more of a remote organisation. Our team is distributed throughout India in 5 major states of India`
            },
            {
                id: 2,
                paragraph: `We prioritize diversity in our business culture and are dedicated to fostering skills and strengthening team relationships. We value clients who are deeply committed to their projects and are willing to collaborate. Our primary focus is on preserving a healthy work-life balance for both our clients and our team`
            },
        ],
    },
    ourTeamData: [
        {
            id: 1,
            title: 'Disha Jaani',
            pageImg: DishaJaniImg,
            position: 'Founder & UX Head',
            paragraph: [
                {
                    id: 1,
                    text: `Meet Disha, an inspirational social worker who has transitioned into the field of UX design. With a strong background in supporting vulnerable communities and advocating for their needs, Disha has developed a deep understanding of human behavior and empathy, which she now applies to creating user-centered designs at VarahiSoft.`
                },
                {
                    id: 2,
                    text: `Her unique journey has equipped her with the skills to blend the art of design with a focus on enhancing the user experience, ultimately aiming to create meaningful and impactful solutions. She now leads the UX department at VarahiSoft, where she continues to make a positive difference. Outside of work, she enjoys traveling with her family, finding inspiration in new experiences.`
                }
            ]
        },
        {
            id: 2,
            title: 'Padmanaban',
            pageImg: PadmanabanImg,
            position: 'Partner and Business Development Head',
            paragraph: [
                {
                    id: 1,
                    text: `Padmanaban is the Business Development Head at VarahiSoft. With over 20 years of experience in business development and a strong track record of driving revenue growth and fostering strategic partnerships, He brings his expertise to VarahiSoft's dynamic team. He is passionate about cultivating new business opportunities and implementing innovative strategies to drive the company's expansion and success. When not working, he loves to be with his family and listening to melodies`
                }
            ]
        },
        {
            id: 3,
            title: 'Mihir Parekh',
            pageImg: MihirParekhImg,
            position: 'Member, Board of Advisor',
            paragraph: [
                {
                    id: 1,
                    text: `Mihir is the elder brother of Mrs Disha Jaani. Mihir is an accomplished senior software architect and a principal engineer at leading multinational corporations worldwide. He possesses a strong enthusiasm for open-source technologies, finding them invigorating due to the challenges and opportunities they offer for customization. Mihir excels as a team leader, adept at extracting the best from his team members.`
                },
                {
                    id: 2,
                    text: `With a bachelor’s degree in information technology, Mihir is poised to become a successful solution architect, relishing challenges along the way. His passion lies in crafting unique software products from the ground up, showcasing his aptitude for exceptional user interfaces. When he's not busy working, he loves travelling and exploring tasty vegetarian cuisine across the globe.`
                }
            ]
        },
    ],
    yourIdea: {
        bgImg: SoftwareTeamHuntBg,
        title: `Small Team.`,
        subtitle1: 'Huge impact.',
        subtitle2: ''
    },
    teamCulture: {
        title: 'Team Culture',
        text: `Our clients' success & satisfaction is paramount. To keep clients happy, we have to keep the Sliders happy. So culture is a big deal to us. At Slide UX, we value respect, collaboration, courage, resourcefulness, and adaptability. We can be a little nerdy, but we get things done – and we love clients who are the same way.`,
        images: [
            {
                id: 1,
                image: G1,
            },
            {
                id: 2,
                image: G2,
            },
            {
                id: 3,
                image: G3,
            },
            {
                id: 4,
                image: G4,
            },
            {
                id: 5,
                image: G5,
            },
            {
                id: 6,
                image: G6,
            }
        ],
        follow: {
            title: 'Follow us on social',
            icons: [
                {
                    id: 1,
                    icon: <LinkedinIcon />
                },
                {
                    id: 2,
                    icon: <InstagramIcon />
                },
                {
                    id: 3,
                    icon: <FacebookIconOne />
                }
            ]
        }
    }
}
export const careersPageData =
{
    bannerImg: bannerImg,
    headerData: {
        title: 'Careers',
        subTitle: '',
        subTitle2: '',
        text: `At VarahiSoft, we offer an inclusive work culture that fosters growth and integrity. Our team supports each other's success. Join us and build your future with VarahiSoft.`,
        btnName: ''
    },
    aboutText: {
        id: 1,
        title: '',
        textData: [
            {
                id: 1,
                paragraph: `You are not just joining a software company; you are becoming a valued member of the VarahiSoft family. We don’t refer to our team members as employees, but as integral parts of the VarahiSoft family. Bring your skills and be ready to tackle work challenges, embrace abundant learning opportunities, and enjoy a sense of freedom. If this resonates with you, we invite you to join us. We are thrilled to welcome you and support you in every way to help you grow and thrive.`
            }
        ],
    },
    perksAndBenefits: {
        title: `Perks And Benefits – Change to -  Life at VarahiSoft`,
        bgImg: PerksAndBenefitImg,
        paragraph: `Join our supportive, ethical workplace to learn, thrive, and elevate your career to global standards.`,
        benefits: [
            {
                id: 1,
                title: 'Competitive salary',
                userImg: <TargetCorrectIcon />,
            },
            {
                id: 2,
                title: 'Work from anywhere',
                userImg: <UsbPortIcon />,
            },
            {
                id: 3,
                title: 'Sick and “life happens” days',
                userImg: <LaptopUploadIcon />,
            },
            {
                id: 4,
                title: 'Employee Benefits',
                userImg: <PhoneMonitorExchangeIcon />,
            },
            {
                id: 5,
                title: 'Paid holidays',
                userImg: <UserNetworkIcon />,
            },
            {
                id: 6,
                title: 'Flexible work timing',
                userImg: <DataFileBarsStarIcon />,
            }
        ],
    },
    availablePositions: {
        pageTitle: 'Available Positions',
        positions: [
            {
                id: 1,
                jobTitle: '.Net Developer',
                experience: {
                    label: 'Experience - ',
                    value: '4+ Years'
                },
                openings: {
                    label: 'No of openings - ',
                    value: '2'
                },
                jobDetails: {
                    title: 'Job Details:',
                    text: `Candidates must possess the following skills and experience:`,
                    details: [
                        {
                            id: 1,
                            icon: <RightClick />,
                            text: 'Proficient in the Microsoft .NET framework and .NET Core with hands-on experience'
                        },
                        {
                            id: 2,
                            icon: <RightClick />,
                            text: 'Demonstrable knowledge of ASP.NET Services'
                        },
                        {
                            id: 3,
                            icon: <RightClick />,
                            text: 'Proficiency in C# and Angular'
                        },
                        {
                            id: 4,
                            icon: <RightClick />,
                            text: 'Ability to debug memory issues and optimize performance'
                        },
                        {
                            id: 5,
                            icon: <RightClick />,
                            text: 'Experience in performance profiling and optimization'
                        },
                        {
                            id: 6,
                            icon: <RightClick />,
                            text: 'Prior involvement with distributed web services'
                        },
                        {
                            id: 7,
                            icon: <RightClick />,
                            text: 'Familiarity with scalability, durability, availability, caching, and fault-tolerance'
                        },
                        {
                            id: 8,
                            icon: <RightClick />,
                            text: 'Experience in API development, including Restful and SOAP'
                        },
                        {
                            id: 9,
                            icon: <RightClick />,
                            text: 'Strong understanding of relational databases, SQL query profiling, and optimization'
                        },
                        {
                            id: 10,
                            icon: <RightClick />,
                            text: 'Familiarity with NoSQL databases'
                        },
                        {
                            id: 11,
                            icon: <RightClick />,
                            text: 'Track record of successfully managing multiple projects and teams.'
                        },
                        {
                            id: 12,
                            icon: <RightClick />,
                            text: 'Candidate must have experience in handling multiple projects & teams.'
                        }
                    ],
                    jobType: {
                        title: 'Job Type:',
                        value: 'Full-time',
                        flexibleSchedule: 'Flexible schedule',
                        timing: 'Monday to Friday (Remote)'
                    },
                    education: {
                        title: 'Education:',
                        value: `Bachelor's (Preferred)`,
                    },
                    experience: {
                        title: 'Experience:',
                        value: `.NET: 4 years (Required)`,
                        totalWork: `total work: 4 years (Required)`,
                        location: `Work Location: Hybrid`,
                    }
                }
            },
            {
                id: 1,
                jobTitle: 'Fullstack Developer (Node.JS / Angular)',
                experience: {
                    label: 'Experience - ',
                    value: '5+ Years'
                },
                openings: {
                    label: 'No of openings - ',
                    value: '1'
                },
                jobDetails: {
                    title: 'Job Details:',
                    text: `Candidates must possess the following skills and experience:`,
                    details: [
                        {
                            id: 1,
                            icon: '',
                            text: 'Proficient in the Microsoft .NET framework and .NET Core with hands-on experience'
                        },
                        {
                            id: 2,
                            icon: '',
                            text: 'Demonstrable knowledge of ASP.NET Services'
                        },
                        {
                            id: 3,
                            icon: '',
                            text: 'Proficiency in C# and Angular'
                        },
                        {
                            id: 4,
                            icon: '',
                            text: 'Ability to debug memory issues and optimize performance'
                        },
                        {
                            id: 5,
                            icon: '',
                            text: 'Experience in performance profiling and optimization'
                        },
                        {
                            id: 6,
                            icon: '',
                            text: 'Prior involvement with distributed web services'
                        },
                        {
                            id: 7,
                            icon: '',
                            text: 'Familiarity with scalability, durability, availability, caching, and fault-tolerance'
                        },
                        {
                            id: 8,
                            icon: '',
                            text: 'Experience in API development, including Restful and SOAP'
                        },
                        {
                            id: 9,
                            icon: '',
                            text: 'Strong understanding of relational databases, SQL query profiling, and optimization'
                        },
                        {
                            id: 10,
                            icon: '',
                            text: 'Familiarity with NoSQL databases'
                        },
                        {
                            id: 11,
                            icon: '',
                            text: 'Track record of successfully managing multiple projects and teams.'
                        },
                        {
                            id: 12,
                            icon: '',
                            text: 'Candidate must have experience in handling multiple projects & teams.'
                        }
                    ],
                    jobType: {
                        title: 'Job Type:',
                        value: 'Full-time',
                        flexibleSchedule: 'Flexible schedule',
                        timeing: 'Monday to Friday (Remote)'
                    },
                    education: {
                        title: 'Education:',
                        value: `Bachelor's (Preferred)`,
                    },
                    experience: {
                        title: 'Experience:',
                        value: `.NET: 4 years (Required)`,
                        totalWork: `total work: 4 years (Required)`,
                        location: `Work Location: Hybrid`,
                    }
                }
            }
        ]
    },
    yourIdea: {
        bgImg: SoftwareTeamHuntBg,
        title: `Small Team.`,
        subtitle1: 'Huge impact.',
        subtitle2: ''
    },
    teamCulture: {
        title: 'Team Culture',
        text: `Our clients' success & satisfaction is paramount. To keep clients happy, we have to keep the Sliders happy. So culture is a big deal to us. At Slide UX, we value respect, collaboration, courage, resourcefulness, and adaptability. We can be a little nerdy, but we get things done – and we love clients who are the same way.`,
        images: [
            {
                id: 1,
                image: G1,
            },
            {
                id: 2,
                image: G2,
            },
            {
                id: 3,
                image: G3,
            },
            {
                id: 4,
                image: G4,
            },
            {
                id: 5,
                image: G5,
            },
            {
                id: 6,
                image: G6,
            }
        ],
        follow: {
            title: 'Follow us on social',
            icons: [
                {
                    id: 1,
                    icon: <LinkedinIcon />
                },
                {
                    id: 2,
                    icon: <InstagramIcon />
                },
                {
                    id: 3,
                    icon: <FacebookIconOne />
                }
            ]
        }
    }
}
export const PrivacyPolicyPageData =
{
    bannerImg: serviceBanner,
    headerData: {
        title: 'Privacy Policy',
        subTitle: '',
        subTitle2: '',
        text: `We always prioritize your privacy and care about you.`,
        btnName: ''
    },
    aboutText: {
        id: 1,
        title: '',
        footerTitle: `Let's create something truly incredible!`,
        textData: [
            {
                id: 1,
                listData: [],
                paragraph: `Your access to and use of this site is subject to the following terms and conditions and all applicable laws. 
                By accessing and using this site, you acknowledge that you have read and accept the terms and conditions, 
                without limitation or qualification`
            },
            {
                id: 2,
                paragraph: `This website is administered by VarahiSoft. At VarahiSoft (together with its subsidiaries and affiliates, 
                “VarahiSoft” or “We”), your privacy is very important to us. The primary purpose of VarahiSoft.com is to be a dynamic resource and business tool to assist you in understanding VarahiSoft offerings and matching your needs and requirements with VarahiSoft offerings.
                It is also utilized as a portal for current and future employees to provide and access information in a secure and controlled way.
                We want you to feel secure when visiting our site and are committed to maintaining your privacy when doing so.
                This Privacy Policy (“Policy”) describes and governs our use and disclosure of the information we collect through our website:`,
                link: 'https://www.varahisoft.com',
                paragraph1: `(the “site”), as well as information that we collect through other means about people who contact us, customers, suppliers, and prospective investors and employees (“you” or “your”).Legal basis for gathering and/or processing your Information:`,
                listData: [
                    {
                        id: 1,
                        text: `To assess the site’s effectiveness and usefulness to those who enter the site and search its contents`
                    },
                    {
                        id: 2,
                        text: `To allow for application to positions posted on the site`
                    },
                    {
                        id: 3,
                        text: `To periodically send promotional e-mails about our new products and service offerings or other information which we think you may find interesting using the e-mail address that you have provided`
                    },
                    {
                        id: 4,
                        text: `To allow electronic submission of required paperwork`
                    },
                    {
                        id: 5,
                        text: `To respond to any query or complaint that you raise with us; and`
                    },
                    {
                        id: 6,
                        text: `To maintain our internal records.`
                    },
                ]
            }
        ],
        informationWeCollect: {
            title: 'Information We Collect:',
            collects: [
                {
                    id: 1,
                    title: `Information Provided Directly to Us:`,
                    listData: [],
                    paragraph: `Usually, we do not allow users to directly provide us with their information or any “Personal Data” / “Sensitive Personal Information” (as may be defined under the applicable laws) through the site. However, you may provide us directly with information in order to access certain services, to provide services to us or to request further information of VarahiSoft. This information may include your name, contact information, resume information, payment information or other information about yourself.`
                },
                {
                    id: 2,
                    title: `Information Collected Indirectly or Automatically:`,
                    paragraph: ` One example of information we collect indirectly is through our Internet access logs. When you access varahisoft.com, your Internet address is automatically collected and is placed in our Internet access logs. Also, we may use other indirect sources such as third-party analytics providers and technologies, including cookies and similar tools, to assist in collecting similar information, including but not limited to:`,
                    listData: [
                        {
                            id: 1,
                            title: `(a)`,
                            text: `IP addresses, location information, unique device identifiers, IMEI, and other information about your computer or device(s), browser types, browser language, operating system, mobile device carrier information, and the state or country from which you accessed the site; and`
                        },
                        {
                            id: 2,
                            title: `(b)`,
                            text: `Information related to the ways in which you interact with the site, such as referring and exit web pages and URLs, platform type, the number of clicks, domain names, landing pages, pages and content viewed and the order of those pages, statistical information about the use of the site, the amount of time spent on particular pages, the date and time of use, the frequency of your use of the site, error logs, and other similar information.`
                        },
                    ]
                }
            ]

        },
        legitimateUseAndTransfersOfData: {
            title: 'Legitimate Use and Transfers of Data:',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `As a global organization, information and/or data we collect may be transferred internationally throughout VarahiSoft’s worldwide organization to recipients located outside the European Economic Area. We will ensure that all transfers take place in accordance with the applicable privacy and data protection laws, including by entering into data transfer agreements where necessary. We will not sell individual information and will share it only with our affiliates, subsidiaries and required service providers and/or clients where it is applicable and where consent has been provided by you for such use. Your phone number provided to us will be kept strictly confidential. We do not sell, rent, trade, or otherwise share your phone number and SMS consent with any third parties. If VarahiSoft requires Sensitive Personal Information (“Sensitive PII”) from you, then VarahiSoft will specifically ask for your consent to our proposed uses of such Sensitive PII. The references to “Sensitive PII” are to the various categories of personal data identified by the United States of America and other data privacy laws such as California Consumer Privacy Act “CCPA”, including other countries’ laws and policies such General Data Protection Regulation “GDPR” of EU law, as requiring special treatment, including in some circumstances the need to obtain explicit consent. These categories may include but are not limited to personal identity numbers, personal data about personality and private life, racial or ethnic origin, nationality, membership of a trade union or profession or trade association, physical or mental health, addictions, property matters, or criminal records (including information about suspected criminal activities).`
                }
            ]
        },
        safeguardOfYourInformation: {
            title: 'Safeguard of Your Information:',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `We take appropriate measures to maintain the security of your data on VarahiSoft.com. However, you should understand that the open nature of the Internet is such that data may flow over networks without security measures and may be accessed and used by people other than those for whom the data is intended. As a result, while VarahiSoft strives to protect your information, we cannot and do not guarantee that these measures will be 100% effective to secure any information you transmit to or from the site.`
                }
            ]
        },
        retentionPeriod: {
            title: 'Retention Period:',
            collects: [
                {
                    id: 1,
                    title: ``,
                    paragraph: `We will retain your personal information for as long as needed or permitted in light of the purpose(s) for which it was obtained. The criteria used to determine our retention periods include:`,
                    listData: [
                        {
                            id: 1,
                            title: `(i)`,
                            text: `The length of time we have an ongoing relationship with you and provide the Service to you;`
                        },
                        {
                            id: 2,
                            title: `(ii)`,
                            text: `Whether there is a legal obligation to which we are subject; and`
                        },
                        {
                            id: 3,
                            title: `(iii)`,
                            text: `Whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation, or regulatory investigations).`
                        },
                    ]
                }
            ]

        },
        SubscribeOrUnsubscribe: {
            title: 'Subscribe or Unsubscribe:',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `It is completely up to you whether or not you want to access and use this site and provide the information requested, if applicable (generally termed as, “Subscribe”). VarahiSoft uses the contact information you provide to us via Let’s Connect Forms to contact you about our products and services. You may unsubscribe from these communications at any time by clicking the (“Unsubscribe”) link in any email that we send to you.`
                }
            ]
        },
        questionsAboutThisPolicy: {
            title: 'Questions About this Policy?',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `If you have any questions about our Policy, please contact us using the information on our Contact Us or write to us at`,
                    link: 'privacy@varahisoft.com'
                }
            ]
        },
    },

}
export const TermsOfUsePageData =
{
    bannerImg: serviceBanner,
    headerData: {
        title: 'Terms of Use',
        subTitle: '',
        subTitle2: '',
        text: `Welcome to VarahiSoft.com!`,
        btnName: ''
    },
    aboutText: {
        id: 1,
        title: 'Acceptance of Terms',
        footerTitle: `Let's create something truly incredible!`,
        textData: [
            {
                id: 1,
                listData: [],
                paragraph: `These Terms of Use outline the rules and regulations for the use of VarahiSoft’s Website, located at VarahiSoft (“VarahiSoft.com” or “site”). VarahiSoft reserves the right to update its Terms of Use at any time without notice to you. Your continued use of the site after any such modifications constitutes your acceptance of the modified terms and conditions. By accessing this website we assume you accept these Terms of Use. Do not continue to use the site if you do not agree to take all of the Terms of Use stated on this page.`
            },
            // {
            //     id: 2,
            //     paragraph: `This website is administered by VarahiSoft. At VarahiSoft (together with its subsidiaries and affiliates, 
            //     “VarahiSoft” or “We”), your privacy is very important to us. The primary purpose of VarahiSoft.com is to be a dynamic resource and business tool to assist you in understanding VarahiSoft offerings and matching your needs and requirements with VarahiSoft offerings.
            //     It is also utilized as a portal for current and future employees to provide and access information in a secure and controlled way.
            //     We want you to feel secure when visiting our site and are committed to maintaining your privacy when doing so.
            //     This Privacy Policy (“Policy”) describes and governs our use and disclosure of the information we collect through our website:`,
            //     link: 'https://www.varahisoft.com',
            //     paragraph1: `(the “site”), as well as information that we collect through other means about people who contact us, customers, suppliers, and prospective investors and employees (“you” or “your”).Legal basis for gathering and/or processing your Information:`,
            //     listData: [
            //         {
            //             id: 1,
            //             text: `To assess the site’s effectiveness and usefulness to those who enter the site and search its contents`
            //         },
            //         {
            //             id: 2,
            //             text: `To allow for application to positions posted on the site`
            //         },
            //         {
            //             id: 3,
            //             text: `To periodically send promotional e-mails about our new products and service offerings or other information which we think you may find interesting using the e-mail address that you have provided`
            //         },
            //         {
            //             id: 4,
            //             text: `To allow electronic submission of required paperwork`
            //         },
            //         {
            //             id: 5,
            //             text: `To respond to any query or complaint that you raise with us; and`
            //         },
            //         {
            //             id: 6,
            //             text: `To maintain our internal records.`
            //         },
            //     ]
            // }
        ],
        agreement: {
            title: 'Agreement',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `These legal notices and Terms of Use are a contract between you and VarahiSoft (the “Agreement”) and govern your visit to and use of the site`
                },
            ]
        },
        commentsAndPosting: {
            title: 'Comments and Posting',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `Parts of the site offers an opportunity for users to post and exchange opinions and information in certain areas of the site. VarahiSoft does not filter, edit, publish or review Comments or Posts prior to their presence on the site. Such Comments do not reflect the views and opinions of VarahiSoft, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, VarahiSoft shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.`
                },
                {
                    id: 2,
                    title: ``,
                    listData: [],
                    paragraph: `VarahiSoft reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms of Use.`
                },
                {
                    id: 3,
                    title: `You warrant and represent that:`,
                    listData: [
                        {
                            id: 1,
                            title: ``,
                            text: `You are entitled to post the Comments on our website and have all necessary licenses and consents to do so; `
                        },
                        {
                            id: 2,
                            title: ``,
                            text: `The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;`
                        },
                        {
                            id: 3,
                            title: ``,
                            text: `The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy`
                        },
                        {
                            id: 4,
                            title: ``,
                            text: `The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.`
                        },
                        {
                            id: 5,
                            title: ``,
                            text: `You hereby grant VarahiSoft a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.`
                        },
                    ],
                    paragraph: ``
                },
            ]
        },
        contentLiability: {
            title: 'Content Liability',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `We shall not be hold responsible for any content that appears on your website. You agree to protect and defend us against all claims that is rising on your website. No link(s) should appear on any website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.`
                }
            ]
        },
        cookies: {
            title: 'Cookies',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `We employ the use of cookies. By accessing the site, you agreed to use cookies in agreement with the VarahiSoft’s privacy policy. Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our site to enable the functionality of certain areas to make it easier for people visiting our site. Some of our affiliate / advertising partners may also use cookies.`
                }
            ]
        },
        disclaimerOfWarranties: {
            title: 'Disclaimer of Warranties',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `varahisoft.com, including all its content, is provided to you on an “as is” and “as available” basis with no warranties of any kind. By using the site, you expressly agree that such use is at your sole risk. Neither VarahiSoft, nor any of its respective officers, directors, partners, members, employees, agents, third-party content providers, designers, contractors, distributors, merchants, sponsors, licensors or the like (collectively “representatives”) warrant that the use of the site will be uninterrupted or error-free. Neither VarahiSoft nor its representatives make any representations or warranties of any kind, either express or implied, and VarahiSoft and its representatives hereby disclaim any and all such warranties, including, but not limited to, the implied warranty of merchantability, fitness for a particular purpose, title and non-infringement to the fullest extent permitted by law. To the fullest extent permitted by law, VarahiSoft also disclaims any warranties for the security, reliability, timeliness, and performance of this site.`
                }
            ]
        },
        effectiveDate: {
            title: 'Effective Date',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `This Agreement shall begin on the date hereof and shall be applicable to you upon acceptance of these Terms of Use and/or use of this site.`
                }
            ]
        },
        hyperlinkingToOurContent: {
            title: 'Hyperlinking to our Content',
            collects: [
                {
                    id: 1,
                    title: `The following organizations may link to our site without prior written approval:`,
                    listData: [
                        {
                            id: 1,
                            title: ``,
                            text: `Government agencies;`
                        },
                        {
                            id: 2,
                            title: ``,
                            text: `Search engines;`
                        },
                        {
                            id: 3,
                            title: ``,
                            text: `News organizations;`
                        },
                        {
                            id: 4,
                            title: ``,
                            text: `Online directory distributors may link to our site in the same manner as they hyperlink to the websites of other listed businesses;`
                        },
                        {
                            id: 5,
                            title: ``,
                            text: `System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.`
                        }
                    ],
                    paragraph: ``
                },
                {
                    id: 2,
                    title: ``,
                    listData: [],
                    paragraph: `These organizations may link to our home page, to publications or to other website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.`
                },
                {
                    id: 3,
                    title: ``,
                    listData: [],
                    paragraph: `If requested and required, we will approve link requests from above listed organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of VarahiSoft; and (d) the link is in the context of general resource information.`
                },
                {
                    id: 4,
                    title: ``,
                    listData: [],
                    paragraph: `If you are one of the organizations listed above and are interested in linking to our site, you must inform us by sending an e-mail to VarahiSoft’s Legal Department. Please include your name, your organization name, contact information as well as the URL of your website, a list of any URLs from which you intend to link to our site, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.`
                }
            ]
        },
        iFrames: {
            title: 'iFrames',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `Without prior approval and written permission, you may not create frames around our webpages that alter in any way the visual presentation or appearance of our site.`
                }
            ]
        },
        linksToThirdPartySites: {
            title: 'Links to Third Party Sites',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `The links on varahisoft.com will let you leave VarahiSoft’s site. The linked sites are not under the control of VarahiSoft and VarahiSoft is not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. VarahiSoft is not responsible for webcasting or any other form of transmission received from any linked site. VarahiSoft is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by VarahiSoft of any such third party website(s).`
                }
            ]
        },
        licenseAndIntellectualPropertyOwnership: {
            title: 'License and Intellectual Property Ownership',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `Unless otherwise stated, VarahiSoft and/or its licensors own the intellectual property rights for all material on varahisoft.com. All intellectual property rights are reserved. You may access this from varahisoft.com for your own personal use subjected to restrictions set in these Terms of Use.`
                },
                {
                    id: 2,
                    title: `You must not:`,
                    listData: [
                        {
                            id: 1,
                            title: ``,
                            text: `Republish, Reproduce, duplicate or copy material from the site.`
                        },
                        {
                            id: 2,
                            title: ``,
                            text: `Redistribute content from the site; or`
                        },
                        {
                            id: 3,
                            title: ``,
                            text: `Sell, rent or sub-license material from the site, without obtaining explicit and prior-written consent from VarahiSoft.`
                        },
                    ],
                    paragraph: ``
                }
            ]
        },
        indemnity: {
            title: 'Indemnity',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `As a condition to your use of varahisoft.com, you agree to indemnify, defend and hold harmless VarahiSoft and its Representatives against any and all claims, liabilities, damages, costs or other expenses (including, without limitation, attorneys’ fees) that may arise directly or indirectly out of or from your breach of this Agreement and/or your use of the site or resulting from any action (or inaction) you take or decision you make in reliance on the information contained herein.`
                }
            ]
        },
        limitationOfLiability: {
            title: 'Limitation of Liability',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `By using the site, you acknowledge: (i) that your use of the site is at your sole risk; (ii) that you assume full responsibility for all costs associated with your use of the site; and (iii) that in no event shall VarahiSoft or any party involved in creating, producing or delivering the site be liable for any damages, losses or liabilities of any kind related to (a) your reliance on or use or inability to use the information, materials, products or services on the site, (b) any errors or omissions in the content of the site, (c) your access to, use of or inability to use the site or (d) any failure of performance, error, omission, interruption, defect or delay in operation or transmission, computer virus, line or system failure or other computer malfunction, in each case ((a) through (d)) including without limitation direct, indirect, incidental, special, punitive, exemplary, compensatory or consequential damages, lost profits and/or loss of or damage to property whether the alleged liability is based on contract, tort, negligence strict liability or any other basis, even if VarahiSoft or such party has been advised of the possibility of such damages, losses or liabilities. VarahiSoft reserves the right to alter or remove the content of this site or suspend or terminate your use in any way, at any time, for any reason, without prior notification, and will not be liable in any way for possible consequences of such changes.`
                },
                {
                    id: 2,
                    title: ``,
                    listData: [],
                    paragraph: `The limitations and prohibitions of liability set forth above and elsewhere on this site: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty. As long as the site and the information and services on the site are provided free of charge, we will not be liable for any loss or damage of any nature.`
                }
            ]
        },
        removalOfLinksFromVarahisoft: {
            title: 'Removal of links from varahisoft.com',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `If you find any link on our site that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove such links but we are not obligated to or so or to respond to you directly.`
                }
            ]
        },
        reservationOfRights: {
            title: 'Reservation of Rights',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `We reserve the right to request that you remove all links or any particular link to our site. You approve to immediately remove all links to our site upon request. We also reserve the right to amend these Terms of Use and it’s linking policy at any time. By continuously linking to our site, you agree to be bound to and follow these linking Terms of Use.`
                }
            ]
        },
        useOfTerminology: {
            title: 'Use of Terminology',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `The following terminology applies to these Terms of Use, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person logging on this website and compliant to VarahiSoft’s Terms of Use. “Company”, “Ourselves”, “We”, “Our” and “Us”, refers to VarahiSoft. “Party”, “Parties”, or “Us”, refers to both the Client and VarahiSoft. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of VarahiSoft’s stated services, in accordance with and subject to, prevailing laws of United States of America. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.`
                }
            ]
        },
        yourPrivacy: {
            title: 'Your Privacy',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `Please read our Privacy Policy (Connect to Privacy Policy)`
                }
            ]
        },
        miscellaneous: {
            title: 'Miscellaneous',
            collects: [
                {
                    id: 1,
                    title: ``,
                    listData: [],
                    paragraph: `VarahiSoft’s failure to exercise or enforce any right or provision of this Agreement at an instance will not deem a waiver of such right or provision. If any provision of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision shall be enforced to the maximum extent permissible so as to affect the intent of this Agreement, and the remainder of this Agreement shall continue in full force and effect. Except for trademark and copyright matters governed by the federal laws of the United States, the foregoing provisions shall be governed by and construed in accordance with the substantive laws of the State of New Jersey, without regard to the principles of conflict of law thereof.`
                }
            ]
        }
    },

}
export const ServicesPageData =
{
    bannerImg: serviceBanner,
    headerData: {
        title: 'Every challenge is an',
        subTitle: 'opportunity for growth and innovation.',
        subTitle2: '',
        text: ``,
        btnName: ''
    },
    productEngineering: {
        id: 1,
        title: `Product Engineering`,
        listing: [
            {
                id: 1,
                text: `Are you seeking to embark on the development of a product from its inception and capitalize on its revenue potential?`
            },
            {
                id: 2,
                text: `Are you in search of a proficient team equipped with strong technical acumen and a profound understanding of end users?`
            },
            {
                id: 1,
                text: `Are you looking for a team with business expertise, strong technical capabilities, and the ability to deliver scalable products?`
            },
        ]
    },
    productEvolution: [
        {
            id: 1,
            question: `Product Evolution`,
            answer: [
                {
                    id: 1,
                    title: `Do you have a great product but think it could be even better?`,
                    text: `Share your challenges with us, and we'll provide tailored solutions for the future. We specialize in enhancing product functionalities to help you stay ahead without compromising your existing software. This is what we call product evolution - preseriving the core while upgrading to something new.`
                },
                {
                    id: 2,
                    title: `Why consider product evolution?`,
                    text: `if you have a working software that you feel could do more, we can help. We add new features and upgrade your software to align it with future needs, essentially transforming it into a brand-new application.`
                },
                {
                    id: 3,
                    title: `What's in it for you?`,
                    text: `By opting for product evolution, you get upgraded software at a competitive price. The additional benefits include new features for the present and future, a user-friendly interface, enhanced visuals, improved performance, and a substantial return on investment.`
                },
                {
                    id: 4,
                    title: `Here's how we work on your existing software:`,
                    text: `We identify areas requiring improvement-functionalities, features, support, code development, and modernization. We then provide recommendations based on software audits and code reviews. Additionally, we train your team on using the upgrades, ensuring smooth business operations and reliable data backups.`
                },
                {
                    id: 5,
                    title: `We focus on essential digital improvements`,
                    text: `that can transform your business into a high-performing, revenue-generating company. If this resonates with you, feel free to reach out to learn more!`
                },

            ]
        },
        {
            id: 2,
            question: `Product Maintenance`,
            answer: [
                {
                    id: 1,
                    title: ``,
                    text: `Product Maintenance is a crucial but often undervalued service in the software industry. While the initial launch generates excitement, it is the ongoing maintenance of the software product that sustains its functionality and revenue generation. The continual operation and regular upgrades of your application are essential. Any downtime can significantly impact both your reputation and revenue. At VarahiSoft, we offer transparent service plans, allowing you to focus on your core business activities while we provide comprehensive product support, maintenance, and necessary upgrades. Our support team is available across all time zones to promptly address any issues, resolve bugs, and ensure uninterrupted functionality of your software. Contact us to learn more about our software maintenance services.`
                },
            ]
        },
    ],
    pocBox: {
        id: 1,
        title: `In 3 months' time,`,
        subtitle: `you will be in cloud nine and we mean it`,
        subtitle2: `. Your POC is super performing.`,
    },
}