import React from 'react';
import { Container } from 'reactstrap';
// import { HashLink as Link } from 'react-router-hash-link';
import CaseStudyHeader from './CaseStudyHeader';
import CaseStudyContentBg from './CaseStudyContentBg';
import CaseStudyApplications from './CaseStudyApplications';
import CaseStudyTakeALook from './CaseStudyTakeALook';
import { CaseStudyData } from '../../common';

const CaseStudy = () => {
    return (
        <section className='CaseStudy'>
            <CaseStudyHeader data={CaseStudyData?.headerData} />
            <div className="acss-1kbtli">
                <CaseStudyContentBg />
                <Container className='acss-18azjcp' >
                    <div className='custom-pending-top'>
                        <CaseStudyApplications data={CaseStudyData?.models} />
                        <CaseStudyTakeALook />
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default CaseStudy;
