import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';
import { items } from '../../common';


const MobileSlider = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const mergedArray = items.flatMap(item => item.data);


    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === mergedArray.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? mergedArray.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }
    
    const slides = mergedArray.map((item, contentIndex) => (
        <CarouselItem
            className="custom-tag"
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={contentIndex}
        >
            <div className='d-flex no-block gap-2 w-100'>
                     <div  className='Product-Engineering'>
                        <div className='product-inner'>
                            <div>
                                { item?.icon}
                            </div>
                            <div>
                                <h5>{ item?.text}</h5>
                                <span>{ item?.paragraph}</span>    
                            </div>
                        </div>    
                    </div>
            </div>
        </CarouselItem>
    ));

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next.bind(null)}
            previous={previous.bind(null)}
        >
            <CarouselIndicators items={mergedArray} activeIndex={activeIndex} onClickHandler={goToIndex.bind(null)} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous.bind(null)} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next.bind(null)} />
        </Carousel>
    );
}

export default MobileSlider;
