import React from 'react';
import ellipseImageTop from '../../assets/images/landingpage/Ellipse1880.png'
import ellipseImageBottom from '../../assets/images/landingpage/Ellipse1879.png'

const CaseStudyContentBg = () => {
    return (
        <div className='position-absolute' style={{ inset: 0 }}>
            <div className="acss-lbif7z acss-b61x89">
                <img className="acss-61n50j acss-118odl4" src={ellipseImageTop} alt="ellipseImage" />
                <img className="acss-61n50j acss-x14t9p" src={ellipseImageBottom} alt="" />
            </div>
            <div className="acss-lbif7z acss-wom5xg">
                <img className="acss-61n50j acss-1ptcy1b" src={ellipseImageTop} alt="" />
                <img className="acss-61n50j acss-1ht8015" src={ellipseImageBottom} alt="" />
            </div>
            <div></div>
        </div>
    );
}

export default CaseStudyContentBg;
