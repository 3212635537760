/* eslint-disable */
import React, { useEffect, useState } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { Container, NavbarBrand, Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap';

import { routesData } from '../../common';
import { Link } from 'react-router-dom';
import { LogoIcon } from '../../common/icon';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const handleScroll = () => {
    // Check if the window is scrolled by 100px or more
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

    // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW                                                   */
    /*--------------------------------------------------------------------------------*/

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Navbar className={`navbar-expand-lg h6-nav-bar ${scrolled ?'headerBackgroundColor1' : ''}`} fixed={scrolled ? 'top' : ''}>
                <Container className='d-flex align-items-center justify-content-between'>
                        <NavbarBrand href="/">
                            <LogoIcon />
                            {/* <img src={logo} alt="VarahiSoft" /> */}
                        </NavbarBrand>
                        <NavbarToggler onClick={toggle}><span className="ti-menu"></span></NavbarToggler>
                        <Collapse isOpen={isOpen} navbar className="hover-dropdown font-14 justify-content-end" id="h6-info">
                            <Nav navbar className="ms-auto">
                                {routesData?.map((route,index) => {
                                    return (
                                        !route?.hidden && <NavItem key={index}>
                                            <Link className="nav-link" to={route?.path}>
                                                {route?.label}
                                            </Link>
                                        </NavItem>
                                    )
                                })}
                                
                            </Nav>
                            <div className="act-buttons">
                                <Link to="/contact" className="btn font-14">Get in Touch</Link>
                            </div>
                        </Collapse>
                </Container>
                    </Navbar>
            </div>
        </div>
    );

}
export default Header;
