import React from "react";

// // Locations for markers
// const locations = [
//   { name: "Ahmedabad", position: [23.0225, 72.5714] },
//   { name: "Rajasthan", position: [27.0238, 74.2179] },
// ];

const MapMyIndia = () => {
    return (
        <div className="my-map-india">
            {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.4106602831424!2d73.1812181149121!3d22.307158985323542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc8e3afc00001%3A0x6263b399d1db8170!2sVadodara%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1624523797308!5m2!1sen!2sin&zoom=100"
                className="h-100 w-100"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Vadodara Map"
            ></iframe> */}
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1579174.933970993!2d70.40464749728939!3d22.303015156209104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc8d6e8b6e32f%3A0xb5ad30a466be2a4d!2sGujarat%2C%20India!5e0!3m2!1sen!2sin!4v1624531315825!5m2!1sen!2sin&zoom=100"
                className="h-100 w-100"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Gujarat Map"
                zoom="100"
            ></iframe>
        </div>
    );
}

export default MapMyIndia;
